import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MultipleCards from './servicios/MultipleCards'
import AOS from 'aos'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    title: {
        fontSize: '30px',
        textAlign: 'center',
        '& h1': {
            fontFamily: 'Accura SemiBold',
            fontWeight: 'bold',
            [theme.breakpoints.down('sm')]: {
            fontSize: '40px',
        },
        },
        '& h1:nth-child(2)': {
            color: '#7174D7',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '25px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
            paddingTop: '20px',
        },
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-680px',
        zIndex: '100',
        '& > *': {
            marginBottom: '20px'
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '-690px',
        },
    },
    panel: {
        display: 'flex',
        maxWidth: '1300px',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: '#7174D7',
        borderRadius: '10px',
        overflow: 'hidden',
        width: '80%',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        '& > div': {
            width: '100%',
            borderRight: '3px solid rgba(0,0,0, .4)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            transition: 'background .5s',
            borderRadiusLeft: '10px',
            '&:hover': {
                background: 'rgba(0,0,0,.3)'
            },
            '&:last-child':{
                border: 'none'
            },
        },
        '& > div > div': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            textAlign: 'center',
            maxWidth: '250px',
            color: '#fff',
            height: '260px',
            [theme.breakpoints.down('xs')]: {
                height: '200px',
            },
            '& > *': {
                marginTop: '15px',
            },
            '& > h1': {
                fontSize: '25px',
                [theme.breakpoints.down('xs')]: {
                    fontSize: '23px',
                },
            },
            '& > p': {
                fontSize: '15px',
                marginLeft: '10px',
                marginRight: '10px'
            },
        },
        '& img': {
            [theme.breakpoints.down('xs')]: {
                width: '70px'
            },
            width: '100px',
        },
    },
    card: {
        maxWidth: '1300px',
        borderRadius: '10px',
        background: '#fff',
        width: '80%',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    },
    tarjetas: {
        borderRadius: '10px',
        border: 'dashed 1px #7174D7',
        width: '80%',
        height: 'auto',
        maxWidth: '1300px',
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        '& > img': {
            width: '350px',
            [theme.breakpoints.down('xs')]: {
                width: '240px',
            },
            margin: '20px',
        }
    }    
}))

const botones = [
    {img: './imgs/servicios/boton.png', type: 'boton', title: 'Botón de Pago', txt: 'Cobra directamente en tu sitio web'},
    {img: './imgs/servicios/link.png', type: 'link', title: 'Link de Pago', txt: 'Envia un enlace por cualquier medio y cobra fácil'},
    {img: './imgs/servicios/api.png', type: 'api', title: 'API', txt: 'Integra nuestra solución al nivel de tus necesidades de cobro con nuestra API'},
]

const Servicios = (props) => {
    const cls = useStyles()
    const [btnCard, setBtnCard] = useState('boton')
    const [windowWidth, setWindowWidth] = useState(0)
    
    const { hash } = props.location
    const btnType = `${hash}`.substring(1)
    
    useEffect(() => {
        AOS.init();
    }, [])

    const handleBoton = (e) => {
        var btnPanel = document.querySelectorAll('.panel')
        var arrPanel = [...btnPanel]
        setBtnCard(e.currentTarget.getAttribute('name'))
        arrPanel.map(btn => 
            btn === e.currentTarget ? 
            btn.classList.add('active-panel') : btn.classList.remove('active-panel')
        )
    }
        
    useEffect(() => {
        if(typeof btnType !== 'undefined') {
            var btnPanel = document.querySelectorAll('.panel')
            var arrPanel = [...btnPanel]

            arrPanel.map(btn =>
                btn.getAttribute('name') === btnType.toString() ? 
                btn.classList.add('active-panel') : btn.classList.remove('active-panel')
            )
            
            setBtnCard(btnType)
            // make different for desktop and mobile
            // window.scrollTo(0,340)
        }
        else{
            document.getElementsByName('boton')[0].classList.add('active-panel')
        }
        setWindowWidth(window.innerWidth)
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth)
        })
    },[btnType])

    return (
    <>
        <div className={cls.root}>
            <div className={`cont ${cls.title}`}>
                <h1>Los mejores beneficios</h1>
                <h1 className={cls.font}>del mercado</h1>
            </div>
        </div>
        <div className={cls.body}>
            <div className={cls.panel}>
                {botones.map(b => (
                    <div onClick={e => handleBoton(e)} name={b.type} className='panel' key={b.type}>
                        <div>
                            <img src={b.img} alt={b.talt}/>
                            <h1>{b.title}</h1>
                            {windowWidth > 599 ? (<p>{b.txt}</p>) : null}
                        </div>
                    </div>
                ))}
            </div>
            <div className={cls.card}>
                <MultipleCards type={btnCard || btnType}/>
            </div>
            <div className={cls.tarjetas}>
                <img src='./imgs/servicios/completa_pagos.PNG' alt='completa_pagos'/>
                <img src='./imgs/servicios/mismas_tarifas.PNG' alt='mismas_tarifas'/>
            </div>
        </div>
    </>
    )
}

export default Servicios
