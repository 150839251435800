import { makeStyles } from "@material-ui/core/styles";
import { H1, Boton } from "./Items";
import { useWindowResize } from "./hooks/useWindowResize";
import { Link } from "react-router-dom";
import { FaTwitterSquare, FaLinkedin, FaFacebookSquare } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "250px",
    backgroundColor: "#7174D7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      marginTop: "320px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "280px",
    },
  },
  firstFooter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "-300px",
    backgroundImage: 'url("./imgs/home/img2Sec6.png")',
    backgroundRepeat: "no-repeat",
    backgroundSize: "115% ",
    backgroundPosition: "10% 89px",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "200% ",
      marginTop: "-300px",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundSize: "250% ",
      backgroundPosition: "-10px 40%",
    },
    width: "100%",
    "& > div": {
      maxWidth: "1280px",
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: "20px",
      backgroundImage: 'url("./imgs/home/img1Sec6.png")',
      backgroundRepeat: "no-repeat",
      backgroundPosition: "90% 20%",
      backgroundSize: "40%",
      alignItems: "flex-start",
      flexDirection: "column",
      width: "90%",
      paddingTop: "250px",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "300px",
        backgroundSize: "400px",
        backgroundPosition: "50% 0%",
        justifyContent: "center",
        alignItems: "center",
      },
      [theme.breakpoints.down("xs")]: {
        backgroundSize: "250px",
        backgroundPosition: "50% 100px",
      },
      zIndex: "2",
      "& div": {
        width: "100%",
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    },
  },
  imgFooter: {
    right: "100px",
    top: "100px",
    zIndex: "1",
    position: "relative",
    margin: "0px",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  inputs: {
    width: "30%", // change width
    height: "30px",
    border: "solid 1px #ccc",
    borderRadius: "1px",
    /* Arriba | Derecha | Abajo | Izquierda */
    margin: "20px 20px 20px 0px",
    padding: "3px 25px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "20px 0px 20px 0px",
    },
  },
  divider: {
    width: "85%",
    backgroundColor: "#2d2d2d",
    height: ".5px",
    margin: "10px 0px",
    display: "flex",
    maxWidth: "1280px",
  },
  secondFooter: {
    width: "100%",
    paddingBottom: "50px",
    display: "flex",
    justifyContent: "center",
    "& > div": {
      marginTop: "20px",
      width: "85%",
      maxWidth: "1280px",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    "& > *": {
      maxWidth: "1280px",
    },
  },
  logoTxt: {
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    "& > img": {
      marginBottom: "20px",
    },
    "& > p": {
      maxWidth: "500px", //originally 400px
      textAlign: "left",
      color: "#fff",
    },
  },
  table: {
    width: "100%",
    "& tr > th": {
      fontFamily: "Accura SemiBold",
      color: "#F9BD18",
      textAlign: "center",
      paddingRight: "50px",
      paddingBottom: "15px",
      fontSize: "20px",
    },
    "& tr > td": {
      maxWidth: "900px",
      color: "#fff",
      fontSize: "15px",
      paddingRight: "40px",
      paddingBottom: "15px",
      textAlign: "center",
    },
  },
  wider: {
    width: "35%",
  },
  otherFooter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#000",
    padding: "50px 0px",
    "& > *": {
      marginTop: "10px",
      width: "90%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    "& > div > h1": {
      fontWeight: "100",
      color: "#fec011",
    },
    "& > div > label": {
      color: "#fff",
      marginTop: "5px",
    },
  },
  link: {
    textDecoration: "none",
    color: "#fff",
  },
  icons: {
    color: "white",
    fontSize: "25px",
    marginTop: "10px",
    transition: ".3s",
    "&:hover": {
      transform: "scale(0.9)",
    },
  },
  tableWidth: {
    width: "100%",
  },
}));

const Footer = () => {
  const cls = useStyles();
  const [windowWidth] = useWindowResize();

  const footerOne = (
    <div className={cls.secondFooter}>
      <div>
        <div className={cls.logoTxt}>
          <img src="./imgs/home/img3Sec6.png" alt="logo" />
          <p>
            En Zigu buscamos incentivar e impulsar la implementación de sistemas
            de pagos electrónicos efectivos y seguros en México y así aportar
            nuestro granito de arena ayudando a nuestros clientes a lograr sus
            metas de negocio.
          </p>
          <a href="https://www.linkedin.com/company/zigu/about/">
            <FaLinkedin className={cls.icons} />
          </a>
          <a href="https://twitter.com/ZiguMX">
            <FaTwitterSquare className={cls.icons} />
          </a>
          <a href="https://www.facebook.com/zigu.mx/">
            <FaFacebookSquare className={cls.icons} />
          </a>
        </div>
        <div className={cls.tableWidth}>
          <table className={cls.table}>
            <tbody>
              <tr>
                <th>Empresa</th>
                <th className={cls.wider}>Soporte</th>
                <th>¿Dónde estamos?</th>
              </tr>
              <tr>
                <td>
                  <Link to="/home" className={cls.link}>
                    Home
                  </Link>
                </td>
                <td>
                  <Link to="/faqs" className={cls.link}>
                    FAQs
                  </Link>
                </td>
                <td>
                  <b>Email: </b>
                  <a className={cls.link} href="mailto:contacto@zigu.mx">
                    contact@zigu.mx
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <Link to="/servicios" className={cls.link}>
                    Servicios
                  </Link>
                </td>
                <td>
                  <Link to="/aviso-de-privacidad" className={cls.link}>
                    Aviso de Privacidad
                  </Link>
                </td>
                <td rowSpan={2}>
                  <b>Dirección: </b>Av. Calzada del Valle #400 local 1021, Col.
                  Del Valle, San Pedro Garza García, Nuevo León, 66220
                </td>
              </tr>
              <tr>
                <td>
                  <Link to="/tarifas" className={cls.link}>
                    Tarifas
                  </Link>
                </td>
                <td>
                  <Link to="/terminos-y-condiciones" className={cls.link}>
                    Términos y Condiciones
                  </Link>
                </td>
                <td></td>
              </tr>
              <tr>
                {/* <td><Link to='/faqs' className={cls.link}>FAQ'S</Link></td> */}
              </tr>
              <tr>{/* <td></td><td>Contáctanos</td><td></td> */}</tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  const footerTwo = (
    <div className={cls.otherFooter}>
      <div className={cls.logoTxt}>
        <img src="./imgs/home/img3Sec6.png" alt="logo" />
        <p>
          En Zigu buscamos incentivar e impulsar la implementación de sistemas
          de pagos electrónicos efectivos y seguros en México y así aportar
          nuestro granito de arena ayudando a nuestros clientes a lograr sus
          metas de negocio.
        </p>
        <div>
          <a href="https://www.linkedin.com/company/zigu/about/">
            <FaLinkedin className={cls.icons} />
          </a>
          <a href="https://twitter.com/ZiguMX">
            <FaTwitterSquare className={cls.icons} />
          </a>
          <a href="https://www.facebook.com/zigu.mx/">
            <FaFacebookSquare className={cls.icons} />
          </a>
        </div>
      </div>
      <div>
        <h1>Empresa</h1>
        <label>
          <Link to="/home" className={cls.link}>
            Home
          </Link>
        </label>
        <label>
          <Link to="/servicios" className={cls.link}>
            Servicios
          </Link>
        </label>
        <label>
          <Link to="/tarifas" className={cls.link}>
            Tarifas
          </Link>
        </label>
      </div>
      <div>
        <h1>Soporte</h1>
        <label>
          <Link to="/faqs" className={cls.link}>
            FAQs
          </Link>
        </label>
        <label>
          <Link to="/aviso-de-privacidad" className={cls.link}>
            Aviso de Privacidad
          </Link>
        </label>
        <label>
          <Link to="/terminos-y-condiciones" className={cls.link}>
            Términos y Condiciones
          </Link>
        </label>
      </div>
      <div>
        {/* direccion and email purple or yellow */}
        <h1>¿Dónde estamos?</h1>
        <label>
          <strong>Dirección:</strong> Av. Calzada del Valle #400 local 1021,
          Col. Del Valle, San Pedro Garza García, Nuevo León, 66220
        </label>
        <a className={cls.link} href="mailto:contacto@zigu.mx">
          contact@zigu.mx
        </a>
        {/* <label><b>Teléfono:</b> 811 356 1237</label><label></label> */}
      </div>
    </div>
  );

  return (
    <footer>
      <div className={cls.root}>
        <div className={cls.firstFooter}>
          <div>
            <H1 txt="¿Estás listo?" color="#FEC011" />
            <H1 txt="Comencemos" color="#fff" />
            <div>
              <input
                className={cls.inputs}
                type="text"
                placeholder="NOMBRE COMPLETO"
              />
              <input
                className={cls.inputs}
                type="email"
                placeholder="CORREO ELECTRÓNICO"
              />
              <Boton
                back="rgb(254, 192, 17)"
                val="Comencemos"
                type="btnAmarillo"
                location="/registro"
              />
            </div>

            {windowWidth > 960 ? <div className={cls.divider}></div> : null}
            {windowWidth > 960 ? footerOne : null}
          </div>
        </div>
      </div>

      {windowWidth < 960 ? footerTwo : null}
    </footer>
  );
};

export default Footer;
