import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MultipleCardFaqs from "./faqs/MultipleCardFaqs";
import { Divider } from "@material-ui/core";
// import zIndex from '@material-ui/core/styles/zIndex';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    backgroundColor: "#FEC011",
    clipPath: "polygon(0 0, 100% 0, 100% 80%, 0% 100%)",
    overflow: "visible",
    width: "100%",
    height: "800px",
    "& h1": {
      fontFamily: "Accura SemiBold",
      fontSize: "70px",
      fontWeight: "bold",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "40px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
    "& h2": {
      fontFamily: "Open Sans",
      fontSize: "40px",
      fontWeight: "bold",
      textAlign: "center",
      color: "#7174d7",
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },
    "& h1:nth-child(2)": {
      color: "#7174d7",
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    maxWidth: "1200px",
    marginTop: "-600px",
    zIndex: "3",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-600px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "-650px",
      width: "90%",
    },
    "& > *": {
      width: "100%",
      margin: "20px 0px",
      backgroundColor: "#fff",
      borderRadius: "10px",
    },
  },
  search: {
    backgroundColor: "#7174d7",
    backgroundImage: 'url("./imgs/faqs/Ornamentos.png")',
    backgroundSize: "150% 100%",
    backgroundPosition: "-120px",
    backgroundRepeat: "no-repeat",
    minHeight: "200px",
    height: "auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      minHeight: "150px",
    },
    "& > div": {
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        width: "90%",
      },
    },
  },
  inputSearch: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    minWidth: "250px",
    marginTop: "10px",
    // marginBottom: '10px',
    "& img": {
      zIndex: "2",
      width: "120px",
      [theme.breakpoints.down("xs")]: {
        width: "70px",
      },
    },
    "& input": {
      backgroundColor: "#fff",
      borderTopRightRadius: "50px",
      borderBottomRightRadius: "50px",
      border: "none",
      paddingLeft: "35px",
      marginLeft: "-30px",
      height: "80px",
      width: "90%",
      fontSize: "20px",
      outline: "none",
      [theme.breakpoints.down("xs")]: {
        height: "60px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "17px",
      },
    },
  },
  telefono: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center", //horizontal
    flewWrap: "wrap",
    alignItems: "flex-end", // vertical
    backgroundColor: "#fff", // #FEC011
    width: "35%",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 10px",
      borderRadius: "20px",
      width: "90%",
      display: "none",
    },
    "& img": {
      width: "55%",
    },
    "& > div": {
      display: "block",
      width: "70%",
      [theme.breakpoints.up("md")]: {
        marginLeft: "20px",
        display: "none",
      },
    },
  },
  // llamanos: {
  //     color: '#fff',
  //     fontSize: '20px',
  //     fontWeight: '500',
  // },
  // numero: {
  //     color: '#7174d7',
  //     fontSize: '20px',
  //     fontWeight: '900',
  // },
  faqs: {
    height: "auto",
    padding: "4px",
    borderRadius: "10px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  help: {
    // height: '300px',
    border: "solid 1px #7174d7",
  },
  titulo: {
    fontSize: "24px",
    fontWeight: "500",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", //vertical
    alignItems: "flex-start", //horizontal
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
    "& > *": {
      margin: "5px 0px",
    },
    "& h1": {
      fontWeight: "100",
      color: "#7174d7",
      fontSize: "40px",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        fontSize: "30px",
      },
    },
    "& h1 span": {
      color: "#000",
    },
    "& p": {
      color: "#7174d7",
      marginLeft: "25px",
      fontSize: "25px",
      [theme.breakpoints.down("sm")]: {
        width: "70%",
        textAlign: "center",
        fontSize: "20px",
      },
    },
  },
  divider: {
    height: "1px",
    width: "60%",
    marginLeft: "25px",
    backgroundColor: "#7174d7",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0px",
    },
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "20px",
    "& > div:nth-child(-n + 2)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& > div:last-child": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
    },
    "& > div:nth-child(1)": {
      width: "30%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& > div:nth-child(2)": {
      width: "60%",
      marginLeft: "20px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginLeft: "0px",
      },
    },
    "& > div:nth-child(3)": {
      width: "100%",
    },
    "& > div:nth-child(-n + 2) input": {
      backgroundColor: "#f0f0f0",
      color: "#000",
      width: "97%",
      height: "35px",
      border: "none",
      margin: "5px 0px",
      paddingLeft: "20px",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
    "& > div:last-child button": {
      backgroundColor: "#7174d7",
      border: "1px solid #ccc",
      color: "#fff",
      fontWeight: "bold",
      width: "30%",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
      margin: "5px 1px",
      height: "35px",
    },
  },
  head: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  // buttons: {
  //     width: '100%',
  //     display: 'flex',
  //     flexDirection: 'row',
  //     justifyContent: 'stretch',
  //     alignItems: 'stretch',
  // }
}));

const Faqs = () => {
  const cls = useStyles();

  const [search, setSearch] = useState("");

  const handleSearch = useCallback(
    (event) => {
      setSearch(event.target.value);
    },
    [setSearch]
  );

  return (
    <div className={cls.root}>
      <div className={cls.title}>
        <h1>¿En qué</h1>
        <h1>te podemos ayudar?</h1>
        {/* <h2>Preguntas Frecuentes</h2> */}
      </div>
      <div className={cls.body}>
        <div className={cls.search}>
          <div className={cls.inputSearch}>
            <img src="./imgs/faqs/search.png" alt="search" />
            <input
              type="text"
              placeholder="¡Resolvemos tus dudas!"
              onChange={handleSearch}
            />
          </div>
          {/* <div className={cls.telefono}>
                        <img src='./imgs/faqs/telefono.png' alt='search'/>
                        <div>
                            <h3 className={cls.llamanos}>Llámanos</h3>
                            <h3 className={cls.numero}>(81) 1356-1237</h3>
                        </div>
                    </div> */}
        </div>
        <div className={cls.faqs}>
          <MultipleCardFaqs search={search} />
        </div>
        <div className={cls.help}>
          <div className={cls.head}>
            <div className={cls.titulo}>
              <h1>
                <b>¿Tienes un cargo</b>
                <span> no reconocido?</span>
              </h1>
              <p>Nosotros te apoyamos en poder identificarlo y aclararlo</p>
              <Divider className={cls.divider} />
            </div>
            <div className={cls.telefono}>
              <img src="./imgs/faqs/preguntas.png" alt="search" />
            </div>
          </div>
          <form className={cls.form}>
            <div>
              <input type="text" placeholder="NOMBRE" />
              <input type="text" placeholder="TELÉFONO" />
              <input type="text" placeholder="CORREO" />
            </div>
            <div>
              <input
                type="text"
                placeholder="ULTIMOS 4 DIGITOS DE TU TARJETA"
              />
              <input type="text" placeholder="MONTO DE LA TRANSACCIÓN" />
              <input type="text" placeholder="FECHA DEL CARGO" />
            </div>
            <div className={cls.buttons}>
              <button>BUSCAR</button>
              <button>SOLICITAR ACLARÁCION</button>
              <button>SOLICITAR DECLARACIÓN</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
