import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fec011",
    height: "1100px",
    marginBottom: "-400px",
    [theme.breakpoints.down("sm")]: {
      height: "1200px",
      marginBottom: "-550px",
    },
  },
}));

const ViewRegistroSesion = ({ children }) => {
  const cls = useStyles();

  return <div className={cls.root}>{children}</div>;
};

export default ViewRegistroSesion;
