import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Boton } from "../Items";
import { cards } from "./Data";
import { useWindowResize } from "../hooks/useWindowResize";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  titulos: {
    alignItems: "center",
    textAlign: "center",
    margin: "40px 0px",
    "& > h1": {
      fontSize: "45px",
      fontWeight: "200",
      margin: "10px 0px",
      fontFamily: "Accura SemiBold",
      [theme.breakpoints.down("sm")]: {
        fontSize: "34px",
      },
    },
    "& > p": {
      maxWidth: "550px",
      color: "#7174D7",
      padding: "0px 10px",
      fontSize: "24px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
  },
  firstSection: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  subtitulo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    "& > div": {
      width: "15%",
      height: "2px",
      background: "#7174d7",
    },
    "& > h1": {
      fontFamily: "Accura SemiBold",
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "250px",
      },
      margin: "0px 20px",
      maxWidth: "450px",
    },
  },
  items: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-start",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "40px 0px",
    width: "80%",
    "& > div": {
      maxWidth: "170px",
      margin: "25px 0px",
      display: "flex",
      justifyContent: "space-evelyn",
      alignItems: "center",
      flexDirection: "column",
    },
    "& > div > div": {
      width: "120px",
      height: "120px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& > div > div > img": {
      width: "120%",
    },
    "& > div > label": {
      fontFamily: "Accura SemiBold",
      fontWeight: "bold",
      color: "#7174d7",
      margin: "10px 0px",
    },
    "& > div > p": {
      textAlign: "center",
      color: "#aaa",
    },
  },
  secondSection: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  secondItems: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "80%",
    marginTop: "20px",
    "& > div": {
      marginTop: "75px",
      width: "200px",
      height: "100px",
      border: "1px dashed #7174d7",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    "& > div > img": {
      height: "100px",
      maxWidth: "130px",
      zIndex: "3",
      marginTop: "-80px",
    },
    "& > div > label": {
      fontWeight: "bold",
      color: "#7174d7",
      margin: "10px",
      fontFamily: "Accura SemiBold",
    },
  },
  registrate: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "320px",
  },
  centerImg: {
    margin: "0px !important",
    marginTop: "15px !important",
  },
  map: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: "25px",
    },
  },
}));

const MultipleCards = ({ type }) => {
  const types = ["boton", "link", "api"];
  if (!types.includes(type)) {
    type = "boton";
  }

  const [windowWidth] = useWindowResize();
  const data = cards[type];
  const cls = useStyles();
  // remove button effect from CEOS
  // talk with Ramon about Cookies !important
  return (
    <div className={cls.root}>
      <div className={cls.titulos}>
        <h1>{data.title}</h1>
        <p>{data.subTitle}</p>
      </div>
      <div className={cls.firstSection}>
        <div className={cls.subtitulo}>
          <div></div>
          <h1>Beneficios</h1>
          <div></div>
        </div>
        <div className={cls.items}>
          {data.firstSection.map((f, i) => (
            <div
              key={i}
              data-aos-delay={`${i + 5}00`}
              style={
                type === "link" && windowWidth > 960
                  ? { margin: "20px 50px" }
                  : null
              }
              data-aos="fade-up"
            >
              <div>
                <img src={f.img} alt={f.alt} />
              </div>
              <label>{f.title}</label>
              <p>{f.txt}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={cls.secondSection}>
        <div className={cls.subtitulo}>
          <div></div>
          <h1>{data.secondSection.title}</h1>
          <div></div>
        </div>
        <div className={cls.secondItems}>
          {data.secondSection.items.map((i, j) => (
            <div key={j} className={cls.map}>
              <img src={i.img} alt={i.alt} className={cls[i.class]} />
              <label>{i.title}</label>
            </div>
          ))}
        </div>
      </div>
      <br></br>
      <br></br>
      <div className={cls.registrate}>
        <Boton
          val="¡Regístrate ya!"
          location="/registro"
          type="btnAmarilloLight"
        />
      </div>
      <br></br>
      <br></br>
    </div>
  );
};

export default MultipleCards;
