import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Boton } from './Items';
import { useWindowResize } from './hooks/useWindowResize';

const useStyles = makeStyles(theme => ({
    cont: {
        marginTop: '-400px',
        maxWidth: '1280px',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginTop: '-500px',
        },
        '& > div': {
            marginTop: '50px',
            marginLeft: '50px',
            [theme.breakpoints.down('sm')]: {
                marginTop: '-40px',
                marginLeft: '100px',
            },
        },
        '& img': {
            width: '650px',
            minWidth: '300px',
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10%',
                width: '80%',
            },
        },
        '& h1:nth-child(1)': {
            color: '#7174d7',
            fontSize: '80px',
            fontWeight: '100',
        },
        '& h1:nth-child(2)': {
            color: '#fff',
            fontSize: '60px',
        },
        '& p': {
            color: '#fff',
            fontSize: '30px',
            fontWeight: '100',
        },
        '& > div *': {
            marginTop: '10px',
        },
        '& button': {
            marginTop: '25px',
        }
    },
}))

const NotFound = () => {
    const cls = useStyles()
    const [windowWidth] = useWindowResize()

    return (
        <div className={cls.cont}>
            <img src='./imgs/404.png' alt='404'/>
            <div>
                <h1>404</h1>
                <h1>Algo salió mal...</h1>
                <p>¡pero estamos trabajando {windowWidth > 960 ? (<br></br>) : null} para solucionarlo!</p>
                <Boton val='Regresar a sitio' location='/home' type='btnMorado'/>
            </div>
        </div>
    )
}

export default NotFound
