import { ApolloClient, InMemoryCache } from "@apollo/client"

const client = new ApolloClient({
    // uri     : 'http://localhost:4000',
    uri     : 'https://webserver.zigu.develbmt.com',
    cache   : new InMemoryCache()
})

export default client

