import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Boton } from "../Items";
import AOS from "aos";
import "aos/dist/aos.css";
import { useWindowResize } from "../hooks/useWindowResize";
// import { onDeck } from "./imgs/home/onDeck.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cont: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap-reverse",
    backgroundColor: "#FEC011",
    clipPath: "polygon(0 0, 100% 0, 100% 85%, 0% 100%)",
    width: "100%",
  },
  img: {
    width: "450px",
    marginBottom: "150px",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      paddingTop: "100px",
    },
  },
  imgMobile: {
    maxWidth: "450px",
    marginTop: "-300px",
    marginBottom: "70px",
    width: "70%",
    padding: "20px 0px",
  },
  textos: {
    "& > *": {
      margin: "10px 0px",
    },
    "& h1": {
      fontWeight: "100",
      lineHeight: "1",
    },
    "& h1:nth-child(n + 3)": {
      color: "#fff",
    },
    "& h1:nth-child(-n + 2)": {
      fontSize: "80px",
      fontWeight: "600",
      letterSpacing: "-3px",
      margin: 0,
    },
    marginLeft: "0px",
    marginTop: "-100px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      paddingBottom: "300px",
      width: "100%",
      "& h1:nth-child(-n + 2)": {
        fontSize: "40px",
        fontWeight: "600",
        letterSpacing: "-2px",
        margin: 0,
      },
      "& h1:nth-child(n + 3)": {
        fontSize: "16px",
      },
      textAlign: "center",
      fontFamily: "Accura",
    },
  },
  labelAPI: {
    color: "#fff",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "20px",
    margin: "20px",
    marginTop: "-100px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  boton: {
    width: "50%",
  },
  onDeck: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  img2: {
    width: "100px",
    height: "50px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
      marginLeft: "0px",
    },
  },
}));

const Inicio = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const cls = useStyles();
  const [windowWidth] = useWindowResize();

  return (
    <div className={cls.root}>
      <div className={cls.cont}>
        {windowWidth > 960 ? (
          <img
            className={cls.img}
            data-aos="zoom-in-right"
            src="./imgs/home/imgHeader.png"
            alt="payment"
          />
        ) : null}
        <div className={cls.textos} data-aos="fade-left">
          <h1>Rápido,</h1>
          <h1>sencillo y seguro.</h1>
          <h1>Cualquiera puede aceptar pagos en</h1>
          <h1>línea en minutos</h1>
          <Boton val="Abre tu cuenta" location="/registro" type="btnMorado" />
          <div className={cls.onDeck}>
            <p>Backed and accelerated by </p>
            <img src="./imgs/home/onDeck.svg" className={cls.img2} />
          </div>
        </div>
      </div>
      {windowWidth < 960 ? (
        <img
          className={cls.imgMobile}
          data-aos="zoom-in-right"
          src="./imgs/home/Ilustracion Mobile.png"
          alt="payment"
        />
      ) : null}
    </div>
  );
};

export default Inicio;
