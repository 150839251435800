import React from "react";
import Inicio from "./home/Inicio";
import Charts from "./home/Charts";
import ComoFunciona from "./home/ComoFunciona";
import ParaQue from "./home/ParaQue";
import Ceos from "./home/Ceos";
import Slider from "./home/Slider";
import Clientes from "./home/Clientes";
import { useWindowResize } from "./hooks/useWindowResize";

const Home = () => {
  const [windowWidth] = useWindowResize();

  return (
    <>
      <Inicio />
      <Charts />
      <ComoFunciona />
      <ParaQue />
      {windowWidth < 960 ? <Slider /> : <Ceos />}
      <Clientes />
    </>
  );
};

export default Home;
