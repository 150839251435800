import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Label, Ptxt } from '../Items'

const useStyles = makeStyles(theme => ({ 
    root:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > div': {
            display: 'flex',
            position: 'relative',
            top:'-110px',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
            maxWidth: '1280px',
        },
       [theme.breakpoints.down('sm')]: {
           marginBottom: '-80px',
        },
    },
    cont: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'center',
        width:'200px',
        height: '310px',
        marginTop: '10px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 0px',
        },
        

    },
    circle: {
        width: '120px',
        height: '120px',
        borderRadius: '50%',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > img': {
            width: '65px',
        },
    },
    paragraphs: {
        marginTop: '10px',
    }
}))

const charts = [
    {img: './imgs/home/chartImg1.png', alt: 'chart', circle: '#70D4C5', label: 'Las mejores tarifas en México', txt: 'Contamos con las tarifas más bajas del mercado para que maximices tus ventas. ¡Garantizado!'},
    {img: './imgs/home/chartImg2.png', alt: 'chart', circle: '#AA6CDF', label: 'Protección en Contracargos', txt: 'Disminuye riesgos en tus transacciones por fraudes generados en contracargos. Paz mental, ventas seguras.'},
    {img: './imgs/home/chartImg3.png', alt: 'chart', circle: '#687DE7', label: 'Tu dinero en máximo 24 Hrs', txt: 'Recibe el dinero de tus ventas exitosas en menos de 24 horas, seguro y directo.'},
    {img: './imgs/home/chartImg4.png', alt: 'chart', circle: '#D5597C', label: 'Comienza a vender ahora', txt: 'Acepta pagos el mismo día en que llenes tu aplicación gracias a la flexibilidad de nuestro proceso.'}
]

const Charts = () => {
    const cls = useStyles()

    return (
        <div className={cls.root}>
            <div>
            {charts.map((c, index) => (
                <div key={c.circle} data-aos="fade-up" data-aos-delay={`${index + 5}00`} className={cls.cont}>
                    <div className={cls.circle} style={{backgroundColor: c.circle}}>
                        <img src={c.img} alt={c.alt}/>
                    </div>
                    <br></br>
                    <Label txt={c.label} />
                    <div className={cls.paragraphs}>
                        <Ptxt txt={c.txt} />
                     </div>
                </div>
            ))}
            </div>
        </div>
    )
}

export default Charts
