import React from "react";
import { H1, Label, Ptxt, Boton } from "../Items";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "-50px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 0px",
    },
    textAlign: "center",
    fontFamily: "Accura SemiBold",
  },
  cont: {
    display: "flex",
    justifyContent: "center", //horizontal
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "30px",
    "& img": {
      height: "135px",
      marginBottom: "-50px",
    },
  },
  box: {
    border: "2px dotted blue",
    paddingTop: "60px",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "300px",
    height: "150px",
    [theme.breakpoints.down("sm")]: {
      width: "270px",
      height: "120px",
    },
    padding: "10px",
    margin: "10px",
  },
  h1: {
    fontFamily: "Accura SemiBold",
    fontWeight: "bold",
    fontSize: "70px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  },
  space: {
    margin: "15px 0px",
  },
}));

const pasos = [
  {
    id: 1,
    label: "Llena el formulario",
    img: "./imgs/servicios/Crea tu Cuenta.png",
    aos: "flip-left",
    txt: "Llena el formulario de aplicación en línea para abrir tu cuenta.",
  },
  {
    id: 2,
    label: "Copia y pega tu código",
    img: "./imgs/servicios/Copia y Pega.png",
    aos: "flip-right",
    txt: "Dentro de nuestro portal encontrarás el código de los lenguajes más populares para que solo copies y pegues en tu sitio web.",
  },
  {
    id: 3,
    label: "Termina de darte de alta",
    img: "./imgs/servicios/descarga.png",
    aos: "flip-up",
    txt: "Estaremos en contacto contigo para asistirte en cuestiones técnicas y para ayudarte a completar el proceso de alta.",
  },
];
const ComoFunciona = () => {
  const cls = useStyles();

  return (
    <div className={cls.root}>
      <h1 className={cls.h1}> ¿Cómo funciona Zigu? </h1>
      <div className={cls.cont}>
        {pasos.map((p, i) => (
          // data-aos={p.aos}
          <div className={cls.space} key={i}>
            <img src={p.img} alt={p.label} />
            <div className={cls.box}>
              <Label txt={p.label} />
              <br></br>
              <Ptxt txt={p.txt} />
            </div>
          </div>
        ))}
      </div>
      <br></br>
      <Boton
        val="¡Regístrate ya!"
        location="/registro"
        type="btnAmarilloLight"
      />
    </div>
  );
};

export default ComoFunciona;
