import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#7174D7",
    backgroundImage: "url(./imgs/home/Ornamentos.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "75% 0%",
    backgroundSize: "110% 100%",
    display: "flex",
    width: "100%",
    padding: "30px 0px",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "200% 100%",
    },
    margin: "50px 0px",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  },
  cont: {
    width: "70%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "1",
    margin: "10px 8px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      width: "90%",
    },
  },
  imgBack: {
    position: "relative",
    width: "150%",
    left: "-10%",
    zIndex: "0",
  },
  imgLeft: {
    height: "400px",
    margin: "15px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contTxt: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    "& h1": {
      fontFamily: "Accura",
      color: "#fff",
      fontSize: "70px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        textAlign: "center",
        fontWeight: "normal",
        color: "#fff",
        fontSize: "35px", //40?
      },
    },
    "& h2": {
      color: "#fff",
      fontSize: "30px",
      fontWeight: "normal",
      fontFamily: "Accura SemiBold",
    },
  },
  contTxtTwo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
    "& h2": {
      margin: "0px 5px",
      fontFamily: "Verdana",
      fontWeight: "normal",
      fontSize: "30px",
      color: "rgba(255,255,255, 0.5)",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
    "& h2:hover": {
      color: "white",
    },
    "& > div:nth-child(2) > h2": {
      color: "#FEC011",
      [theme.breakpoints.down("sm")]: {
        color: "#FEC011",
      },
    },
    "& > p": {
      maxWidth: "500px",
      color: "#fff",
      textAlign: "center",
      marginBottom: "20px",
      marginLeft: "20px",
      marginRight: "20px",
    },
  },
}));

const ParaQue = () => {
  const cls = useStyles();

  // const leftText = useRef(null);
  // const rightText = useRef(null);
  // const mainText = useRef(null);
  // const newText = useRef(null);
  // const container = useRef(null);

  const [leftTextValue, leftTextValueChange] = useState("Entrepreneurs");
  const [rightTextValue, rightTextValueChange] = useState("Ecommerce");
  const [mainTextValue, mainTextValueChange] = useState("Emprendedores");
  const [newTextValue, newTextValueChange] = useState("todos");

  const updateTextLeft = useCallback(() => {
    const left = leftTextValue;
    const center = mainTextValue;
    mainTextValueChange(left);
    leftTextValueChange(center);
    newTextValueChange(left);
  }, [
    leftTextValue,
    mainTextValue,
    mainTextValueChange,
    leftTextValueChange,
    newTextValueChange,
  ]);

  const updateTextRight = useCallback(() => {
    const right = rightTextValue;
    const center = mainTextValue;
    mainTextValueChange(right);
    rightTextValueChange(center);
    newTextValueChange(right);
  }, [
    rightTextValue,
    mainTextValue,
    mainTextValueChange,
    rightTextValueChange,
    newTextValueChange,
  ]);

  return (
    <div className={cls.root}>
      <div className={cls.cont}>
        <img
          data-aos="zoom-in-right"
          className={cls.imgLeft}
          src="./imgs/home/Grupo 204.png"
          alt="servicios"
        />
        <div className={cls.contTxt}>
          <div className={cls.contTxt}>
            <h1>¿Para quién es Zigu?</h1>
            <h2>
              Es para <span id="newText">{newTextValue}</span>
            </h2>
          </div>
          <div className={cls.contTxtTwo} id="testing">
            <div data-aos="zoom-in">
              <h2
                data-aos="zoom-in"
                id="leftText"
                className="leftText"
                onClick={updateTextLeft}
              >
                {leftTextValue}
              </h2>
            </div>
            <div data-aos="zoom-in-up">
              <h2 data-aos="zoom-in-up" id="mainText">
                {mainTextValue}
              </h2>
            </div>
            <div data-aos="zoom-in-down">
              <h2
                data-aos="zoom-in-down"
                id="rightText"
                className="rightText"
                onClick={updateTextRight}
              >
                {rightTextValue}
              </h2>
            </div>
          </div>
          <div className={cls.contTxtTwo}>
            <p>
              Si tienes un negocio en línea, ¡Zigu es para ti! No importa el
              tamaño o el tiempo que tengas operando. Zigu es un agregador
              especializado en brindarte las tarifas más bajas para manejar tus
              transacciones de venta sin complicaciones y máxima seguridad.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParaQue;
