import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: '17px',
    fontWeight: theme.typography.fontWeightRegular,
    color: '#7174d7',
    textAlign: 'center',
    width: '100%',
  
  },
  acordion: {
    boxShadow: 'none',
    textAlign: 'center',
    justifyContent: 'center',
  },
  center: {
    justifyContent: 'center',
  }
}));

const RenderHTML = ({texto}) => ( <Typography dangerouslySetInnerHTML={{__html: texto}}></Typography>)

const Preguntas = ({ data, search }) => {
  const classes = useStyles();

  //create a new array by filtering the original array
  const filteredData = data.filter((el) => {
      //if no input the return the original
      if (search === '') {
          return el;
      }
      //return the item which contains the user input
      else {

      // todo: agregar búsqueda sin acentos
          return el.pregunta.toLowerCase().includes(search)
      }
  })

  return (
    <div className={classes.root}>
      {filteredData.map((p, index) => (
        <Accordion key={index} className={classes.acordion}>
          <AccordionSummary className={classes.acordion} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
            <Typography className={classes.heading}>{p.pregunta}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.center} >
            <RenderHTML texto={p.respuesta} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default Preguntas
