export const cards = {
  boton: {
    title: "Botón de Pago",
    subTitle:
      "Cero complicaciones para tus clientes. Cobra directamente en tu sitio web, fácil y rápidamente.",
    firstSection: [
      {
        img: "./imgs/servicios/Sin Complicaciones.png",
        alt: "btn",
        color: "#70d4c5",
        title: "Sin complicaciones",
        txt: "Solo instala los componentes necesarios para activar tu botón de compra y listo. Comienza a vender.",
      },
      {
        img: "./imgs/servicios/Seguridad Total.png",
        alt: "btn",
        color: "#aa6cdf",
        title: "Seguridad total",
        txt: "Todas las transacciones son aprobadas con certificados de seguridad para que tú y tus clientes estén tranquilos.",
      },
      {
        img: "./imgs/servicios/Flexibilidad.png",
        alt: "btn",
        color: "#687de7",
        title: "Flexibilidad",
        txt: "Que tus clientes decidan usar tarjeta de crédito o débito y si desean pagar a meses sin intereses.",
      },
      {
        img: "./imgs/servicios/Transparencia.png",
        alt: "btn",
        color: "#d5597c",
        title: "Transparencia y claridad",
        txt: "Revisa todas las transacciones realizadas en tu tienda detalladamente sin cargos o retenciones escondidos.",
      },
    ],
    secondSection: {
      title: "Obtener tu botón de pago es fácil",
      items: [
        {
          img: "./imgs/servicios/Crea tu Cuenta.png",
          alt: "holo",
          title: "Crea tu Cuenta",
        },
        {
          img: "./imgs/servicios/Copia y Pega.png",
          alt: "holo",
          title: "Copia y pega el código en tu pagina",
        },
        {
          img: "./imgs/servicios/Comienza a Vender.png",
          alt: "holo",
          title: "Comienza a vender",
        },
      ],
    },
  },
  link: {
    title: "Link de Pago",
    subTitle: "¡Permite que tus clientes paguen desde cualquier dispositivo!",
    firstSection: [
      {
        img: "./imgs/servicios/Se crea.png",
        alt: "link",
        color: "#70d4c5",
        title: "Se crea facilmente",
        txt: "Con tus datos de pedido completa el registro de la orden.",
      },
      {
        img: "./imgs/servicios/No necesitas.png",
        alt: "link",
        color: "#aa6cdf",
        title: "No necesitas pagina web",
        txt: "El enlace es creado por Zigu, no necesitas una página.",
      },
      {
        img: "./imgs/servicios/Enlace Universal.png",
        alt: "link",
        color: "#687de7",
        title: "Un enlace universal",
        txt: "Copia y pega, puedes enviar el enlace.",
      },
      {
        img: "./imgs/servicios/Cualquier dispositivo.png",
        alt: "link",
        color: "#d5597c",
        title: "Para cualquier dispositivo",
        txt: "Accediendo al enlace, el cliente puede pagar sin importar el dispositivo.",
      },
      {
        img: "./imgs/servicios/Recibe.png",
        alt: "link",
        color: "#d5c259",
        title: "Recibe pagos con tarjeta",
        txt: "Las transacciones son procesadas con seguridad y aprobaciones estrictas.",
      },
      {
        img: "./imgs/servicios/Tarifa.png",
        alt: "link",
        color: "#59bfd5",
        title: "Tarifa más baja del mercado",
        txt: "Te ofrecemos las tarifas más competitivas para que tus ganancias no sufran.",
      },
    ],
    secondSection: {
      title: "Tan fácil como mandar un mensaje de texto",
      items: [
        {
          img: "./imgs/servicios/Crea tu Cuenta.png",
          alt: "holo",
          title: "Crea tu cuenta llenando nuestro sencillo formulario.",
        },
        {
          img: "./imgs/servicios/Copia y Pega.png",
          alt: "holo",
          title: "Crea el enlace usando tus datos de cobro.",
        },
        {
          img: "./imgs/servicios/Comienza a Vender.png",
          alt: "holo",
          title: "Envialo a tu cliente por chat, email, SMS, etc.",
        },
        {
          img: "./imgs/servicios/Obten tu Pago.png",
          alt: "holo",
          title: "Obten tu pago. ¡Asi de facil!",
        },
      ],
    },
  },
  api: {
    title: "API",
    subTitle:
      "Integra nuestra solución al nivel de tus necesidades de cobro con nuestra API.",
    firstSection: [
      {
        img: "./imgs/servicios/Adaptabilidad.png",
        alt: "api",
        color: "#70d4c5",
        title: "Adaptabilidad a plataformas",
        txt: "Desarrolla tu propia plataforma de pagos y conéctate a nuestro API para procesarlos.",
      },
      {
        img: "./imgs/servicios/Seguridad.png",
        alt: "api",
        color: "#aa6cdf",
        title: "Seguridad total",
        txt: "Encripta la información de las tarjetas de tus clientes automáticamente para proteger la seguridad de la transacción.",
      },
      {
        img: "./imgs/servicios/Flexibilidad.png",
        alt: "api",
        color: "#687de7",
        title: "Flexibilidad",
        txt: "Tu configuras las opciones disponibles para tus clientes, desde tarjetas de débito o crédito y meses sin intereses.",
      },
      {
        img: "./imgs/servicios/Soporte Tecnico.png",
        alt: "api",
        color: "#d5597c",
        title: "Soporte Tecnico",
        txt: "Nuestro equipo técnico de desarrolladores está a tu disposición para ayudarte a implementar y scomprender el funcionamiento del API.",
      },
    ],
    secondSection: {
      title: "Utiliza Zigu con las plataformas más usadas:",
      items: [
        {
          img: "./imgs/servicios/shopify.svg",
          alt: "Shopify",
          class: "centerImg",
        },
        {
          img: "./imgs/servicios/woocommerce.svg",
          alt: "Woocommerce",
          class: "centerImg",
        },
        {
          img: "./imgs/servicios/clearsale.png",
          alt: "Clearsale",
          class: "centerImg",
        },
      ],
    },
  },
};
