import { H1 } from "../Items";

export const data = { 
  preguntasGenerales: [
      {pregunta: '¿Qué divisa maneja Zigu?', respuesta: 'Actualmente se manejan Pesos mexicanos (MXN)'},
      {pregunta: '¿Si estoy fuera de México puedo usar Zigu?', respuesta: 'Sí es posible, pero el comercio debe ser persona física o moral mexicana. Sí se pueden aceptar tarjetas internacionales, pero los cargos y depósitos se harán en pesos mexicanos a la tasa de cambio definida por el banco el día en que se realice la operación.'},
      {pregunta: '¿Mi banco está dentro de la lista de aliados de Zigu?', respuesta: 'Aceptamos cualquier pago de cualquier banco que utilice tarjetas Visa, Master Card o AMEX'},
      {pregunta: '¿Cuáles son considerados giros prohibidos para procesar ordenes con Zigu?', respuesta: 'Por políticas propias de la empresa, no tendremos relación comercial con negocios que se dediquen a la venta de los siguientes productos y/o servicios:<br><br>'+
        '-Armas de fuego<br>'+
        '-Armas deportivas y municiones<br>'+
        '-Pólvora y otros explosivos<br>'+
        '-Carnes de animales exóticos<br>'+
        '-Fuegos artificiales<br>'+
        '-Materiales tóxicos<br>'+
        '-Combustibles<br>'+
        '-Inflamables y radiactivos<br>'+
        '-Venta de animales y seres vivos<br>'+
        '-Petróleo, sus derivados y productos finales<br>'+
        '-Multas judiciales<br>'+
        '-Gastos judiciales<br>'+
        '-Créditos usureros<br>'+
        '-Reparación o modificación de crédito<br>'+
        '-Reducción de deudas<br>'+
        '-Servicios de inmigración<br>'+
        '-Equipo y software para romper seguridad y entrometerse en hardware y software<br>'+
        '-Piratería y réplicas sin autorización de marca<br>'+
        '-Comercios relacionados con: bestialismo, abuso sexual, violencia, actos de odio, abuso'+
        'infantil y/o incesto; prostitución, pornografía.<br>'+
        '-Venta y reventa no autorizada de productos y/o servicios que estén protegidos con un'+
        'registro de marca.<br>'+
        '-Productos y/o servicios importados o exportados ilegalmente<br>'+
        '-Evasión de programas antifraude<br>'+
        '-Información de tarjetahabientes sin el permiso del titular de la cuenta<br>'+
        '-Falsificaciones de marcas registradas<br>'+
        '-Drogas y equipo para su fabricación y/o uso<br>'+
        '-Piramidales<br>'+
      '<br>Zigu se reserva el derecho de otorgar o negar sus servicios a cualquier cliente a discreción de la empresa según sus políticas internas.'},
      {pregunta: '¿Zigu cuenta con plug-ins o integración con soluciones ya existentes para comercio en línea?', respuesta: 'Si, Zigu cuenta con integracion con Shopify<br>También tenemos plug-ins para cientos de carritos de compras como Magento, Drupal, VirtueMart, Oscommerce, Woocommerce y Opencart.'},
  ],
  comoEmpezar: [
      {pregunta: '¿Qué requiero para abrir una cuenta?', respuesta: 'Con tener un RFC mexicano válido como persona moral o persona física y una cuenta bancaria, identificación oficial, comprobante de domicilio y llenar el formulario de alta (con liga al registro).'},
      {pregunta: '¿Como abro mi cuenta Zigu?', respuesta: 'Da click en abre tu cuenta. A continuación, llena el formulario y da click en abrir cuenta. Ve a tu correo electrónico y abre el correo de activación. Da click en el enlace para comprobar tu correo.<br>'+
      'Nota: Puedes abrir tu cuenta únicamente llenando los campos requeridos y después completar los que hayan faltado, así como subir los documentos necesarios para comenzar a utilizar el sistema.'},
      {pregunta: '¿Cómo valido mi cuenta?', respuesta: 'Ya que hayas llenado el formulario de registro recibirás un correo con los pasos necesarios para activar tu cuenta. Con esto podrás configurar y colocar tu cuenta de recepción y datos de empresa. Realiza una transacción y de dos a tres días será validada tu cuenta y estará lista.'},
      {pregunta: '¿Es necesario registrarme en otra parte para recibir los pagos?', respuesta: 'No, con tener una cuenta de banco y haber completado el proceso de registro estarás listo para recibir tus pagos.'},
      {pregunta: '¿En cuánto tiempo puedo empezar a usar mi cuenta?', respuesta: 'Una vez completado el proceso de registro, nuestro compromiso es que al siguiente día hábil tu cuenta esté activada y lista para comenzar a recibir pagos.'},
      {pregunta: '¿Cómo puedo integrar Zigu?', respuesta: 'Contamos con diferentes soluciones para que puedas comenzar a cobrar en línea. Integra fácilmente tu Boton de Pago o utiliza nuestra API con ayuda de un programador, o en caso de no tener una página, puedes usar el Enlace de Pago.'},
      {pregunta: '¿Puedo recibir pagos sin página web?', respuesta: 'Sí, con el Enlace de Pago, Zigu crea una página de pago personalizada con la información de la transacción para que puedas enviarla a tus clientes y cobrar de esa manera.'},
      {pregunta: '¿A quién debo acudir para integrar Zigu a mi página web?', respuesta: 'Un programador de páginas web puede utilizar nuestro portal de desarrolladores para consultar guías paso a paso para realizar la integración de nuestras soluciones.'},
  ],
  contracargos: [
    {pregunta: '¿Qué son los contracargos?', respuesta: 'Un contracargo se genera cuando un tarjetahabiente no reconoce un cargo realizado a su tarjeta y realiza una reclamación con su banco. El banco entonces gira la solicitud de contracargo al vendedor. Zigu te informará del contracargo y en caso de desees contenderlo, te solicitaremos la información pertinente para rebatirlo con el banco. En caso de que el contracargo proceda, se descontará del saldo de la cuenta y se te informará al correo que tengas registrado.'},
    {pregunta: '¿Para qué sirve un contracargo?', respuesta: 'Sirve para evitar cargos no autorizados por el tarjetahabiente.'},
    {pregunta: '¿Cómo diminuyo la posibilidad de contracargos?', respuesta: 'En la confirmación de pago avisa a tu cliente como aparecerá el cargo, para que lo pueda identificar. Si tenías que enviar un pedido, estima la fecha de entrega adecuadamente para que no lo cancelen y mantén informado a tu cliente en todo el proceso de entrega.'},
    {pregunta: '¿Puedo prevenir cargos duplicados?', respuesta: 'Sí, al implementar la solución puedes incluir el manejo de errores para que no se cobre dos veces al cliente. Si sucede, lo mejor es realizar la devolución y ponerse en contacto con el cliente para evitar contracargos.'},
    {pregunta: '¿Cómo prevengo contracargos en suscripciones?', respuesta: 'Al momento en que recibas una petición de suspensión realízala al momento para evitar que pasen la fecha de renovación y se genere un cargo no deseado.'},
    {pregunta: '¿Cómo prevengo contracargos en caso de productos?', respuesta: 'Envía bien empaquetado, a tiempo y con guía de rastreo de ser posible. Además comunica cualquier detalle a tu cliente. Especifica claramente lo que ofreces y el estado en que se encuentra. Contesta las dudas de tu cliente.'},
    {pregunta: '¿A dónde me llegan las notificaciones de contracargos?', respuesta: 'Al recibir el mensaje del banco, la notificación se envía al instante al correo que ingresaste al darte de alta en Zigu y en tu portal de usuario Zigu.'},
    {pregunta: '¿Cuánto me cuesta un contracargo?', respuesta: 'El monto es igual a la transacción cancelada y la comisión por venta, pero no hay cargos extra.'},
    {pregunta: '¿Cómo te ayuda Zigu con los contracargos?', respuesta: 'Nosotros recopilamos la evidencia y se envía al banco para validación. En caso de que se resuelva a favor del comercio, el saldo retenido será devuelto y depositado en tu cuenta. En caso de que se resuelva en contra del comercio, el monto retirado será depositado a tu cliente.'},
    {pregunta: '¿Qué pasos debo seguir al recibir un contracargo?', respuesta: 'Entra a la sección de Admin para revisar los contracargos.<br>Ahí verás a los que se han generado.<br>Comunicate con tu cliente por si acaso no recuerda su compra.<br>Si aceptas el contracargo ya no es necesario hacer nada.<br>Si no aceptas, deberás subir la información y evidencia por medio del Admin y solo en el plazo permitido por el banco.<br> En caso de error de página al subir la información se deberá informar a Zigu al correo ayuda@zigu.mx dentro del plazo indicado.<br>La información pertinente que puede ayudarnos a realizar la validación es:<br>'+
    '-Recibo del cargo o cotización<br>  -Factura<br>  -Comunicaciones con el cliente<br>  -Identificación del cliente<br>  -Guía de embarque y confirmación de entrega con firma si existen.<br>  -Otros<br>'},
    {pregunta: '¿Cuánto tiempo toma la resolución?', respuesta: 'De 3 a 4 semanas es lo normal, pero puede ser hasta 180 días hábiles.'},
    {pregunta: '¿Cuánto tiempo debo mantener la información en mis bases de datos?', respuesta: 'Los contracargos pueden hacerse a un máximo de 180 días. En tarjetas refrentes a agencias de viajes, paquetes vacacionales, entregas, membresías y seguros, el máximo es de 540 días.'},
    {pregunta: '¿Quién decide si el contracargo aplica?', respuesta: 'Zigu se encarga de contender los contracargos revisando con el banco la evidencia que envíes. Sin embargo, la decisión de aplicar o revertir los contracargos recae en las autoridades encargadas de revisar las transacciones bancarias.'},
  ],
  metodosPago: [
    {pregunta: '¿Qué tipo de tarjetas puedo usar?', respuesta: 'Con Zigu podrás procesar las siguientes tarjetas de débito:<br>-Visa y Mastercard<br><br>Tarjetas de crédito:<br>-Visa, Mastercard y American Express<br><br>Para realizar cargos a Meses Sin Intereses, la disponibilidad de esta opción y la cantidad de meses permitidos dependerá del banco que emitió la tarjeta.'},
    {pregunta: '¿Por qué si en una sucursal con terminal física mi tarjeta pasa la mayoría de las veces, cuando se hace una compra en línea la compra es rechazada?', respuesta: 'Por la seguridad del tarjetahabiente, la transacciones en línea tienen que pasar filtros antifraude con requisitos más rigurosos que las transacciones hechas con la tarjeta presente. Por eso, si la transacción en línea activa alguna de las banderas de seguridad del sistema antifraude, es posible que la transacción sea declinada.'},
    {pregunta: '¿Por qué se declinó el cargo a la tarjeta?', respuesta: 'Las razones principales por las cuales se rechaza un cargo a tarjeta de débito o crédito por una <br> transacción en línea son:<br><br>1. El cargo no es aceptado por el banco emisor, ya que muchos bancos no permiten cargos en línea en sus tarjetas o en cierto tipo de cuenta.<br><br>2. Los bancos y sistemas de Zigu cuentan con algoritmos antifraude rigurosos para detectar actividades sospechosas en cada transacción procesada y si ésta activa alguna de las banderas de alerta, el banco o Zigu pueden decidir no procesar la transacción y proteger a tu negocio de algún fraude.'},
    {pregunta: 'Si se elige el cargo por meses sin intereses, ¿recibiré una notificación cada que se le cobre al cliente?', respuesta: 'No, el cobro a la tarjeta vía meses sin intereses es realizado por el banco. Por lo tanto, Zigu no enviará una notificación cada que el banco realice el cobro mes con mes, sino que aparecerá en el reporte como un cargo por meses sin intereses.'},
    {pregunta: 'Si se genera un cobro a meses sin intereses, ¿se me depositará cada mes que se le cobre al cliente?', respuesta: 'Cuando el cliente procesa un pago a meses sin intereses, tu recibirás la cantidad total de la compra (menos la tarifa de comisión de servicio de Zigu) y el banco se encargará de cobrarle al cliente mes con mes hasta completar la cantidad total de su compra.'},
  ]
}