import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core";
// import {Link} from 'react-router-dom'
import { Boton } from "./Items";
import { Divider } from "@material-ui/core";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useWindowResize } from "./hooks/useWindowResize";
import { red } from "@material-ui/core/colors";

const REGISTRO = gql`
  mutation REGISTRO(
    $nombre: String!
    $apellidoP: String!
    $apellidoM: String!
    $correo: String!
    $contrasena: String!
    $confirmarContrasena: String!
    $tipoPersona: PersonType!
    $telefonoCelular: String!
    $nombreEmpresa: String!
    $razonSocial: String!
    $telefonoCasa: String!
    $telefonoOficina: String!
    $ife: String!
    $curp: String!
    $rfc: String!
    $calle: String!
    $numeroExt: String!
    $numeroInt: String!
    $colonia: String!
    $municipio: String!
    $estado: String!
    $pais: String!
    $codigoPostal: String!
    $telefonoOficinaEmpresa: String!
    $telefonoServicioEmpresa: String!
    $correoAdminZigu: String!
    $correoAdminEmpresa: String!
    $correoServicio: String!
    $rfcEmpresa: String!
    $calleEmpresa: String!
    $numeroExtEmpresa: String!
    $numeroIntEmpresa: String!
    $coloniaEmpresa: String!
    $municipioEmpresa: String!
    $estadoEmpresa: String!
    $paisEmpresa: String!
    $codigoPostalEmpresa: String!
  ) {
    register(
      userInfo: {
        nombre: $nombre
        apellidoP: $apellidoP
        apellidoM: $apellidoM
        correo: $correo
        contrasena: $contrasena
        confirmarContrasena: $confirmarContrasena
        telefonoCelular: $telefonoCelular
        telefonoCasa: $telefonoCasa
        telefonoOficina: $telefonoOficina
        ife: $ife
        curp: $curp
        rfc: $rfc
        calle: $calle
        numeroExt: $numeroExt
        numeroInt: $numeroInt
        colonia: $colonia
        municipio: $municipio
        estado: $estado
        pais: $pais
        codigoPostal: $codigoPostal
      }
      businessInfo: {
        persona: $tipoPersona
        nombreEmpresa: $nombreEmpresa
        razonSocial: $razonSocial
        telefonoOficinaEmpresa: $telefonoOficinaEmpresa
        telefonoServicioEmpresa: $telefonoServicioEmpresa
        correoAdminZigu: $correoAdminZigu
        correoAdminEmpresa: $correoAdminEmpresa
        correoServicio: $correoServicio
        rfcEmpresa: $rfcEmpresa
        calleEmpresa: $calleEmpresa
        numeroExtEmpresa: $numeroExtEmpresa
        numeroIntEmpresa: $numeroIntEmpresa
        coloniaEmpresa: $coloniaEmpresa
        municipioEmpresa: $municipioEmpresa
        estadoEmpresa: $estadoEmpresa
        paisEmpresa: $paisEmpresa
        codigoPostalEmpresa: $codigoPostalEmpresa
      }
    ) {
      payload {
        token
        user {
          id
        }
      }
      error {
        message
        path
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  cont: {
    marginTop: "-350px",
    maxWidth: "1280px",
    border: "solid 1px #fff",
    width: "723px",
    height: "610px",
    borderRadius: "45px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-600px",
      border: "none",
      width: "100vw",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    "& > div:nth-child(1)": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
      padding: "15px 0px",
    },
    "& > div:nth-child(1) > div": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    "& input": {
      width: "300px",
      height: "42px",
      fontSize: "15px",
      margin: "10px",
      paddingLeft: "15px",
      border: "none",
      [theme.breakpoints.down("sm")]: {
        width: "80%",
        // minWidth: "300px",
        textAlign: "center",
      },
    },

    "& select": {
      width: "315px",
      height: "42px",
      fontSize: "15px",
      margin: "10px",
      paddingLeft: "15px",
      border: "none",
      background: "#fff",
      [theme.breakpoints.down("sm")]: {
        width: "82%",
        minWidth: "300px",
        textAlign: "center",
      },
    },
  },
  legend: {
    marginLeft: "100px",
    fontSize: "40px",
    color: "#fff",
  },
  divider: {
    height: "1px",
    background: "#fff",
    width: "100%",
    color: "#fff",
    margin: "10px 0px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  sesion: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "& > label": {
      color: "#000",
      marginTop: "10px",
    },
    "& > button": {
      color: "#7174d7",
      marginTop: "10px",
    },
  },
  terms: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& input": {
      width: "20px",
      // justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        width: "20px",
      },
    },
    "& label": {
      display: "flex",
      alignItems: "center",
      marginTop: "-20px",
      "& a": {
        color: "#7174d7",
      },
      "& input": {
        borderRadius: "70%",
        // check rounded
      },
    },
  },
}));

const Registro = () => {
  const cls = useStyles();
  const [windowWidth] = useWindowResize();
  const [values, setValues] = useState({
    tipoPersona: "FISICA",
  });
  const [registrar] = useMutation(REGISTRO);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = useCallback(
    (name) => (event) => {
      const newValues = {
        ...values,
      };
      newValues[name] = event.target.value;
      setValues(newValues);
    },
    [values, setValues]
  );

  const handleCheck = useCallback(
    (name) => (event) => {
      const newValues = {
        ...values,
      };
      newValues[name] = event.target.checked;
      setValues(newValues);
    },
    [values, setValues]
  );

  const handleRegister = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        if (!values.nombre) {
          throw new Error("Favor de ingresar un Nombre");
        }
        if (!values.apellidoP) {
          throw new Error("Favor de ingresar un Apellido Paterno");
        }
        if (!values.apellidoM) {
          throw new Error("Favor de ingresar un Apellido Materno");
        }
        if (!values.telefonoCelular) {
          throw new Error("Favor de ingresar un Teléfono");
        }
        if (!values.tipoPersona) {
          throw new Error("Favor de ingresar un Tipo de persona");
        }
        if (!values.correo) {
          throw new Error("Favor de ingresar un Correo");
        }
        if (!values.contrasena) {
          throw new Error("Favor de ingresar una Contraseña");
        }
        if (!values.reContrasena || values.reContrasena !== values.contrasena) {
          throw new Error("La contraseña debe coincidir");
        }
        if (!values.terminosCondiciones) {
          throw new Error("Favor de leer y aceptar los Términos y Condiciones");
        }
        if (!values.politicaPrivacidad) {
          throw new Error("Favor de leer y aceptar la Política de Privacidad");
        }

        const result = await registrar({
          variables: {
            nombre: values.nombre,
            apellidoP: values.apellidoP,
            apellidoM: values.apellidoM,
            correo: values.correo,
            tipoPersona: values.tipoPersona,
            contrasena: values.contrasena,
            confirmarContrasena: values.reContrasena,
            telefonoCelular: values.telefonoCelular,
            nombreEmpresa: `${values.nombre} ${values.apellido}`,
            razonSocial: `${values.nombre} ${values.apellido}`,

            telefonoCasa: "",
            telefonoOficina: "",
            ife: "",
            curp: "",
            rfc: "",
            calle: "",
            numeroExt: "",
            numeroInt: "",
            colonia: "",
            municipio: "",
            estado: "",
            pais: "",
            codigoPostal: "",
            telefonoOficinaEmpresa: "",
            telefonoServicioEmpresa: "",
            correoAdminZigu: "",
            correoAdminEmpresa: "",
            correoServicio: "",
            rfcEmpresa: "",
            calleEmpresa: "",
            numeroExtEmpresa: "",
            numeroIntEmpresa: "",
            coloniaEmpresa: "",
            municipioEmpresa: "",
            estadoEmpresa: "",
            paisEmpresa: "",
            codigoPostalEmpresa: "",
          },
        });
        const token = result?.data?.register?.payload?.token;
        console.log(result);
        if (token) {
          console.log(token);
          const token64 = btoa(token);
          window.open(`https://admin.zigu.mx/token/${token64}`, "_self");
          // window.open(`http://localhost:3000/token/${token64}`, '_self')
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      }
      console.log(values);
    },
    [values, registrar, enqueueSnackbar]
  );

  return (
    <fieldset className={cls.cont}>
      <legend
        className={cls.legend}
        style={windowWidth < 960 ? { margin: "0 auto" } : null}
      >
        Regístrate
      </legend>
      <form className={cls.form}>
        <div>
          <div>
            <input
              type="text"
              placeholder="Nombre(s)"
              onChange={handleChange("nombre")}
            />
            <input
              type="text"
              placeholder="Apellido Paterno"
              onChange={handleChange("apellidoP")}
            />
            <input
              type="text"
              placeholder="Apellido Materno"
              onChange={handleChange("apellidoM")}
            />
            <select onChange={handleChange("tipoPersona")}>
              <option value="" disabled>
                Tipo de Persona
              </option>
              <option value="FISICA">Fisica</option>
              <option value="MORAL">Moral</option>
            </select>
          </div>
          <div>
            <input
              type="text"
              placeholder="Teléfono"
              onChange={handleChange("telefonoCelular")}
            />
            <input
              type="text"
              placeholder="Correo Electrónico"
              onChange={handleChange("correo")}
            />
            <input
              type="password"
              placeholder="Contraseña"
              onChange={handleChange("contrasena")}
            />
            <input
              type="password"
              placeholder="Confirma tu Contraseña"
              onChange={handleChange("reContrasena")}
            />
          </div>
        </div>
        <div className={cls.terms}>
          <label>
            He leído y acepto los&nbsp;
            <a href="/terminos-y-condiciones"> términos y condiciones </a>
            <input
              name="Terminos"
              type="checkbox"
              className={cls.check}
              onChange={handleCheck("terminosCondiciones")}
            />
          </label>
          <label>
            He leído y acepto la&nbsp;
            <a href="/aviso-de-privacidad"> política de privacidad </a>
            <input
              name="Aviso"
              type="checkbox"
              className={cls.check}
              onChange={handleCheck("politicaPrivacidad")}
              // checked={this.state.isGoing}
            />
          </label>
        </div>
        <Divider className={cls.divider} />
        <div className={cls.sesion}>
          {/* <label>¿Olvidaste tu Contraseña?</label> */}
          <label>&nbsp;</label>
          <div onClick={handleRegister}>
            <Boton
              val="Enviar"
              txt="Inicia Sesión"
              location="#"
              type="btnMorado"
            />
          </div>
          <label>¿Ya tienes una cuenta?</label>
          <Boton
            val="Inicia Sesión"
            txt="Inicia Sesión"
            location="sesion"
            type="btnMorado"
          />
        </div>
      </form>
    </fieldset>
  );
};

export default Registro;
