import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    btn: {
        borderRadius: '15px 0px',
        height: '40px',
        width: '200px',
        cursor: 'pointer',
        transition: 'background .2s',
        '& a':{
            height: '100%',
            fontWeight: 'bold',
            textDecoration: 'none',
            fontSize: '14px',
        },
        '&:hover': {
            transition: '.3s',
            transform: 'scale(0.9)',
        },    
    },
    btnSesion: {
        boxShadow: '0px 0px 10px 0px rgba(113,116,215,0.75)',
        border: 'solid #7174d7 3px',
        background: '#FEC011',
        '&:hover': {
            backgroundColor: '#efb40e',
        },
        '& a': {
            color: '#7174d7',
        }
    },
    btnMorado: {
        boxShadow: '0px 0px 10px 0px rgba(113,116,215,0.75)',
        border: 'none',
        background: '#7174d7',
        '&:hover': {
            backgroundColor: '#6668c1',
        },
        '& a': {
            color: '#fff',
        }
    },
    btnAmarillo: {
        boxShadow: '0 0 16px rgb(254 192 17 / 52%)',
        border: 'none',
        background: '#fec011',
        '&:hover': {
            backgroundColor: '#efb40e',
        },
        '& a': {
            color: '#000',
        }
    },
    btnAmarilloLight: {
        borderRadius: '15px 0px',
        cursor: 'pointer',
        background: '#FEC011',
        color: '#fff',
        border: 'none',
        '& a': {
            color: '#fff',
        },
        boxShadow: '0 0 16px rgb(254 192 17 / 52%)',
    },
    h1: props => ({
        fontWeight: 'bold',
        fontSize: '75px',
        fontFamily: 'Accura SemiBold',
        [theme.breakpoints.down('sm')]: {
            fontSize: '70px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '50px',
        },
        color: props.color
    }),
    h2: props => ({
        fontWeight: 'bold',
        fontSize: '40px',
        color: props.fontColor,
    }),
    label: props => ({
        fontFamily: 'Accura SemiBold',
        color: props.fontColor,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '18px',
        verticalAlign: 'bottom',
    }),
    p: props => ({
        color: props.fontColor,
        textAlign: 'center',
        fontSize: '16px',
        alignItems: 'center',
    }),
    box: {
        border: '2px dotted blue',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '300px',
        height: '200px',
        [theme.breakpoints.down('sm')]: {
            width: '270px',
            height: '180px',
        },
        padding: '10px',
        margin: '10px'
    }
}))

export const Boton = (props) => {
    const cls = useStyles({...props});
    return <button className={`${cls.btn} ${cls[props.type]}`}>
                <a href={props.location}>{props.val}</a>
            </button>
}

export const H1 = ({txt, color}) => {
    const cls = useStyles({color})
    return <h1 className={cls.h1}>{txt}</h1>
}

export const H2 = ({txt, color}) => {
    const fontColor = color ? color : '#fff'
    const cls = useStyles({fontColor});
    return <h2 className={cls.h2}>{txt}</h2>
}

export const Label = ({txt, color}) => {
    const fontColor = color ? color : '#7174D7'
    const cls = useStyles({fontColor})
    return <label className={cls.label}>{txt}</label>
}

export const Ptxt = ({txt, color}) => {
    const fontColor = color ? color : '#9e9e9e'
    // const fontSize ? size ? size
    const cls = useStyles({fontColor})
    return <p className={cls.p}>{txt}</p>
}

export const Box = ({children}) => {
    const cls = useStyles()
    return <div className={cls.box}>{children}</div>
}