import {useState, useEffect} from 'react'

export const useWindowResize = () => {
    const [windowWidth, setWindiowWidth] = useState(null)
    const [windowHeight, setWindiowHeight] = useState(null)

    useEffect(() => {
        const setResize = () => {
            setWindiowWidth(window.innerWidth)
            setWindiowHeight(window.innerHeight)
        }

        window.addEventListener('resize', setResize)
        window.addEventListener('load', setResize)

        setResize()

        return () => window.removeEventListener("load", setResize)
    },[])

    return [windowWidth, windowHeight]
}
