import React from 'react'
import Preguntas from './Preguntas'
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { data } from './Data';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    preguntas: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    indicator: {
      backgroundColor: '#FEC011',
    },
    tabs: {

      '& .MuiTabs-flexContainer': {
        [theme.breakpoints.up('sm')]: {
          display: 'flex',
          justifyContent: 'space-around',
        },
      }
    },
    h1t: {
      textAlign: 'center',
      fontSize: '50px',
      margin: '10px 0px 30px 0px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
          margin: '5px 0px 30px 0px',
        },
    },
    // center: {
    //   display: 'flex',
    //   flexDirection: 'column',
    //   justifyContent: 'center',
    // }
  }));
  
const MultipleCardFaqs = (props) => {
    const {search} = props
    const cls = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleClick = (e) => {
      e.currentTarget.classList.add(cls.tab)
    }

    return (
        <div className={cls.root}>
          <Tabs
            classes={{indicator: cls.indicator}}
            value={value}
            onChange={handleChange}
            className={cls.tabs}
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab onClick={e => handleClick(e)} className={`linkPregunta ${cls.tab}`} label="PREGUNTAS GENERALES" {...a11yProps(0)} />
            <Tab onClick={e => handleClick(e)} className='linkPregunta' label="CÓMO EMPEZAR" {...a11yProps(1)} />
            <Tab onClick={e => handleClick(e)} className='linkPregunta' label="CONTRACARGOS" {...a11yProps(2)} />
            <Tab onClick={e => handleClick(e)} className='linkPregunta' label="MÉTODOS DE PAGO" {...a11yProps(3)} />
          </Tabs>
        <TabPanel value={value} index={0}>
            <h1 className={cls.h1t}>Preguntas Generales</h1>
          <Preguntas data={data.preguntasGenerales} search={search}/>
          </TabPanel>
        <TabPanel value={value} index={1}>
              <h1 className={cls.h1t}>Cómo Empezar</h1>
              <Preguntas data={data.comoEmpezar} search={search}/>
          </TabPanel>
        <TabPanel value={value} index={2}>
              <h1 className={cls.h1t}>Contracargos</h1>
              <Preguntas data={data.contracargos} search={search}/>
          </TabPanel>
        <TabPanel value={value} index={3}>
              <h1 className={cls.h1t}>Métodos de pago</h1>
              <Preguntas data={data.metodosPago} search={search}/>
          </TabPanel>
        </div>
    );
}

  export default MultipleCardFaqs;