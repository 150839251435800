import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import urvita from "../../imgs/clientes/urvita.png";
import saphiro from "../../imgs/clientes/saphiro.png";
import condovive from "../../imgs/clientes/condovive.png";
import rawsociety from "../../imgs/clientes/rawsociety.png";
import emissary from "../../imgs/clientes/emissary.PNG";
import masterholder from "../../imgs/clientes/masterholder.png";
import ridenburn from "../../imgs/clientes/ridenburn.png";
import mercado from "../../imgs/clientes/mercado-final.png";
import richerlogo from "../../imgs/clientes/richerlogo.png";
import vapormex from "../../imgs/clientes/vapormex.png";
import "../../embla.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    position: "relative",
    "& div": {
      maxWidth: "1280px",
    },
  },
  cont: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    alignItems: "center",
    flexDirection: "column",
    border: "1px dotted blue",
    padding: "50px",
    zIndex: -1,
    top: "-30px",
    width: "1000px",
    // height: "250px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      margin: "10px",
      width: "80%",
      overflow: "hidden",
      //   height: "200px",
    },
  },
  titulo: {
    fontFamily: "Accura SemiBold",
    fontWeight: "bold",
    fontSize: "70px",
    backgroundColor: "white",
    textAlign: "center",
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
    },
  },

  logo: {
    display: "flex",
    position: "relative",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    margin: "5px",
    padding: "10px",
    "& > *": {
      flex: "0 1 33%",
      [theme.breakpoints.down("sm")]: {
        flex: "1 1 50%",
      },
    },
  },

  leftArrow: {
    top: "50%",
    left: "50px",
    fontSize: "3rem",
    color: "#7174d7",
    zIndex: 5,
    cursor: "pointer",
    userSelect: "none",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
      display: "none",
    },
  },
  rightArrow: {
    top: "50%",
    right: "50px",
    fontSize: "3rem",
    color: "#7174d7",
    zIndex: 5,
    cursor: "pointer",
    userSelect: "none",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
      display: "none",
    },
  },
  full: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  embla: {},
}));

const w = "80%";

const clientes = [
  {
    img: "./imgs/clientes/urvita.PNG",
    alt: "urvita",
    width: w,
    img1: "./imgs/clientes/saphiro.png",
    alt1: "saphiro",
    // width: w,
    img2: "./imgs/clientes/condovive.png",
    alt2: "condovive",
    // width: w,
    img3: "./imgs/clientes/ridenburn.png",
    alt3: "ride n burn",
    // width: w,
    img4: "./imgs/clientes/rawsociety.png",
    alt4: "rawsociety",
    // width: w,
    img5: "./imgs/clientes/emissary.png",
    alt5: "emissary",
    // width: w,
  },
  {
    img: "./imgs/clientes/emissary.PNG",
    alt: "emissary",
    width: w,
    img1: "./imgs/clientes/rawsociety.png",
    alt1: "rawsociety",
    // width: w,
    img2: "./imgs/clientes/masterholder.png",
    alt2: "master holder",
    // width: w,
    img3: "./imgs/clientes/mercado-final.png",
    alt3: "mercado cpap",
    // width: w,
    img4: "./imgs/clientes/richerlogo.png",
    alt4: "richer",
    // width: w,
    img5: "./imgs/clientes/vapormex.png",
    alt5: "vapormex",
    // width: w,
  },
];

const Clientes = () => {
  const cls = useStyles();

  //   const [current, setCurrent] = useState(0);
  //   const length = clientes.length;
  const [emblaRef] = useEmblaCarousel(
    {
      align: "start",
    },
    [Autoplay()]
  );

  if (!Array.isArray(clientes) || clientes.length <= 0) {
    return null;
  }

  //   const nextSlide = () => {
  //     setCurrent(current === length - 1 ? 0 : current + 1);
  //     clearTimeout(handleTimeOut);
  //   };

  //   const prevSlide = () => {
  //     setCurrent(current === 0 ? length - 1 : current - 1);
  //     clearTimeout(handleTimeOut);
  //   };

  //   const handleTimeOut = setTimeout(() => nextSlide(), 4000);

  return (
    <div className={cls.root}>
      <h1 className={cls.titulo}>Nuestros Clientes</h1>
      {/* actual logos  */}
      <div className={`${cls.cont} embla`}>
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            <div className="embla__slide">
              <img src={urvita} alt={"urvita"} className="embla__slide__img" />
            </div>
            <div className="embla__slide">
              <img
                src={saphiro}
                alt={"saphiro"}
                className="embla__slide__img"
              />
            </div>
            <div className="embla__slide">
              <img
                src={condovive}
                alt={"condovive"}
                className="embla__slide__img"
              />
            </div>

            <div className="embla__slide">
              <img
                src={rawsociety}
                alt={"rawsociety"}
                className="embla__slide__img"
              />
            </div>
            <div className="embla__slide">
              <img
                src={emissary}
                alt={"emissary"}
                className="embla__slide__img"
              />
            </div>
            <div className="embla__slide">
              <img
                src={masterholder}
                alt={"masterholder"}
                className="embla__slide__img"
              />
            </div>
            <div className="embla__slide">
              <img
                src={ridenburn}
                alt={"ridenburn"}
                className="embla__slide__img"
              />
            </div>
            <div className="embla__slide">
              <img
                src={mercado}
                alt={"mercado final"}
                className="embla__slide__img"
              />
            </div>
            <div className="embla__slide">
              <img
                src={richerlogo}
                alt={"richerlogo"}
                className="embla__slide__img"
              />
            </div>
            <div className="embla__slide">
              <img
                src={vapormex}
                alt={"vapormex"}
                className="embla__slide__img"
              />
            </div>
          </div>
        </div>
        {/* <div data-aos-delay={`100`} data-aos="fade-up">
          <div
            className={cls.logo}
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-delay="50"
            data-aos-mirror="true"
          >
            <div>
              <img
                src={"./imgs/clientes/emissary.PNG"}
                alt={"emissary"}
                width={"80%"}
              />
            </div>
            <div>
              <img
                src={"./imgs/clientes/emissary.PNG"}
                alt={"emissary"}
                width={"80%"}
              />
            </div>
          </div>
        </div> */}
        {/* {clientes.map((c, i) => {
          return (
            <div key={i} data-aos-delay={`${i + 5}00`} data-aos="fade-up">
              {i === current && (
                <div
                  className={cls.logo}
                  data-aos="fade-in"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-delay="50"
                  data-aos-mirror="true"
                >
                  <div>
                    <img src={c.img} alt={c.alt} width={c.width} />
                  </div>
                  <div>
                    <img src={c.img1} alt={c.alt1} width={c.width} />
                  </div>
                  <div>
                    <img src={c.img2} alt={c.alt2} width={c.width} />
                  </div>
                  <div>
                    <img src={c.img4} alt={c.alt3} width={c.width} />
                  </div>
                  <div>
                    <img src={c.img5} alt={c.alt4} width={c.width} />
                  </div>
                  <div>
                    <img src={c.img3} alt={c.alt5} width={c.width} />
                  </div>
                </div>
              )}
            </div>
          );
        })} */}
      </div>
      {/* <FaArrowAltCircleLeft className={cls.leftArrow} onClick={prevSlide} />
      <FaArrowAltCircleRight className={cls.rightArrow} onClick={nextSlide} /> */}
    </div>
  );
};

export default Clientes;
