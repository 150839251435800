import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { H1 } from "./Items";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "50px",
    [theme.breakpoints.down("sm")]: {
      padding: "30px",
    },
  },
  text: {
    textAlign: "left",
    fontFamily: "Open Sans",
    marginTop: "10px",
  },
  heading: {
    textAlign: "left",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    marginTop: "30px",
    color: "black",
  },
  ordered: {
    listStyleType: "lower-alpha",
    paddingLeft: "50px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "30px",
    },
    "& > li": {
      fontFamily: "Open Sans",
      marginTop: "10px",
    },
    "& > li > ul": {
      paddingLeft: "50px",
      fontFamily: "Open Sans",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "30px",
      },
    },
  },
}));

const AvisoDePrivacidad = () => {
  const cls = useStyles();
  return (
    <div className={cls.root}>
      <div>
        <p className={cls.text}>
          A fin de dar cumplimiento con lo establecido en la Ley Federal de
          Protección de Datos Personales en Posesión de los Particulares, su
          Reglamento y Lineamientos aplicables (la “Ley”), Procesadora de Pagos
          Electrónicos, S.A.P.I. de C.V., sus filiales y/o subsidiarias, y/o sus
          partes relacionadas (“Zigu”), con domicilio en [Av. Calzada del Valle
          #400 interior 1021, Col. Del Valle, San Pedro Garza García, Nuevo
          León, código postal 66220] , (el “Domicilio”), dirección electrónica:
          https://www.zigu.mx/home (el “Sitio” y/o la “Plataforma”), titular de
          los derechos del Software denominado Zigu (el “Software”) para su uso
          a través de la Plataforma y demás plataformas digitales y aplicaciones
          web y/o móviles presentes y futuras de su propiedad, y con correo
          electrónico de contacto [atencion@zigu.mx] (el “Correo Electrónico”),
          pone a su disposición el presente:
        </p>
        <h3 className={cls.heading}>
          <b>Aviso de Privacidad</b>
        </h3>
        <p className={cls.text}>
          Con la finalidad de dar un tratamiento legítimo, controlado e
          informado a sus datos personales, que actualmente nos proporcione o en
          el futuro y que obren en nuestras bases de datos, o que hayan sido
          recopilados por cookies, o cualquier otra tecnología de seguimiento
          web, así como para garantizar su privacidad y su derecho a la
          autodeterminación informativa al proporcionarnos dichos datos, siendo
          Zigu responsable del uso y protección de sus datos personales los
          cuales serán tratados con base en los principios de licitud,
          consentimiento, información, calidad, finalidad, lealtad,
          proporcionalidad y responsabilidad previstos en la Ley.
        </p>
        <h3 className={cls.heading}>
          <b>UTILIZACIÓN DE LA INFORMACIÓN</b>
        </h3>
        <p className={cls.text}>
          La información que usted nos provea a través del acceso, registro y
          creación de su cuenta en el Sitio, y/o correo electrónico, y/o llenado
          de formularios o encuestas físicas o electrónicas, en tiempo real o
          histórico, se procesará y ordenará, para que genere indicadores de
          datos, mismos que Zigu podrá usar para tomar decisiones pertinentes a
          su negocio. Toda la información que sea recopilada se utilizará con
          fines estadísticos, de manera genérica y no personalizada, y se
          asocian con el crecimiento, mantenimiento y administración Zigu,
          respetando en todo momento su privacidad. Estos usos incluyen nuestras
          operaciones y administración internas, la comunicación con usted y el
          cumplimiento de las solicitudes de servicios y/o productos provistos
          por Zigu, así como para mejorar, desarrollar, perfeccionar y,
          proporcionar los servicios Zigu, a través de sus partes relacionadas,
          filiales, o proveedores autorizados y/o socios comerciales,
          estableciendo las medidas adecuadas a fin de limitar el uso de la
          información recabada de usted, únicamente para fines legales y
          autorizados de conformidad con este Aviso, así como con las debidas
          medidas de confidencialidad y seguridad.
        </p>
        <p className={cls.text}>
          Zigu también podrá recabar su dirección de IP (Internet Protocol) para
          ayudar a diagnosticar problemas con el servidor Zigu y para
          administrar el Sitio y la Plataforma. Una dirección de IP es un número
          que se le asigna a su computadora cuando usa Internet. Su dirección de
          IP también es utilizada para ayudar a identificarle dentro de una
          sesión particular y para recolectar información demográfica general.
          Zigu podrá hacer uso de tecnología “push” a través de la aplicación
          que Zigu usa para enviar notificaciones con autorización previa del
          Usuario. Este medio de comunicación no tiene ningún tipo de acceso a
          otras funciones o información del equipo con el que se conecta al
          Sitio. La información puede incluir la URL de la que provienen (estén
          o no en el Sitio), a qué URL acceden seguidamente (estén o no en el
          Sitio), qué navegador están usando, así como también las páginas
          visitadas, las búsquedas realizadas, las publicaciones, preferencias
          comerciales, mensajes, etc.
        </p>
        <h3 className={cls.heading}>
          <b>USO DE COOKIES</b>
        </h3>
        <p className={cls.text}>
          Zigu le informa que, mediante el uso de cookies y tecnologías
          similares, busca garantizar la mejor experiencia posible en el Sitio
          y/o la Plataforma, al proporcionarle información personalizada,
          recordando sus preferencias de servicios y de mercadeo y ayudándolo a
          obtener la información adecuada. En caso de que requiera mayor
          información respecto al uso de cookies y tecnologías similares, Zigu
          pone a su disposición la Política de Uso de Cookies.
        </p>
        <h3 className={cls.heading}>
          <b>USO DE PLATAFORMAS DE TERCEROS COMO MEDIO DE OBTENCIÓN DE DATOS</b>
        </h3>
        <p className={cls.text}>
          Los Datos Personales pueden ser recabados mediante la integración
          manual de la información solicitada en la Plataforma al tener el
          Usuario registrada una cuenta de correo electrónico en dicha
          plataforma.
        </p>
        <h3 className={cls.heading}>
          <b>1. DATOS PERSONALES SOLICITADOS</b>
        </h3>
        <p className={cls.text}>
          Zigu, y/o las empresas controladoras de éste y/o empresas filiales y/o
          subsidiarias y/o partes relacionadas (los “Terceros Relacionados”) y/o
          aquellos terceros que por la naturaleza de su trabajo o funciones
          tengan la necesidad de tratar y/o utilizar sus datos personales, como
          proveedores o aliados comerciales (“Socios Comerciales”), solicita y
          obtiene datos los siguientes datos personales:
        </p>
        <ul className={cls.ordered}>
          <li className={cls.text}>
            <b>
              ​​Información de registro y uso: cuando se registra para utilizar
            </b>{" "}
            nuestros Servicios mediante la creación de una Cuenta, recopilaremos
            los Datos personales necesarios para ofrecer y proveer los Servicios
            que solicite. Según los Servicios que elija, podemos solicitar que
            nos proporcione su nombre, dirección postal, número de teléfono,
            dirección de correo electrónico e información de identificación para
            crear una Cuenta. Puede que solicitemos que nos proporcione Datos
            personales adicionales cuando utilice nuestros Servicios.
          </li>
          <li className={cls.text}>
            <b>
              Información de transacción y experiencia de uso: cuando utiliza
            </b>{" "}
            nuestros Servicios o accede a nuestros Sitios web, recopilamos
            información acerca de la transacción, además de otros datos
            asociados a la transacción como el importe enviado o solicitado, el
            importe pagado por artículos o servicios, la información del
            comercio (que incluye la información acerca de cualquier forma de
            pago utilizada para completar la transacción), Información del
            dispositivo, Datos de uso técnico e Información de geolocalización.
          </li>
          <li className={cls.text}>
            <b>
              Información del participante: cuando utiliza nuestros Servicios o
            </b>{" "}
            accede a nuestros Sitios web, recopilamos los Datos personales que
            nos proporciona acerca de los demás participantes asociados con sus
            transacciones.
          </li>
          <li className={cls.text}>
            <b>Solicitar pago: cuando solicita un pago a través de nuestros</b>{" "}
            Servicios, recopilamos Datos personales como el nombre, la dirección
            postal, el número de teléfono y la información financiera de la otra
            parte que está enviándole un pago a usted. El alcance de los Datos
            personales requeridos de un participante puede variar en función de
            los Servicios que utilice para solicitar el pago.
          </li>
          <li className={cls.text}>
            <b>
              Información que decide proporcionarnos para obtener Servicios
              adicionales o Servicios en línea específicos:
            </b>{" "}
            si utiliza o solicita la autorización para usar una funcionalidad
            opcional del Sitio o solicita algún Servicio especial u otra
            funcionalidad opcional, podemos recopilar información adicional de
            usted. Proporcionaremos un aviso independiente en el momento de la
            recopilación si el uso de esa información difiere de los usos
            explicados en esta Política de Privacidad.
          </li>
          <li className={cls.text}>
            <b>Información acerca de usted de otras fuentes: podemos obtener</b>{" "}
            información de terceros, como comercios, proveedores de datos y
            burós de crédito, cuando las leyes aplicables así lo permitan.
          </li>
          <li className={cls.text}>
            <b>
              Otra información que recopilamos con relación al uso que hace de
              nuestro Sitio o Servicios:
            </b>{" "}
            podemos recopilar información adicional de usted o acerca de usted
            cuando se comunique con nosotros o con nuestros equipos de atención
            al cliente, o cuando responda una encuesta enviada por nosotros.
          </li>
        </ul>
        <p className={cls.text}>
          En todos los casos, la recolección de Datos Personales por parte Zigu
          es realizada de buena fe y para los fines aquí expuestos; por tal
          motivo, se presume que los datos proporcionados por sus titulares son
          apegados a la verdad y completos, por lo que son responsabilidad del
          titular que los proporciona.
        </p>
        <p className={cls.text}>
          <b>
            Todos los Datos Personales son procesados por Zigu y puestos en un
            repositorio para su tratamiento y transferencia al Cliente, a fin de
            dar cumplimiento al objeto de los Servicios adquiridos por parte del
            Cliente.
          </b>{" "}
          AL SER LA PLATAFORMA UNA HERRAMIENTA DE PROCESAMIENTO DE DATOS PUESTA
          AL SERVICIO DEL CLIENTE, QUE PERMITE MONITOREAR Y CENTRALIZAR TODA LA
          INFORMACIÓN DE SUS USUARIOS FINALES, ZIGU NO TIENE INJERENCIA SOBRE LA
          TRANSFERENCIA Y FINALIDAD DEL USO POR PARTE DEL CLIENTE, POR LO QUE
          ESTAS ACTIVIDADES ESTÁN REGIDAS POR EL AVISO DE PRIVACIDAD QUE CADA
          CLIENTE DISPONGA A ESTE EFECTO.
        </p>
        <h3 className={cls.heading}>
          <b>2. FINALIDADES DEL TRATAMIENTO DE LOS DATOS PERSONALES</b>
        </h3>
        <p className={cls.text}>
          Los Datos Personales proporcionados a Zigu a través de la Plataforma
          serán utilizados según se ha mencionado anteriormente, con la
          finalidad de:
        </p>
        <ol className={cls.ordered}>
          <li>
            Para operar los Sitios y proveer los Servicios, que incluyen:
            <ul>
              <li>Realizar un pago;</li>
              <li>Autenticar el acceso a una cuenta;</li>
              <li>
                Establecer comunicación con usted, acerca de su Cuenta, los
                Sitios, los Servicios o ZIGU;
              </li>
              <li>
                Crear una conexión de cuentas entre su Cuenta y la cuenta o
                plataforma de un tercero;
              </li>
              <li>
                Realizar comprobaciones de solvencia y otras verificaciones de
                solvencia financiera, evaluar aplicaciones y comparar
                información con fines de precisión y verificación.
              </li>
            </ul>
          </li>
          <li>
            Para realizar necesidades comerciales, como la supervisión, el
            análisis y la mejora del rendimiento y la funcionalidad de los
            Servicios y los Sitios. Por ejemplo, analizamos el comportamiento
            del Usuario y realizamos una investigación acerca de la forma en la
            que usted utiliza nuestros Servicios.
          </li>
          <li>
            Para administrar el riesgo y proteger los Sitios, los Servicios y a
            usted de fraudes mediante la verificación de su identidad ,
            ayudándole a detectar y evitar fraudes y el mal uso de los Sitios o
            Servicios.
          </li>
          <li>
            Para fines de mercadotecnia, mediante la entrega de materiales
            promocionales acerca de los productos y servicios de ZIGU. También
            podemos procesar sus Datos personales para ofrecer ciertos Servicios
            o experiencias del Sitio que coincidan con nuestra interpretación de
            sus intereses.
          </li>
          <li>
            Para proveer Servicios personalizados por parte de ZIGU, en sitios
            web de terceros y otros servicios en línea. Nunca usaremos sus Datos
            personales ni cualquier otra información que haya sido recopilada de
            acuerdo con los términos de esta ni otra Política de Privacidad para
            proporcionar una visualización, característica u oferta dirigida a
            usted sobre sitios web de terceros. Podemos utilizar cookies y otras
            tecnologías de rastreo para proporcionar estos servicios en línea
            y/o trabajar con otros terceros como, empresas de seguridad,
            publicidad o de análisis, para proporcionar estos servicios.
          </li>
          <li>
            Para proporcionarle opciones específicas para la ubicación,
            funcionalidad u ofertas si elige compartir su Información de
            geolocalización a través de los Servicios. Utilizaremos esta
            información para mejorar la seguridad de los Sitios y Servicios y
            proporcionar Servicios basados en la ubicación tales como
            publicidad, resultados de búsqueda y otro contenido personalizado.
          </li>
          <li>
            Para cumplir con nuestras obligaciones y con los términos de
            nuestros Sitios y Servicios, entre ellos el cumplimiento de todas
            las leyes y normas aplicables.
          </li>
          <li>
            Para responder a sus solicitudes, por ejemplo, para comunicarse con
            usted en relación a una pregunta que envía a nuestro equipo de
            atención al cliente.
          </li>
        </ol>
        <p className={cls.text}>
          Una vez cumplidas las finalidades del tratamiento de sus Datos
          Personales, y cuando no exista disposición legal que establezca lo
          contrario, Zigu a su sola discreción y bajo la autorización del
          Titular de los datos, podrá hacer uso de los datos recabados
          únicamente con fines estadísticos de manera genérica y no
          personalizada, y se asocian con el crecimiento, mantenimiento y
          administración Zigu, respetando en todo momento la privacidad del
          titular de los Datos Personales.
        </p>
        <h3 className={cls.heading}>
          <b>3. TRANSFERENCIA</b>
        </h3>
        <p className={cls.text}>
          <b>TRANSFERENCIA DE LOS DATOS PERSONALES E INFORMACIÓN.</b> Los Datos
          Personales a que se refiere este Aviso podrán ser transferidos a: (i)
          terceros relacionados y/o aliados comerciales, con la finalidad de
          engrandecer la propuesta de valor Zigu, así como ofrecerle, con base
          en sus necesidades, otros productos y servicios; (ii) autoridades
          judiciales, mexicanas y extranjeras, con la finalidad de dar
          cumplimiento a la Ley, legislación, notificaciones, requerimientos u
          oficios de carácter judicial; (iii) a proveedores de servicios de
          internet sobre la cual esté montada la infraestructura tecnológica
          Zigu; y/o (iv) a proveedores de servicios de soporte técnico de la
          Plataforma. En caso de realizar alguna transferencia de sus Datos
          Personales, en los que se requiera su consentimiento expreso, se lo
          informaremos a efecto de recabar el mismo.
        </p>
        <p className={cls.text}>
          En todos los casos, Zigu comunicará el presente Aviso de Privacidad a
          estos terceros y se asegurará a través de la firma de convenios y/o la
          adopción de otros documentos vinculantes, que dichos terceros
          mantengan las medidas de seguridad administrativas, técnicas y físicas
          necesarias para resguardar sus Datos Personales, así como que dichos
          terceros únicamente utilicen sus Datos Personales para las finalidades
          para los cuales fueron recabados. Asimismo, tanto el Cliente como
          responsable de recabar los Datos Personales y Zigu que facilita a
          través de la Plataforma la recabación y procesamiento de los mismos,
          así como cualquier otra persona relacionada con Zigu que tenga acceso
          a la información contenida en este Aviso de Privacidad, quedarán
          obligados a resguardarla bajo las mismas normas de seguridad y
          confidencialidad, y a no revelarla ni hacer mal uso de la misma, o en
          caso contrario serán responsables de conformidad con las leyes
          aplicables.{" "}
        </p>
        <p className={cls.text}>
          No obstante lo anterior, Zigu no transferirá sus Datos Personales a
          terceros no relacionados con Zigu, salvo en los casos antes citados y
          los previstos en la Ley, sin su consentimiento previo.
        </p>
        <h3 className={cls.heading}>
          <b>
            4. MEDIOS Y PROCEDIMIENTOS PARA EL EJERCICIO DE LOS DERECHOS ARCO
          </b>
        </h3>
        <p className={cls.text}>
          Usted, como titular de los Datos Personales proporcionados a Zigu,
          podrá solicitar en cualquier momento, el ejercicio de sus derechos de
          acceso, rectificación, cancelación u oposición (los “Derechos ARCO”)
          al tratamiento de sus Datos Personales, consistentes en: (i) acceder a
          sus Datos Personales y a los detalles del tratamiento de los mismos;
          (ii) rectificar sus Datos Personales en caso de ser inexactos o
          incompletos; (iii) cancelar sus Datos Personales cuando considere que
          no se requieren para alguna de las finalidades señalados en este Aviso
          de Privacidad, estén siendo utilizados para finalidades no consentidas
          o haya finalizado su relación contractual o de servicio u otra con
          Zigu; y (iv) oponerse al tratamiento de sus Datos Personales para
          fines específicos.
        </p>
        <p className={cls.text}>
          Para tal fin, usted deberá seguir el proceso de presentar su petición
          por escrito a Zigu, o bien, enviar su petición al Correo Electrónico,
          según sea aplicable, la cual deberá contener, como mínimo, la
          siguiente información: (a) su nombre completo y domicilio, u otro
          medio idóneo para comunicarle la respuesta a su solicitud; (b) los
          documentos que acrediten su identidad o, en su caso, la de su
          representante legal; (c) la descripción clara y precisa de los Datos
          Personales respecto de los que se busca ejercer alguno de los derechos
          antes mencionados; y (d) cualquier otro elemento o información que
          facilite la localización de los Datos Personales, así como cualquier
          otro documento requerido por la regulación actual en el momento de
          presentar la solicitud. Usted también podrá solicitar al Correo
          Electrónico mayor información sobre el procedimiento para ejercer sus
          Derechos ARCO.
        </p>
        <p className={cls.text}>
          La respuesta a su solicitud le será dada a conocer por Zigu en los
          términos y plazos establecidos en la Ley. No obstante, usted podrá
          obtener más información acerca del estado que guarda su solicitud y
          del plazo de respuesta de la misma, contactando a Zigu o enviando su
          petición al Correo Electrónico, donde además podrán atender cualquier
          aclaración o duda que pudiera tener respecto al tratamiento de sus
          Datos Personales y el ejercicio de sus Derechos ARCO.
        </p>
        <h3 className={cls.heading}>
          <b>
            5. REVOCACIÓN DEL CONSENTIMIENTO; LIMITACIÓN DE USO Y DIVULGACIÓN DE
            LOS DATOS PERSONALES{" "}
          </b>
        </h3>
        <p className={cls.text}>
          Usted también podrá revocar, en cualquier momento, el consentimiento
          que haya otorgado a Zigu para el tratamiento de sus Datos Personales,
          así como solicitar que se limite el uso y divulgación de los mismos,
          siempre y cuando no lo impida una disposición legal. Para tal fin,
          usted deberá presentar su solicitud por escrito a Zigu, o bien, enviar
          su solicitud al Correo Electrónico, según sea aplicable. Dicha
          solicitud deberá cumplir con los mismos requisitos mencionados en la
          Sección 4. anterior.
        </p>
        <p className={cls.text}>
          La respuesta a su solicitud le será dada a conocer por Zigu en los
          términos y plazos establecidos en la Ley. No obstante, usted podrá
          obtener más información acerca del estado que guarda su solicitud y
          del plazo de respuesta de la misma, contactando a Zigu o enviando su
          petición al Correo Electrónico, donde además podrán atender cualquier
          aclaración o duda que pudiera tener respecto al tratamiento y estos
          derechos que le corresponden respecto a sus Datos Personales.
        </p>
        <p className={cls.text}>
          En caso de que sus Datos Personales hubiesen sido remitidos con
          anterioridad a la fecha de revocación del consentimiento, y sigan
          siendo tratados por encargados Zigu, éste hará del conocimiento de
          éstos últimos dicha revocación, para que procedan a efectuar lo
          conducente.
        </p>
        <h3 className={cls.heading}>
          <b>6. CAMBIOS AL AVISO DE PRIVACIDAD</b>
        </h3>
        <p className={cls.text}>
          Zigu se reserva el derecho de modificar y/o actualizar este Aviso de
          Privacidad, en alguna o todas sus partes, a su entera discreción, en
          cuyo caso lo comunicará aquí mismo a través de su Sitio y/o la
          Plataforma; y, según sea el caso particular de cada titular, a través
          de sus redes internas, o por medio de un aviso que se colocará en los
          medios habituales (físicos o electrónicos) de comunicación Zigu y en
          un lugar visible del Domicilio, o mediante un aviso por escrito
          dirigido a su correo electrónico, según sea legalmente requerido.
        </p>
        <h3 className={cls.heading}>
          <b>7. FORMA DIGITAL, ELECTRÓNICA O EN LÍNEA</b>
        </h3>
        <p className={cls.text}>
          La Partes acuerdan que la forma para perfeccionar el acuerdo de
          voluntades entre ellas podrá ser el de formato Digital, Electrónico o
          en Línea, en donde bastará manifestar su voluntad por medio de su
          aceptación, así como proporcionar los datos personales, en el propio
          Sitio Zigu sin requerir estampar la firma en documento alguno.
        </p>
        <br />
        <br />
        <p className={cls.text}>Fecha de primera emisión: [1 de Mayo, 2022]</p>
        <p className={cls.text}>
          Fecha de última modificación: [1 de Mayo, 2022]
        </p>
      </div>
    </div>
  );
};

export default AvisoDePrivacidad;
