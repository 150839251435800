import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Label, Ptxt } from "../Items";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: "50px 0px",
    flexWrap: "wrap",
  },
  cont: {
    display: "flex",
    height: "500px",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "350px",
    marginTop: "10px",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      margin: "0px 10px",
    },
  },
  img: {
    width: "250px",
    marginBottom: "15px",
    height: "200px",
  },
  paragraphs: {
    height: "130px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
}));

const Ceos = () => {
  const cls = useStyles();

  const ceos = [
    {
      img: "./imgs/home/img1Sec4.png",
      aos: "zoom-out",
      alt: "ceos",
      ptxt: "“Desde que empezamos a utilizar Zigu, se acabaron nuestros dolores de cabeza a la hora de cobrar y recibir el dinero. En verdad han aumentado la seguridad para nosotros y nuestros clientes.”",
      name: "GABRIEL",
      ceo: "CEO MERCADO CPAP",
    },
    {
      img: "./imgs/home/img2Sec4.png",
      aos: "zoom-out-up",
      alt: "ceos",
      ptxt: '"Lo que más valoramos en VMX es el servicio al cliente, y haber encontrado una plataforma de pagos con un equipo siempre listo y dispuesto a ayudarnos e innovar con nosotros ha sido fantástico, los considero parte de nosotros."',
      name: "Miguel ",
      ceo: "CEO Vapormex",
    },
    {
      img: "./imgs/home/img3Sec4.png",
      aos: "zoom-out-down",
      alt: "ceos",
      ptxt: "“Me preocupaba que la integración con mi sistema de eCommerce fuera a complicarse y luego iba a andar batallando. Pero no fue así. Su API es súper simple de instalar y en menos de lo esperado ya estaba cobrando.”",
      name: "Rafael",
      ceo: "CEO Master Holder",
    },
  ];

  return (
    <div className={cls.root}>
      {ceos.map((c, i) => (
        <div key={i} data-aos={c.aos} className={cls.cont}>
          <img className={cls.img} src={c.img} alt={c.alt} />
          <br></br>
          <div className={cls.paragraphs}>
            <Ptxt txt={c.ptxt} />
            <br></br>
          </div>
          <div className={cls.lbls}>
            <Label txt={c.name} color="#58585a" />
            <br></br>
            <Label txt={c.ceo} color="#FEC011" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Ceos;
