import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { SnackbarProvider } from "notistack";
import Header from "./components/Header";
import Home from "./components/Home";
import Servicios from "./components/Servicios";
import Tarifas from "./components/Tarifas";
import Faqs from "./components/Faqs";
import Footer from "./components/Footer";
import ScrollTop from "./components/ScrollTop";
import ViewRegistroSesion from "./components/ViewRegistroSesion";
import ViewRegistroSesion2 from "./components/ViewRegistroSesion2";
import InicioSesion from "./components/InicioSesion";
import Registro from "./components/Registro";
import NotFound from "./components/NotFound";
import client from "./utils/ApolloClient";
import "./style.css";
import AvisoDePrivacidad from "./components/AvisoDePrivacidad";
import TerminosYCondiciones from "./components/TerminosYCondiciones";

// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles(theme => ({
//     topPadding: {
//         [theme.breakpoints.down('sm')]: {
//             paddingTop: '100px',
//         }
//     }
// }));

const App = () => {
  // const cls = useStyles();

  return (
    <div>
      <ApolloProvider client={client}>
        <SnackbarProvider maxSnack={3}>
          <Router>
            <Header />
            <ScrollTop>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
                <Route exact path="/home" component={Home} />
                <Route exact path="/servicios" component={Servicios} />
                <Route exact path="/tarifas" component={Tarifas} />
                <Route exact path="/faqs" component={Faqs} />
                <Route
                  exact
                  path="/aviso-de-privacidad"
                  component={AvisoDePrivacidad}
                />
                <Route
                  exact
                  path="/terminos-y-condiciones"
                  component={TerminosYCondiciones}
                />
                <Route exact path="/sesion">
                  <ViewRegistroSesion>
                    <InicioSesion />
                  </ViewRegistroSesion>
                </Route>
                <Route exact path="/registro">
                  <ViewRegistroSesion2>
                    <Registro />
                  </ViewRegistroSesion2>
                </Route>
                <Route>
                  <ViewRegistroSesion>
                    <NotFound />
                  </ViewRegistroSesion>
                </Route>
              </Switch>
            </ScrollTop>
            <Footer />
          </Router>
        </SnackbarProvider>
      </ApolloProvider>
    </div>
  );
};

export default App;
