import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Boton } from "./Items";
import clsx from "clsx";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import MegaMenu2 from "./MegaMenu2";
import { Button } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PersonIcon from "@material-ui/icons/Person";
import { useWindowResize } from "./hooks/useWindowResize";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    background: "#FEC011",
    marginBottom: "-3px",
    position: "sticky",
    top: "0px",
    zIndex: "10",
    "& > div": {
      maxWidth: "1400px",
      width: "100%",
      transition: "0.5s",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "50px 120px", //change on scroll
      [theme.breakpoints.down("md")]: {
        padding: "20px 10px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        zIndex: "5",
        top: "0px",
        height: "40px",
        backgroundColor: "#FEC011",
        transition: "box-shadow .5s",
      },
    },
  },
  sticky: {
    position: "fixed",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  cont: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  nav: {
    display: "flex",
    width: "500px",
    height: "50px",
    marginRight: "50px",
    transition: 0.4,
    [theme.breakpoints.down("md")]: {
      width: "500px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  list2: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    listStyle: "none",
    "& > li > a": {
      cursor: "pointer",
      fontWeight: "bold",
      textDecoration: "none",
    },
    "& > li:hover > a": {
      color: "#fff",
    },
  },
  hoverLink: {
    color: "#fff",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "320px",
    "& > button": {
      borderRadius: "15px 0px",
      height: "35px",
      width: "150px",
      cursor: "pointer",
      fontWeight: "bold",
    },
  },
  sesion: {
    backgroundColor: "transparent",
    border: "solid 2px #7174D7",
    color: "#7174D7",
  },
  registrate: {
    color: "#fff",
    border: "none",
    backgroundColor: "#7174D7",
  },
  btnNavbar: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
    marginRight: "15px",
    zIndex: "5",
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "30px",
    height: "30px",
    backgroundColor: "#7174D7",
    borderRadius: "5px",
    cursor: "pointer",
    padding: "2px",
    "& > span": {
      backgroundColor: "#FEC011",
      borderRadius: "10px",
      width: "75%",
      height: "10%",
      margin: "2px 0px",
    },
  },
  btnNavbar2: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
    justifyContent: "center", // vertical
    alignItems: "center", // horizontal
    flexDirection: "column",
    width: "30px",
    height: "30px",
    backgroundColor: "#7174D7",
    borderRadius: "5px",
    cursor: "pointer",
    padding: "2px",
    "& > span": {
      backgroundColor: "#FEC011",
      borderRadius: "10px",
      width: "75%",
      height: "10%",
      margin: "2px 0px",
    },
  },
  btnListExit: {
    backgroundColor: "red",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#be0000",
    },
  },
  // list: {
  //     width: 'auto',
  // },

  logo: {
    width: "145px",
    marginLeft: "10px",
    transition: ".5s",
    [theme.breakpoints.down("md")]: {
      width: "100px", //change on scroll
      marginLeft: "110px",
      marginTop: "10px",
      marginRight: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100px", //change on scroll
      marginLeft: "20px",
      marginTop: "10px",
    },
    "&:hover": {
      transform: "scale(0.9)",
    },
  },
  logoMobile: {
    //dropdown menu logo
    width: "100px",
    marginLeft: "15px",
    marginTop: "10px",
  },
  linkMobile: {
    textDecoration: "none",
    "& *": {
      fontFamily: "Accura",
    },
  },
  wbg: {
    backgroundColor: "#fff",
    padding: "35px 25px",
    height: "82vh",
  },
  primary: {
    color: "#7174d7",
    fontSize: "35px",
    fontFamily: "Accura Semibold",
    textDecoration: "none",
  },
  secondary: {
    paddingLeft: "15%",
    color: "#000",
    fontFamily: "Accura",
    fontSize: "18px",
  },
  mobile: {
    backgroundColor: "#fec011",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between", //horizontal
    alignItems: "center", //vertical
    height: "10vh",
    paddingRight: "24px",
  },
  name: {
    color: "#fff",
    fontFamily: "Accura",
    fontSize: "40px",
    marginTop: "-5px",
  },
  bottom: {
    width: "100%",
  },

  close: {
    height: "30px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end", //horizontal
    alignItems: "center",
    padding: "20px 25px 5px 0px",
    color: "#7174d7",
  },
  navButtons: {
    width: "100%",
    padding: "16px",
    fontSize: "16px",
    borderRadius: "4px",
    borderWidth: "2px",
  },
  comenzar: {
    marginLeft: "50px",
    textDecoration: "none",
  },
}));

const handleColor = (e) => {
  const links = document.querySelectorAll(".link");
  const arrLinks = [...links];
  arrLinks.forEach((link, path) => {
    path = link.href.split("/");
    path = "/" + path[path.length - 1];
    path === e.pathname
      ? link.classList.add("active-link")
      : link.classList.remove("active-link");
  });
};
// const SimpleMenu = () => {
//         const [anchorEl, setAnchorEl] = React.useState(null);
//         const handleClick = (event) => {
//             setAnchorEl(event.currentTarget);
//         };

//         const handleClose = () => {
//             setAnchorEl(null);
//         };
//     }

// const links = [
//     {icon: <HomeIcon />, txt: 'Home', to: 'home'},
//     {icon: <StorageIcon />, txt: 'Servicios', to: 'servicios'},
//     {icon: <GradeIcon />, txt: 'Tarifas', to: 'tarifas'},
//     {icon: <QuestionAnswerIcon />, txt: 'FAQS', to: 'faqs'},
//     {icon: <MenuBookIcon />, txt: 'Documentación', to: '', anchor: ''},
// ]

const Header = () => {
  const cls = useStyles();
  const location = useLocation();
  const [windowWidth] = useWindowResize();
  const [sticky, setSticky] = useState(null);

  useEffect(() => {
    const handleScrollResize = () => {
      if (window.scrollY >= 90 && windowWidth > 960) {
        document.getElementById("hd").style.padding = "15px 120px";
        document.getElementById("logo2").style.width = "50%";
      } else if (windowWidth > 960) {
        document.getElementById("hd").style.padding = "50px 120px";
        document.getElementById("logo2").style.width = "145px";
      } else if (window.scrollY > 90 && windowWidth < 960) {
        setSticky(cls.sticky);
      } else {
        setSticky(null);
      }
    };

    window.addEventListener("scroll", handleScrollResize);

    return () => window.removeEventListener("scroll", handleScrollResize);
  }, [setSticky, cls, windowWidth]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div>
      <div //mobile dropdown menu
        className={clsx(cls.list, {
          [cls.fullList]: anchor === "top" || anchor === "bottom",
        })}
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div>
          <List disablePadding={true}>
            <ListItem className={cls.mobile}>
              <Link to="home">
                <img
                  src="./imgs/logo.png"
                  alt="logo"
                  className={cls.logoMobile}
                />
              </Link>
              <Link to="registro" underline="none">
                <Button
                  variant="contained"
                  color="primary"
                  className={cls.comenzar}
                >
                  Comenzar ahora
                </Button>
              </Link>
              <CloseRoundedIcon />
            </ListItem>
            <div className={cls.wbg}>
              <Link to="home" style={{ textDecoration: "none" }}>
                <ListItem className={cls.primary}> Home </ListItem>
              </Link>
              <Link to="servicios" style={{ textDecoration: "none" }}>
                <ListItem className={cls.primary}> Servicios </ListItem>
              </Link>

              <Link to="servicios#boton" style={{ textDecoration: "none" }}>
                <ListItem className={cls.secondary}>• Botón de pago</ListItem>
              </Link>

              <Link to="servicios#link" style={{ textDecoration: "none" }}>
                <ListItem className={cls.secondary}>• Link de Pago </ListItem>
              </Link>

              <Link to="servicios#api" style={{ textDecoration: "none" }}>
                <ListItem className={cls.secondary}>• API </ListItem>
              </Link>

              <Link to="tarifas" style={{ textDecoration: "none" }}>
                <ListItem className={cls.primary}> Tarifas </ListItem>
              </Link>
              <Link to="faqs" style={{ textDecoration: "none" }}>
                <ListItem className={cls.primary}> FAQs </ListItem>
              </Link>
              <Link to="faqs" style={{ textDecoration: "none" }}>
                <ListItem className={cls.secondary}>
                  • Preguntas Frecuentes
                </ListItem>
              </Link>
              <a
                href="https://docs.zigu.mx"
                className={cls.secondary}
                style={{ textDecoration: "none" }}
              >
                • Documentación
              </a>
              <br />
              <br />
              <br />
              <br />
              <div className={cls.bottom}>
                <div>
                  <Link
                    className={`${cls.linkMobile} link`}
                    onClick={(e) => handleColor(e)}
                    to="registro"
                    underline="none"
                  >
                    <ListItem button style={{ color: "#fff" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={cls.navButtons}
                      >
                        Comenzar ahora
                      </Button>
                    </ListItem>
                  </Link>
                  <Link to="sesion">
                    <ListItem>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        className={cls.navButtons}
                      >
                        Iniciar Sesión
                      </Button>
                    </ListItem>
                  </Link>
                </div>
                <div>
                  {/* <img
                                    className='bottomImg'
                                    src='./imgs/logo.png' alt='letter' /> */}
                </div>
              </div>
            </div>
          </List>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    handleColor(location);
  }, [location]);

  return (
    //desktop menu
    <div className={cls.root}>
      <div id="hd">
        <Link to="home">
          <img
            src="./imgs/logo.png"
            alt="logo"
            id="logo2"
            className={cls.logo}
          />
        </Link>
        <div className={cls.btnNavbar} onClick={toggleDrawer("top", true)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <Drawer
          anchor={"top"}
          open={state["top"]}
          onClose={toggleDrawer("top", false)}
        >
          {list("top")}
        </Drawer>
        <div className={cls.cont}>
          <nav className={cls.nav}>
            <ul className={cls.list2}>
              <li>
                <Link
                  className="link"
                  onClick={(e) => handleColor(e)}
                  to="home"
                >
                  Home
                </Link>
              </li>
              <li>
                <MegaMenu2 />
              </li>
              <li>
                <Link
                  className="link"
                  onClick={(e) => handleColor(e)}
                  to="tarifas"
                >
                  Tarifas
                </Link>
              </li>
              <li>
                <Link
                  className="link"
                  onClick={(e) => handleColor(e)}
                  to="faqs"
                >
                  FAQs
                </Link>
              </li>
              <li>
                <a
                  className="link"
                  onClick={(e) => handleColor(e)}
                  href="https://docs.zigu.mx"
                >
                  Documentación
                </a>
              </li>
            </ul>
          </nav>
          <div className={cls.buttons}>
            <Boton
              val="Inicia Sesión"
              txt="Inicia Sesion"
              location="/sesion"
              type="btnSesion"
            />
            <Boton
              val="Regístrate"
              txt="Regístrate"
              location="/registro"
              type="btnMorado"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
