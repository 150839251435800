import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { H1 } from "./Items";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "50px",
    [theme.breakpoints.down("sm")]: {
      padding: "30px",
    },
  },
  text: {
    textAlign: "left",
    fontFamily: "Open Sans",
    marginTop: "10px",
  },
  heading: {
    textAlign: "left",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    marginTop: "30px",
    color: "black",
  },
  ordered: {
    listStyleType: "lower-alpha",
    paddingLeft: "50px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "30px",
    },
    "& > li": {
      fontFamily: "Open Sans",
      marginTop: "10px",
    },
    "& > li > ul": {
      paddingLeft: "50px",
      fontFamily: "Open Sans",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "30px",
      },
    },
  },
}));

const AvisoDePrivacidad = () => {
  const cls = useStyles();
  return (
    <div className={cls.root}>
      <div>
        <h3 className={cls.heading}>
          <b>
            TÉRMINOS Y CONDICIONES DE USO DEL SOFTWARE ZIGU A TRAVÉS DE
            PLATAFORMA DIGITAL O APLICACIÓN WEB.
          </b>
        </h3>
        <p className={cls.text}>
          Los Términos y Condiciones de uso que a continuación se presentan (los
          “Términos”) constituyen un acuerdo entre Procesadora de Pagos
          Electrónicos, S.A.P.I. de C.V., sus filiales y/o subsidiarias, y/o sus
          partes relacionadas (“Zigu”), con domicilio en [*] (el “Domicilio”),
          dirección electrónica www.zigu.mx (el “Sitio”), quien es legítimo
          propietario o autorizado para comercializar y usar el Software “Zigu”
          para su acceso vía web (la “Plataforma”) y con correo electrónico de
          contacto atencion@zigu.mx (el “Correo Electrónico”); y la persona
          física y/o moral que acceda a ella, en relación a todos los servicios
          prestados por Zigu (los “Servicios”), independientemente del contrato
          que tenga celebrado con Zigu. La utilización del Sitio por parte de
          cualquier persona, le atribuye la calidad de cliente (el “Cliente” o
          “Comercio” o “Usted” o “Vendedor” o cualquier referencia que se haga
          al Cliente), y ello implica su adhesión plena e incondicional a estos
          Términos.
        </p>
        <p className={cls.text}>
          Zigu se reserva el derecho de, en cualquier momento, modificar y/o
          renovar unilateralmente y sin previo aviso los términos y condiciones
          del Sitio, con la obligación de publicar un mensaje en el Sitio que
          contenga un aviso al Cliente de que han sido realizadas ciertas
          modificaciones a los Términos. La versión actualizada entrará en vigor
          al momento de su publicación. Si la versión modificada incluye un
          Cambio Sustancial, según se define más adelante, se lo notificaremos
          con al menos 30 días de anticipación a dicho Cambio Sustancial
          mediante la publicación de una notificación en el Sitio. Tu acceso o
          uso continuado del Sitio o los Servicios después de dicha publicación,
          constituye tu consentimiento a vincularte por los Términos y
          Condiciones y sus modificaciones, siendo la última versión publicada
          la que regulará inmediatamente las relaciones comerciales que se
          generen al momento de realizarse la transacción.
        </p>
        <p className={cls.text}>
          En cualquier caso, la aceptación de estos Términos será un paso previo
          e indispensable a la adquisición de los Servicios de Zigu. Los
          términos y condiciones específicos de la prestación de servicios de
          agregador de pagos se regirán por los acuerdos del contrato que para
          tal efecto celebre Usted con Zigu.
        </p>

        <h3 className={cls.heading}>
          <b>1.- DEFINICIONES.</b>
        </h3>
        <p className={cls.text}>
          <b>“Actividades Restringidas”</b> se refiere a todas aquellas
          actividades descritas en el numeral 5 de los presentes Términos.
        </p>
        <p className={cls.text}>
          <b>“Afiliada”</b> se refiere a una empresa que se encuentra
          relacionada con Zigu a través de una titularidad o control común.
        </p>
        <p className={cls.text}>
          <b>“Artículo No Recibido”</b> se refiere a la reclamación de un pago
          que un Usuario hace al Comercio argumentando que no recibió el
          artículo comprado.
        </p>
        <p className={cls.text}>
          <b>“Autorizar” o “Autorización”</b> se refiere a la autorización
          expresa que un comprador hace a un Comercio para cobrar el pago.
        </p>
        <p className={cls.text}>
          <b>“API”</b> Interfaces de programación de aplicaciones por sus siglas
          en inglés (Application Programming Interfaces).
        </p>
        <p className={cls.text}>
          <b>“Banco”</b>, institución de tipo financiero que realiza servicios y
          operaciones de banca y crédito según lo establece la Ley de
          Instituciones de Crédito en su artículo 2º y demás relativos.
        </p>
        <p className={cls.text}>
          <b>“Cambio Sustancial”</b> se refiere a un cambio en los términos de
          este contrato que modifique, de forma trascendente, derechos o
          responsabilidades de las Partes.
        </p>
        <p className={cls.text}>
          <b>"Cancelación"</b> se refiere a cuando Zigu cancela o revierte un
          pago que el Comercio recibió derivado de alguno de los siguientes
          supuestos: contrato que modifique, de forma trascendente, derechos o
          responsabilidades de las Partes.
        </p>
        <ul className={cls.ordered}>
          <li className={cls.text}>
            Ha sido invalidado por el banco del remitente (el Usuario),
          </li>
          <li className={cls.text}>
            Zigu, una filial o cualquier subsidiaria directa o indirecta de Zigu
            le envió un pago indebido a usted por error, es decir, sin que obre
            alguna transacción o concepto que justifique dicho pago,
          </li>
          <li className={cls.text}>
            El Usuario no tenía autorización para realizar el pago, lo cual se
            hace del conocimiento de Zigu por parte del Banco (por ejemplo: el
            remitente utilizó una tarjeta de crédito robada),{" "}
          </li>
          <li className={cls.text}>
            El Comercio recibió el pago por actividades que infringían este
            contrato, la Política de Uso Aceptable de Zigu o cualquier otra
            Política de Zigu, ley o norma aplicable, o
          </li>
          <li className={cls.text}>
            Zigu resuelve una Reclamación en contra del Comercio, de conformidad
            con el proceso establecido en los presentes Términos..
          </li>
        </ul>
        <p className={cls.text}>
          <b>“Centro de Resoluciones en Línea”</b> contacto que realiza el
          Usuario cuando tiene alguna reclamación sobre un cargo realizado.
        </p>
        <p className={cls.text}>
          <b>“Comunicaciones”</b> se refiere a toda la información de
          transacciones o de la Cuenta del Cliente que Zigu le proporcione, en
          formatos elaborados por Zigu, y con el contenido que Zigu considere
          pertinente, incluyendo las Políticas aceptadas por el Comercio, con
          sus actualizaciones; recibos o confirmaciones de transacciones;
          estados de cuenta, entre otros.
        </p>
        <p className={cls.text}>
          <b>“Contenido Digital”</b> se refiere a los datos o información en
          forma digital (archivos electrónicos), e información distinta a la
          física, que se generan y se entregan y utilizan en formato
          electrónico, tales como video, artículos, bases de datos, planes de
          negocio, entre otros.
        </p>
        <p className={cls.text}>
          <b>“Contracargo”</b> se refiere a la reducción del dinero por una
          Reclamación que ya ha sido resuelta a favor del Usuario de conformidad
          con el procedimiento establecido en estos Términos.{" "}
        </p>
        <p className={cls.text}>
          <b>“Controversia”</b> se refiere a la impugnación que el Usuario
          presenta directamente a Zigu, según el procedimiento establecido, en
          el Centro de resoluciones en Línea conforme al numeral ** de estos
          Términos.
        </p>
        <p className={cls.text}>
          <b>“Cuenta Empresas”</b> se refiere a una Cuenta que se utiliza
          principalmente para fines comerciales, distinto a las cuentas
          personales, familiares y/o domésticos.
        </p>
        <p className={cls.text}>
          <b>“Cuenta Verificada”</b> Se refiere a la cuenta que el Comercio
          mantenga activa en un Banco, en la cual se realizaran las
          transacciones derivadas de este contrato.
        </p>
        <p className={cls.text}>
          <b>“Cuenta” o “Cuenta Zigu”</b> se refiere a una Cuenta de afiliación
          Personal o Empresas del Comercio con Zigu.
        </p>
        <p className={cls.text}>
          <b>“CVV o CVC”</b> es un grupo de 3 o 4 números situado en el reverso
          de la tarjeta de crédito, el cual se utiliza como método de seguridad
          en transacciones en las que la tarjeta no está físicamente presente,
          como en compras por teléfono o Internet.
        </p>
        <p className={cls.text}>
          <b>“Días Hábiles”</b> se refiere como los días de lunes a viernes,
          excluyendo aquellos días festivos oficiales reconocidos en México.
        </p>
        <p className={cls.text}>
          <b>“Días Naturales”</b> se entiende como los días calendario.
        </p>
        <p className={cls.text}>
          <b>“Error”</b> se refiere a un error de procesamiento en favor de Zigu
          o sus proveedores, donde se hace un cobro o un abono en su Cuenta por
          equivocación.
        </p>
        <p className={cls.text}>
          <b>“Forma de Pago”</b> se refiere a las opciones o medios de pago que
          se utiliza para fondear una transacción. Las siguientes formas de pago
          se pueden utilizar para fondear una operación: tarjeta de crédito y
          tarjeta de débito.
        </p>
        <p className={cls.text}>
          <b>“Garantía”</b> Se refiere a las garantía ante una reclamación de
          fraude que Zigu ofrece al Cliente y el cual podrá ser contratada por
          el Cliente mediante el contrato que Zigu y el Cliente celebren para
          tal efecto.
        </p>
        <p className={cls.text}>
          <b>“Información”</b> se refiere a toda la información de la Cuenta que
          es proporcionada a Zigu, tal como, de forma enunciativa más no
          limitativa, información personal, información financiera u otra
          información relacionada con usted o su negocio.
        </p>
        <p className={cls.text}>
          <b>“Perfil de Cuenta”</b> se refiere a aquella parte del Sitio, en la
          cual el Comercio puede ver y administrar su perfil, incluyendo su
          información personal, detalles de formas de pago, autorizaciones de
          pagos con aprobación previa, sus herramientas de venta y la
          configuración de su Cuenta, incluyendo sus preferencias de
          notificaciones y permisos de acceso de API.
        </p>
        <p className={cls.text}>
          <b>“Política” o “Políticas”</b> se refiere a cualquier política,
          manual, normativa u otro acuerdo a las cuales el Comercio se debe de
          sujetar en todo momento al hacer uso de los Servicios de Zigu.
        </p>
        <p className={cls.text}>
          <b>“Página de Detalles de la Transacción”</b> se refiere a la página
          del Sitio titulada pago.zigu.mx.
        </p>
        <p className={cls.text}>
          <b>“Detalles de la Transacción”</b>, se refiere a la información
          acerca de la transacción efectuada con el Comercio y el Usuario. A
          dicha información se puede obtener acceso a través de su Cuenta, en el
          Sitio.
        </p>
        <p className={cls.text}>
          <b>“Reclamación”</b> Se refiere a la solicitud que presenta el Usuario
          directamente a la compañía de su tarjeta de crédito y/o débito o al
          Banco/ empresa emisora de la tarjeta para invalidar un pago.
        </p>
        <p className={cls.text}>
          <b>“Reembolso”</b> Se refiere al procedimiento mediante el cual Zigu
          regresa su dinero a un Usuario derivado de una devolución o solicitud
          de reembolso del Usuario al Comercio.
        </p>
        <p className={cls.text}>
          <b>“Reserva”</b> se refiere a un porcentaje de los fondos que el
          Comercio recibe en su Cuenta y que nosotros retenemos, para cubrir
          alguna Cancelación, Contracargos, Reclamaciones o cualquier otra
          responsabilidad o contingencia relacionada con su Cuenta y/o el uso
          que usted haga de los Servicios de Zigu en caso de no tener
          suficientes fondos en su Cuenta.
        </p>
        <p className={cls.text}>
          <b>“Responsable Del Tratamiento De Datos”</b> (o simplemente el
          "responsable del tratamiento") y "encargado" (o simplemente el
          "encargado del tratamiento") y el "interesado" es La persona física o
          jurídica que sola o conjuntamente con otras trate datos personales por
          cuenta del responsable, según lo dispuesto por la Ley Federal De
          Protección De Datos Personales En Posesión De Los Particulares.
        </p>
        <p className={cls.text}>
          <b>“Saldo”</b> es la cantidad a favor o en contra que resulta de
          realizar las operaciones correspondientes a una cuenta.
        </p>
        <p className={cls.text}>
          <b>“Servicio de Atención al Cliente”</b> es el soporte técnico de Zigu
          al que se puede acceder en línea, en cualquier momento, a través del
          Centro de Ayuda de Zigu o llamando al 81-1356-12 desde las 8:00 a.m.
          hasta las 7:00 p.m., hora de la Ciudad de México.
        </p>
        <p className={cls.text}>
          <b>“Transacción No Autorizada”</b> se refiere a lo definido en el
          numeral [*] de estos Términos.
        </p>
        <p className={cls.text}>
          <b>“Usuario”</b> se entiende como la persona o entidad que tiene
          relación con el Comercio en el uso de los Servicios Zigu.
        </p>
        <br />
        <br />
        <h3 className={cls.heading}>
          <b>1. OBJETO</b>
        </h3>
        <p className={cls.text}>
          ​​Zigu es una empresa dedicada al desarrollo de tecnología para las
          soluciones de pago en línea, permitiendo a través de la Plataforma la
          recepción de pagos de cualquier persona o entidad a través de Internet
          que disponga de una tarjeta de crédito y/o débito de las marcas
          participantes. Así, Zigu crea, hospeda, mantiene y proporciona los
          Servicios y actividades conducentes directa o indirectamente a este
          fin. Zigu agrega valor al Comercio brindando herramientas sencillas y
          efectivas, cumpliendo con todos los procedimientos necesarios para que
          el Comercio brinde el servicio de pagos de tarjeta de crédito y/o
          débito a sus Clientes.
        </p>
        <p className={cls.text}>
          Los Servicios se prestan en la modalidad de Software as a Service (el
          “SaaS”), que implica que el Comercio recibe los servicios en la nube
          (como se define más adelante), a través de la contratación mediante el
          Sitio y sujeto al registro y alta de la Cuenta. Los Servicios
          proveídos por Zigu, adicional a aquellos que se establezcan en el
          contrato que Usted celebre con Zigu, contienen los siguientes
          componentes:
        </p>
        <ol className={cls.ordered}>
          <li>
            Procesar las transacciones – pagos, abonos, cargos diferidos y/o
            contracargos – a través de Internet que provengan de cualquier
            persona o entidad que disponga de una tarjeta de crédito y/o débito
            de las marcas participantes.
          </li>
          <li>
            Abonar las ventas y/o pagos a la Cuenta Verificada del Comercio en
            virtud de las transacciones e importes que sean realizados por los
            Usuarios con el Comercio a través de la Plataforma.
          </li>
          <li>
            Proveer y resguardar la seguridad en las transacciones mencionadas
          </li>
          <li>
            Intermediación ante el Banco: Zigu actúa como un intermediario entre
            el Banco y el Comercio.
          </li>
        </ol>
        <p className={cls.text}>
          Usted acepta cumplir con todos y cada uno de los procedimientos que
          las leyes aplicables señalen, respecto a la adquisición de servicios
          en línea o digitales; y, por su parte, Zigu se compromete a respetar y
          hacer cumplir los derechos del Comercio y dar un correcto uso a los
          datos que se recaben con dicho propósito, conforme al Aviso de
          Privacidad que se encuentra en el Sitio.
        </p>
        <h3 className={cls.heading}>
          <b>2. USO CONTRACTUAL DE LA PLATAFORMA</b>
        </h3>
        <p className={cls.text}>
          En virtud de los presentes Términos, Zigu autoriza y otorga al Usuario
          el uso y goce de 1 (una) licencia de uso de la Plataforma para uso
          personal, misma que implica el registro y acceso de la misma. Dicho
          otorgamiento se realiza bajo la modalidad “as is”, temporal, de manera
          no exclusiva, no comercializable y no sublicenciable, para la
          adquisición de los Servicios.
        </p>
        <p className={cls.text}>Contratación Electrónica.</p>
        <p className={cls.text}>
          <b>1. Elegibilidad y requisitos para abrir una Cuenta en Zigu.</b>
        </p>
        <p className={cls.text}>
          Para abrir y mantener una Cuenta, debe proporcionarnos información
          fidedigna y actualizada, tal como:
        </p>
        <ol className={cls.ordered}>
          <li>
            Datos de identificación como Nombre o Razón Social, Nombre
            Comercial, Datos De Constitución, Representante Legal, Datos de la
            escritura donde constan las facultades del Representante, Registro
            Federal De Contribuyentes, Domicilio, Cuenta Bancaria, Sitio Web,
            Correo Electrónico, Teléfono y Giro Comercial, entre otros datos que
            podrán ser requeridos de conformidad con la política de Conocimiento
            al Cliente de Zigu.
          </li>
          <li>
            Información de contacto. Correo electrónico. Una vez que el Comercio
            ha pasado satisfactoriamente y obtenido la validación en el proceso
            interno de validación por Zigu, es su responsabilidad mantener
            actualizado su correo electrónico, de manera que Zigu pueda
            comunicarse con el Comercio de manera electrónica. El Comercio
            reconoce y acepta que si Zigu le envía una Comunicación electrónica
            y si no la recibe debido a que su dirección de correo electrónico
            registrada es incorrecta, está desactualizada, bloqueada por su
            proveedor de servicio o no es apta por cualquier razón o forma para
            recibir comunicaciones electrónicas, se considerará que Zigu le ha
            entregado a usted dicha comunicación y se considera válida sin
            responsabilidad o requisito adicional a seguir por parte de Zigu
            para la entrega de la Comunicación.
          </li>
          <li>
            Puede actualizar su correo electrónico o domicilio en cualquier
            momento si inicia sesión en el sitio web de Zigu. Dicho cambio en su
            información será adoptado para efecto de comunicaciones dentro de
            las 24 (veinticuatro) horas siguientes a la realización de la
            modificación.
          </li>
          <li>
            Si su correo ya no es válido y genera la devolución de las
            Comunicaciones electrónicas que le envía Zigu, Zigu considerará que
            su Cuenta está inactiva y el Comercio no podrá hacer ningún
            movimiento con su Cuenta hasta que proporcione a Zigu una dirección
            de correo electrónico principal válida y en funcionamiento. En dicho
            caso, Zigu podrá continuar, a su discreción, con las transacciones
            regulares sin enterar los fondos al Comercio hasta que la situación
            citada fuere regularizada.
          </li>
        </ol>
        <p className={cls.text}>
          <b>2. Verificación de identidad.</b>
        </p>
        <ol className={cls.ordered}>
          <li>
            El Comercio autoriza a Zigu, directamente o a través de terceros, a
            hacer todas las consultas que consideremos necesarias para validar
            su identidad. <br /> Dentro de estas consultas se incluye:
            solicitarle más información o documentación, tal como, requerirle
            que proporcione su clave de Registro Federal de Contribuyentes (RFC)
            y/o Clave Única de Registro de Población (CURP), requerirle que siga
            algunos pasos para confirmar la titularidad de su correo electrónico
            o la titularidad de instrumentos financieros, solicitarle un reporte
            crediticio o bien, comparar su información con bases de datos de
            terceros o a través de otras fuentes que Zigu estime necesarias con
            la finalidad de corroborar la identidad del Comercio.
          </li>
          <li>
            Visitas de Inspección. Zigu podrá realizar, durante la vigencia del
            contrato, por sí mismo o de un tercero contratado para dichos
            efectos, inspecciones al Comercio, sus sucursales, bodegas,
            oficinas, con la finalidad de validar la información que le ha sido
            proporcionada.
          </li>
          <li>
            Actualizaciones de la información. El Comercio puede realizar
            modificaciones en la información bancaria proporcionada a Zigu, a
            través del portal, en el cual a los 48 horas hábiles, es decir una
            vez que el Comercio ha pasado satisfactoriamente y obtenido la
            validación del proceso Zigu, y allegado la información comprobatoria
            requerida, tal como carátula de estado de cuenta, recibo con
            domicilio del solicitante, entre otros, se dará respuesta sobre la
            actualización de la cuenta bancaria estableciéndose como Cuenta
            Verificada..
          </li>
          <li>
            El Comercio se obliga a proporcionar la información correspondiente
            sobre los cambios que repercutan corporativamente en su estructura,
            tales como, cambio en su estructura accionaria, en el Órgano de
            Administración, Apoderados, domicilio social, razón social, sea
            escindida, se modifique el capital social en su parte fija, entre
            otros.
          </li>
        </ol>
        <p className={cls.text}>
          Usted reconoce que el uso y acceso a la Plataforma incluye la
          capacidad de celebrar contratos para adquirir los Servicios y/o a
          realizar transacciones electrónicamente. Por lo anterior, el Cliente
          reconoce que los envíos electrónicos constituyen su aceptación e
          intención de obligarse y pagar, en tiempo y forma, por tales servicios
          y transacciones. Dicha obligación se considerará aplicable a todos los
          registros relacionados a todas las transacciones que se realicen a
          través de la Plataforma y los Servicios, incluyendo los avisos de
          cancelación, políticas de uso, contratos y aplicaciones.
        </p>
        <p className={cls.text}>
          Solo quien está expresamente autorizado y reconocido por Zigu podrá
          aceptar transacciones. En el caso de personas físicas, debe ser el
          Propietario Beneficiario y Titular de la Cuenta; en el caso de una
          persona moral, los individuos autorizados para representarla (gerentes
          y/o apoderados legales). Para entidades que cotizan en bolsa y
          entidades legales sin fines de lucro, los accionistas y/o socios
          controladores, en caso de haberlos, o bien, quien estuviere legalmente
          facultado para ello.
        </p>
        <p className={cls.text}>
          La persona principal que controla la Cuenta tendrá la autoridad para
          delegar sus facultades, en su totalidad o en parte, a personas
          secundarias que tengan acceso a la Cuenta; en el entendido de que el
          Comercio (persona moral o entidad legal) será responsable de cualquier
          acción u omisión por parte de las personas que controlan la cuenta,
          entendiéndose como si la persona principal realizare cada acción, de
          conformidad con la ley aplicable en la materia.
        </p>
        <p className={cls.text}>
          <b>Hospedaje.</b> El Zigu hospedará las Licencias en la nube de su
          elección (nube significa espacio de procesamiento y almacenamiento de
          datos y aplicaciones en servidores físicos que están en un Centro de
          Datos de algún tercero). El hospedaje tiene una disponibilidad
          adecuada. No obstante lo anterior, el Zigu no será responsable de
          cualquier caída, ausencia total o parcial de disponibilidad, ni de
          pérdida total o parcial de datos.
        </p>
        <p className={cls.text}>
          <b>Vigencia.</b> Queda entendido que la suscripción a los Servicios
          tendrá una vigencia indefinida, hasta en tanto no exista una
          instrucción de cancelación por parte del Cliente de conformidad con lo
          establecido en estos Términos.
        </p>
        <p className={cls.text}>
          <b>Terminación.</b> Zigu a su absoluta discreción, podrá dar por
          terminados los Servicios, en cualquier momento,sin necesidad de
          declaración judicial y sin responsabilidad alguna a su cargo ​​en el
          caso o evento que Zigu tenga conocimiento o indicios de que el
          Comercio:
        </p>
        <ol className={cls.ordered}>
          <li>
            Lleva a cabo acciones, conductas u omisiones delictivas o tendientes
            al delito, como podrían ser aquellas que pudiesen tipificar el
            fraude, robo, lavado de dinero, cualquier delito patrimonial o
            delito de naturaleza diversa.
          </li>
          <li>
            Acciones, conductas, omisiones o declaraciones que impacten de forma
            negativa la marca o imagen pública de el Banco, Zigu, las marcas de
            tarjetas, y/o de terceros, de acuerdo a lo previsto en el presente
            contrato o bien, por cualquier causa no enunciada que produzca dicho
            efecto
          </li>
          <li>
            Incumpla con cualquiera obligación contenida en estos Términos, el
            contrato que celebre con Zigu o las Políticas de Zigu.
          </li>
          <li>
            Violaciones o incumplimiento a lo establecido en el Reglamento de
            VISA, MASTERCARD, American Express o cualquier otra red de
            transferencia electrónica de fondos (conocido y referido como VIOR)
            o los reglamentos de otras tarjetas.
          </li>
        </ol>
        <p className={cls.text}>
          En caso de que aplique la baja de el Comercio por incurrir en alguno
          de los supuestos contenidos en este numeral, el Banco tendrá el
          derecho de reportar a cualquier Receptor de Pagos en los sistemas Visa
          Merchant Trace System y MATCH.
        </p>
        <p className={cls.text}>
          <b>Cancelación de Cuenta.</b> El Cliente puede cancelar su Cuenta en
          cualquier momento siguiendo las instrucciones del Perfil de Cuenta en
          el portal de Zigu. Después de cancelar su Cuenta, se cancelará
          cualquier operación pendiente y relación comercial con Zigu. Para que
          pudiera el Comercio cerrar la Cuenta deberá de cubrir todos los
          adeudos que tuviere con Zigu, así como cumplir las obligaciones que
          estén en proceso o pendiente con el Usuario.
        </p>
        <p className={cls.text}>
          La cancelación de su Cuenta no evita que se lleve a cabo las
          investigaciones que resulten conducentes. Si el Comercio cierra su
          cuenta mientras Zigu está llevando a cabo una investigación, Zigu
          podrá retener sus fondos para proteger a Zigu contra el riesgo de
          Cancelaciones, Contracargos, Reclamaciones, comisiones, multas,
          penalizaciones y otras responsabilidades.
        </p>
        <p className={cls.text}>
          El Comercio seguirá siendo responsable de todas las obligaciones
          relacionadas con su Cuenta, incluso después de la cancelación de la
          misma.
        </p>
        <p className={cls.text}>
          <b>Seguridad de la Contraseña.</b> El Comercio es responsable de
          mantener la adecuada seguridad y control de cualquiera de las
          identificaciones, contraseñas, números de identificación personal
          (NIP) o de cualquier otro código que el Comercio utilice para obtener
          acceso a los Servicios Zigu.
        </p>
        <p className={cls.text}>
          <b>Impuestos.</b> Cada una de las Partes será responsable de los
          impuestos que les corresponda según la legislación fiscal vigente
          aplicable. En ningún momento, Zigu será considerado como responsable
          solidario, consultor, asesor de los impuestos que deba presentar y
          reportar el Comercio.
        </p>
        <p className={cls.text}>
          En términos de la Legislación Fiscal Mexicana, a partir del 1º de
          septiembre de 2015, las Comisiones cobradas por Zigu a todos sus
          Clientes bajo la normatividad mexicana, estarán sujetas a la tasa
          ordinaria del Impuesto al Valor Agregado Mexicano (“IVA”).
        </p>
        <p className={cls.text}>
          Bajo algunas condiciones, el Comercio podrá obtener facturas con
          desglose de IVA mensualmente, emitidas en cumplimiento con la
          legislación fiscal mexicana. Los detalles de tales condiciones, así
          como otros detalles relacionados con la generación y obtención de
          tales facturas, serán publicados en el sitio web de Zigu, y en
          comunicaciones independientes que le serán enviadas.
        </p>
        <h3 className={cls.heading}>
          <b>3. USO Y ACCESO A LA PLATAFORMA</b>
        </h3>
        <p className={cls.text}>
          Para acceder a los Servicios, es necesario que el Cliente aperture su
          Cuenta en el Sitio proporcionando los datos y documentos indicados en
          el numeral 2 de estos Términos. Una vez recibidos la totalidad de
          documentos de identificación requeridos por Zigu, Zigu contará con un
          plazo de hasta 5 días hábiles para notificar a Usted la aprobación del
          proceso de alta de la Cuenta, momento en el cual Usted ya podrá hacer
          uso de los Servicios.
        </p>
        <p className={cls.text}>
          El Usuario es el único responsable frente a Zigu, y cualquier tercero,
          respecto de su conducta al acceder, consultar y proporcionar
          información en la Plataforma y de las consecuencias que se puedan
          derivar de una utilización, con fines o efectos ilícitos o contrarios
          al objeto de la Plataforma, su contenido, elaborado o no por Zigu,
          publicado o no bajo su nombre de forma oficial; así como aquellas
          consecuencias que se puedan derivar de la utilización contraria al
          contenido de estos Términos y de la Política de Uso Aceptable que sea
          lesiva de los intereses o derechos de terceros, o que de cualquier
          forma pueda dañar, inutilizar o deteriorar la Plataforma e impedir el
          normal disfrute de otros usuarios.
        </p>
        <h3 className={cls.heading}>
          <b>4. DEL PROCEDIMIENTO</b>
        </h3>
        <p className={cls.text}>
          <b>Saldo.</b> El Saldo de la cuenta del Comercio que se encuentre
          retenido por alguno de los motivos indicados en estos Términos se
          mantendrá en una cuenta concentradora a nombre de Zigu. El Comercio no
          obtendrá intereses ni otras ganancias de los fondos que mantenga en su
          Saldo. Los fondos que formen parte de la cuenta concentradora a título
          de Zigu serán manejados de forma independiente: no serán utilizados
          para solventar los gastos operativos y corporativos de Zigu. Zigu
          podrá recibir y mantener los intereses que los importes de la cuenta
          concentradora generen; EL Comercio acepta ceder los derechos sobre
          aquéllos que deriven de sus fondos a Zigu.
        </p>
        <p className={cls.text}>
          Aclaramos que Zigu no pondrá, de forma voluntaria, sus fondos a
          disposición de los acreedores de Zigu en caso de quiebra o concurso
          mercantil.
        </p>
        <p className={cls.text}>
          <b>
            Saldo Negativo y Compensación de Importes Adeudados y cuyas fechas
            de pago han pasado.
          </b>{" "}
          Si la Cuenta del Comercio tiene Saldo Negativo, Zigu puede compensar
          dicho Saldo Negativo con los pagos que se encuentren por fondear en la
          cuenta del Comercio hasta liquidar el monto adeudado.
        </p>
        <p className={cls.text}>
          Si el Comercio tiene un adeudo con Zigu de cualquier monto, Zigu puede
          descontar de sus pagos las cantidades necesarias para liquidar los
          importes deudores.
        </p>
        <p className={cls.text}>
          Zigu podrá, en caso de que no haya en la cuenta del comercio saldo
          para cubrir los montos adeudados por el Comercio a Zigu, realizar el
          cargo del saldo negativo al Comercio directamente de la cuenta
          contenida y aprobada en el Anexo H por las partes, sin que sea
          requerido autorización o trámite adicional.
        </p>
        <p className={cls.text}>
          Los pagos recibidos en su cuenta podrán cancelarse posteriormente
          cuando un pago sea objeto de invalidación sea por contracargo,
          cancelación, reclamación o cualquier otra razón que lo anule o
          invalide. En dichos casos, el(los) pago(s) se podrá(n) retirar de su
          cuenta inclusive si el Comercio ha entregado al Usuario los bienes o
          servicios que adquirió.
        </p>
        <p className={cls.text}>
          Zigu podrá cerrar, suspender o restringir el acceso a su Cuenta o a
          los Servicios Zigu y/o restringir el acceso a sus fondos si infringe
          estos Términos, el Contrato, la Política de Uso Aceptable de Zigu o
          cualquier otro acuerdo o contrato que haya usted celebrado con Zigu.
        </p>
        <p className={cls.text}>
          Zigu se reserva el derecho de retirar o retener un pago recibido en la
          cuenta después de que el Comercio haya entregado al comprador los
          bienes o servicios cuando esos pagos sean objeto de un contracargo,
          cancelación, reclamación o bien, sean invalidados por cualquier otra
          razón.
        </p>
        <p className={cls.text}>
          <b>Forma de Contactar al Comercio.</b> A efecto de contactar al
          Comercio de forma más eficiente, Zigu puede de vez en cuando contactar
          al Comercio utilizando mensajes auto-marcados o pre-grabados o
          mensajes de texto al (a los) número(s) telefónicos que el Comercio
          haya proporcionado. Zigu puede hacer dichas llamadas o enviar estos
          mensajes de texto a efecto de (i) proveerle notificaciones con
          respecto a su Cuenta o la actividad de su Cuenta; (ii) investigar o
          prevenir fraude, o (iii) cobrar una cuenta que el Comercio adeude. El
          Comercio acuerda que Zigu o nuestros proveedores de servicios pueden
          contactarlo utilizando mensajes auto-marcados o pre-grabados o
          mensajes de texto a fin de llevar acabo los fines descritos con
          antelación. Zigu puede compartir su(s) numero(s) telefónico(s) con
          proveedores de servicios que contratemos para asistirnos en la
          ejecución de tales intereses, pero no compartiremos su(s) número(s)
          telefónicos(s) con terceros para sus propósitos particulares sin
          previo consentimiento por escrito del Comercio.
        </p>
        <p className={cls.text}>
          <b>Devoluciones.</b> En el caso que el Usuario requiera una devolución
          o reembolso total o parcial por cualquier motivo, este deberá
          sujetarse a los términos y condiciones de devoluciones de el Comercio
          aprobadas por Zigu de conformidad con los lineamientos del Anexo B de
          estos Términos.
        </p>
        <h3 className={cls.heading}>
          <b>5. ACTIVIDADES RESTRINGIDAS</b>
        </h3>
        <p className={cls.text}>
          En relación con el uso que el Comercio haga del Sitio, su Cuenta, los
          Servicios o bien, en el transcurso de sus interacciones con Zigu, un
          Usuario, o terceros, Usted deberá abstenerse de lo enunciado a
          continuación:
        </p>
        <ol className={cls.ordered}>
          <li>
            Incumplir estos Términos, el contrato que celebre con Zigu, la
            Política de Uso Aceptable o cualquier otra política que haya
            acordado con Zigu;
          </li>
          <li>Infringir cualquier ley, estatuto, ordenanza o reglamento;</li>
          <li>
            Infringir los derechos de autor, patente, marca comercial, secreto
            comercial u otros derechos de propiedad intelectual de Zigu, de
            terceros, del Banco, o los derechos de publicidad o privacidad de
            los involucrados en este Contrato;
          </li>
          <li>
            Vender artículos falsificados o de procedencia ilícita de
            conformidad con la legislación vigente;
          </li>
          <li>
            Actuar de una manera que sea difamatoria, de carácter de libelo
            comercial, inapropiada, amenazante u hostil hacia/para con los
            empleados de Zigu, agentes u otros Usuarios;
          </li>
          <li>Proporcionar información falsa, inexacta o engañosa;</li>
          <li>
            Involucrarse en actividades y/o transacciones potencialmente
            fraudulentas, ilegales, delictuosas o sospechosas;
          </li>
          <li>
            Negarse a cooperar en una investigación o a proporcionar
            confirmación de su identidad o cualquier otra información que usted
            nos proporcione;
          </li>
          <li>
            Controlar una Cuenta que está asociada a otra Cuenta que se haya
            visto involucrada en cualquiera de estas actividades restringidas;
          </li>
          <li>
            Conducir su empresa o utilizar los Servicios Zigu de manera tal que
            tenga o pueda tener como resultado quejas, Controversias,
            Reclamaciones, Cancelaciones, Contracargos, Comisiones, multas,
            penalizaciones y otras responsabilidades ante Zigu, otros usuarios,
            terceros o Usted;
          </li>
          <li>
            Tener una calificación de riesgo crediticio de una agencia del
            informe de crédito que indique un alto nivel de riesgo asociado a su
            uso de los Servicios Zigu;
          </li>
          <li>
            Utilizar su Cuenta o los Servicios Zigu de manera tal que Visa,
            MasterCard, American Express o cualquier otra red de transferencia
            electrónica de fondos tenga indicios o sospechas de que ha habido un
            abuso del sistema de tarjetas o una transgresión a la asociación de
            tarjetas o de las reglas de la red;
          </li>
          <li>Permitir que su Cuenta tenga saldo negativo;</li>
          <li>
            Usar una tarjeta de crédito en su Cuenta para otorgarse a sí mismo
            un anticipo en efectivo (o ayudar a otros a hacer esto);
          </li>
          <li>
            Revelar o distribuir Información de otros usuarios a terceros o
            utilizar la Información para fines de marketing, a menos que
            previamente reciba el consentimiento expreso y escrito del usuario
            para hacerlo;
          </li>
          <li>
            Enviar correos electrónicos no solicitados a Usuarios o utilizar los
            Servicios Zigu para cobrar cantidad alguna por enviar, o ayudar a
            enviar, correos electrónicos no solicitados a terceros;
          </li>
          <li>
            Adoptar medidas que impongan una carga no razonable o
            desproporcionadamente grande a los Servicios Zigu, en nuestro sitio
            web, software, o sistemas (incluida cualquier red y servidor que
            utilice para proporcionar cualquiera de los servicios de Zigu)
            operados por nosotros o en nuestro nombre;
          </li>
          <li>
            Facilitar la difusión de virus, caballos de troya, malware, gusanos
            u otras rutinas de programación informática que intenten o puedan
            dañar, interrumpir, corromper, maltratar o interferir negativamente,
            interceptar o expropiar u obtener acceso no autorizado a cualquier
            sistema, datos, Información o Servicios Zigu;
          </li>
          <li>
            Utilizar un proxy anónimo; utilizar robots, arañas u otros
            dispositivos automáticos o procesos manuales para controlar o copiar
            nuestros sitios web sin nuestro consentimiento previo por escrito;
          </li>
          <li>
            Uso de cualquier dispositivo, software o rutina para infringir las
            restricciones de nuestros encabezados de exclusión de robots o para
            interferir o interrumpir o intentar interferir con nuestro sitio
            web, software, o sistemas (incluida cualquier red y servidores que
            se utilicen para proporcionar cualquiera de los Servicios Zigu)
            operados por nosotros o en nuestro nombre, cualquiera de los
            Servicios Zigu o con el uso de otro usuario de cualquiera de los
            Servicios Zigu;
          </li>
          <li>
            Adoptar medidas que pueden provocar que perdamos cualquiera de los
            servicios de nuestros proveedores de servicios de Internet,
            procesadores de pago u otros proveedores o proveedores de servicios;
            o
          </li>
          <li>
            Abuso de nuestro proceso de Resolución de Controversias y/o de la
            Protección al Vendedor de Zigu.
          </li>
          <li>
            no le está permitido desembolsar fondos en forma de cheques de
            viajero a manera de contraprestación total o parcial por cualquier
            compra que haga el tarjetahabiente.
          </li>
          <li>
            No desembolsar fondos en forma de efectivo a menos que el Comercio
            este dispensando fondos en forma de cheques de viajero, tarjetas
            Visa Travel Money, o en moneda extranjera.
          </li>
          <li>
            Cualquier otra que cause algún perjuicio a Zigu, directa o
            indirectamente, a consideración exclusiva de Zigu.
          </li>
        </ol>
        <h3 className={cls.heading}>
          <b>6. RESPONSABILIDADES DEL COMERCIO</b>
        </h3>
        <p className={cls.text}>
          El Comercio es responsable de todas las Cancelaciones, Reclamaciones,
          Devoluciones de Cargo, Comisiones, multas, penalizaciones y otras
          responsabilidades en las que hayan podido incurrir Zigu o un tercero,
          provocadas por, u originadas del incumplimiento de este
          Acuerdo|contrato y/o de la utilización de los Servicios Zigu. Acepta
          reembolsar a Zigu o al tercero que corresponda, por cualquiera y cada
          una de dichas responsabilidades.
        </p>
        <p className={cls.text}>
          Dentro de su responsabilidad se incluye el precio total de la compra
          del artículo más los gastos de envío originales (y en algunos casos,
          pudiera ser que el artículo no le fuere retornado) y las comisiones de
          Zigu causadas por la transacción.
        </p>
        <p className={cls.text}>
          En el supuesto de que el Comercio no tuviere Saldo suficiente como
          para cubrir el importe total de su responsabilidad, su Cuenta tendrá
          Saldo Negativo y se le pedirá que lo reembolse inmediatamente a Zigu a
          través de una forma alternativa. Si no se cubriera el monto pendiente
          por el Comercio, Zigu puede iniciar el proceso de cobranza
          correspondiente para recuperar dichos importes. Adicionalmente, y sin
          limitación o exclusión entre ellos, Zigu podrá: incluir su nombre en
          el registro de entidades de la protección de crédito, recurrir a una
          agencia de cobro para recuperar el importe adeudado, ceder, vender o
          transferir bajo cualquier figura la cuenta, y/o iniciar un proceso
          judicial.
        </p>
        <p className={cls.text}>
          Si un Usuario presenta una Reclamación Zigu no está obligado a
          realizar el reembolso de las Comisiones que se hayan generado por la
          transacción original del Comercio.
        </p>
        <h3 className={cls.heading}>
          <b>7. RETENCIONES Y RESERVAS</b>
        </h3>
        <p className={cls.text}>
          <b>Medidas de Zigu.</b> Si Zigu, a su entera discreción, considera que
          el Comercio se ha visto involucrado en Actividades Restringidas,
          optaremos por ejercer las acciones que sean necesarias con la
          finalidad de proteger a Zigu, sus Miembros, a terceros o el Comercio,
          contra Cancelaciones, Devoluciones de Cargo, Reclamaciones,
          Comisiones, multas, penalizaciones y cualquier otra responsabilidad.
        </p>
        <p className={cls.text}>
          Entre las medidas o acciones que Zigu puede adoptar son:
        </p>
        <ol className={cls.ordered}>
          <li>
            Cancelar, suspender o limitar el acceso al Comercio de su Cuenta o a
            los Servicios Zigu,
          </li>
          <li>
            Retener los fondos del Comercio por un período de tiempo
            razonablemente necesario para proteger contra el riesgo de
            responsabilidad a Zigu, un tercero, o bien, en caso de que Zigu
            considere que el Comercio pueda estar involucrado en actividades o
            transacciones sospechosas o potencialmente fraudulentas o
            delictuosas.
          </li>
          <li>Limitar al Comercio el acceso al Sitio y a la Plataforma.</li>
        </ol>
        <p className={cls.text}>
          <b>Retenciones</b>
        </p>
        <ol className={cls.ordered}>
          <li>
            Retenciones en función del riesgo. Zigu, a su entera discreción,
            puede aplicar retenciones a cualquiera o cada uno de los pagos que
            el Comercio reciba si Zigu considera que puede existir riesgo
            asociado al Comercio, su Cuenta o a cualquiera y cada una de sus
            transacciones. Dichas retenciones podrán realizarse hasta por 180
            (ciento ochenta) días naturales.
            <br />
            <br />
            Se podrán retener todos los fondos, salvo las comisiones y demás
            conceptos que sean parte de los conceptos que se originan por la
            prestación de los Servicios Zigu.
            <br />
            <br />
            En caso de que Zigu aplicara la retención en un pago, los fondos
            aparecerán en su “reserva” y el estado del pago indicará la
            retención: por ejemplo, puede aparecer como “Realizado, fondos no
            disponibles aún”. Si Zigu aplicara retenciones en algunos o en todos
            los pagos que recibe, Zigu le dará aviso de ello.
            <br />
            <br />
            Zigu liberará la retención de cualquier pago transcurridos 30 días
            naturales a partir de la fecha en que lo recibió en su Cuenta, a
            menos que Zigu tenga un motivo para continuar la(s) retención(es),
            como puede ser (a) recibirse una Controversia, Reclamación,
            Contracargo o Cancelación, (b) la sospecha y/o indicio razonable de
            que el Comercio haya transgredido los términos de este contrato o de
            cualquier otra Política, y que dicha transgresión tiene como
            resultado la necesidad de continuar reteniendo los fondos o (c) hay
            razón para que Zigu crea que el Comercio puede estar involucrado en
            actividades o transacciones sospechosas o potencialmente
            fraudulentas.
            <br />
            <br />
            En tales casos, Zigu continuará la(s) retención(es) hasta que se
            resuelva el problema conforme a este contrato.
            <br />
            <br />
            Zigu, podrá, a su entera discreción, liberar la retención por
            anticipado al término previamente señalado bajo ciertas
            circunstancias, como por ejemplo, si usted proporciona la
            información pertinente con la que se verifique que el artículo fue
            recibido.
          </li>
          <li>
            Retenciones de Transacciones en Controversia. Si un Usuario presenta
            una Controversia, Reclamación, Contracargo o Cancelaciones por un
            pago que el Comercio ha recibido en su Cuenta, Zigu puede retener
            temporalmente los fondos de su Cuenta para cubrir el importe de la
            responsabilidad respectiva. Si la Controversia se resuelve a favor
            del Comercio o la transacción, eliminaremos la retención temporal.
            Si la resolución fuere contraria a los intereses del Comercio, Zigu
            los enterará a la parte reclamante.
          </li>
        </ol>
        <p className={cls.text}>
          <b>Reservas</b>
        </p>
        <p className={cls.text}>
          Zigu, a su entera discreción, puede aplicar una Reserva a los fondos
          de su Cuenta si considera que puede existir un alto nivel de riesgo
          asociado a la Cuenta del Comercio. Si Zigu aplica una Reserva en la
          Cuenta del Comercio, las transacciones aparecerán como “reserva” en su
          saldo Zigu y el Comercio no tendrá acceso a los fondos que están en
          estado “pendiente” hasta que se compensen. Si la Cuenta del Comercio
          está sujeta a una Reserva, Zigu le proporcionará una notificación que
          especifique los términos de la reserva. Los términos pueden requerir
          que cierto porcentaje de los importes que reciba en su Cuenta se
          retengan por un período específico, que cierto importe de dinero se
          retenga en la Reserva o bien, cualquier otra medida que Zigu determine
          necesaria para resguardar el riesgo asociado a la Cuenta del Comercio.
          Zigu podrá cambiar los términos de la Reserva en cualquier momento
          previa notificación de los nuevos términos y condiciones comerciales
          que se adicionará como Anexo C. <br /> Zigu fijará a discreción el
          modelo de la reserva aplicada al Comercio en cualquier momento.
        </p>
        <p className={cls.text}>
          12.5 Cierre de la Cuenta, cancelación del servicio, acceso restringido
          a la Cuenta
          <br />
          Zigu se reserva, a su entera discreción, el derecho de suspender o dar
          por terminado este Acuerdo|contrato o acceso o uso de su sitio web,
          software, o sistemas (incluida cualquier red y servidores que se
          utilizan para proporcionar cualquiera de los Servicios Zigu) operados
          por Zigu o a su nombre, o parte o la totalidad de los Servicios Zigu
          por cualquier motivo y en cualquier momento tras previo aviso 3 días
          hábiles y pago de cualquier fondo no restringido que mantenga en su
          Saldo; lo anterior sin responsabilidad para Zigu o la necesidad de
          resolución judicial previa o acción adicional a las ya mencionadas.
        </p>
        <p className={cls.text}>
          Si limitamos el acceso a la Cuenta del Comercio, incluido a través de
          Reservas o retenciones, le daremos aviso de ello y la oportunidad para
          solicitar el restablecimiento del acceso, reservando la oportunidad de
          hacerlo a nuestra discreción y al considerarlo adecuado. La decisión
          de Zigu de adoptar ciertas medidas, incluidas limitar el acceso a la
          Cuenta de Comercio, colocar retenciones o imponer Reservas, puede
          obedecer criterios confidenciales esenciales para nuestra gestión del
          riesgo, la seguridad de las cuentas de los usuarios, el sistema Zigu o
          los proveedores de servicios de Zigu. <br /> Zigu no tiene obligación
          de revelar o desglosar los detalles del manejo del riesgo o de sus
          procedimientos de seguridad.
        </p>
        <h3 className={cls.heading}>
          <b>7. POLÍTICA DE USO ACEPTABLE.</b>
        </h3>
        <p className={cls.text}>
          Usted puede consultar la Política de Uso Aceptable aquí. Zigu se
          reserva el derecho de, en cualquier momento, modificar y/o renovar
          unilateralmente y sin previo aviso la Política, con la obligación de
          publicar un mensaje en el Sitio
        </p>
        <p className={cls.text}>
          Si el COMERCIO infringe la Política de Uso Aceptable, además de las
          medidas antes mencionadas, el COMERCIO será responsable ante ZIGU por
          el importe de los daños provocados a ZIGU por cada infracción de la
          Política de Uso Aceptable. Acepta que 2,500.00 USD (o su equivalente)
          por transacción que transgreda la Política de Uso Aceptable es una
          valoración mínima razonable por los daños reales ocasionados a ZIGU,
          teniendo en Cuenta todas las circunstancias existentes, más los daños
          y perjuicios que pudieren ocasionarse a ZIGU, ya que por la naturaleza
          de las transgresiones de la Política de Uso Aceptable, los daños
          reales sean difíciles o imposibles de calcular. ZIGU puede deducir los
          importes de dichos daños directamente de los Saldos de su Cuenta.
        </p>
        <h3 className={cls.heading}>
          <b>​​8. ERRORES Y TRANSACCIONES NO AUTORIZADAS</b>
        </h3>
        <p className={cls.text}>
          <b>Protección para Errores.</b> Cuando ocurre un Error en la Cuenta
          del COMERCIO, ZIGU cubrirá el importe total de todo Error que reúna
          los requisitos aplicables, siempre que el COMERCIO siga los
          procedimientos que se describen a continuación.
        </p>
        <p className={cls.text}>1. Requisitos de Notificación.</p>
        <ol className={cls.heading}>
          <li>
            El COMERCIO notificará de inmediato a ZIGU si cree que:
            <ul className={cls.ordered}>
              <li>Ha habido un acceso no autorizado a su Cuenta,</li>
              <li>
                Existe un Error en su estado de Cuenta ZIGU (usted puede acceder
                a su estado de Cuenta ZIGU iniciando sesión en su Cuenta y
                haciendo clic en el enlace a “Ver todas mis Transacciones”) o
                bien, en la confirmación de la transacción que se le envió por
                correo electrónico,
              </li>
              <li>
                Hay un error en el importe que se le cargó o hay un importe
                cargado a la Cuenta del COMERCIO o transferencias pendientes o
                preautorizadas (recurrentes),
              </li>
              <li>Su contraseña de ZIGU se ha visto comprometida,</li>
              <li>
                Ha perdido, le han robado o ha desactivado el equipo con acceso
                a ZIGU, o
              </li>
              <li>
                Necesita más Información acerca de una transacción que aparece
                en el estado de cuenta o en la confirmación de transacción.
              </li>
            </ul>
          </li>
          <br />
          <li>
            Para reunir los requisitos para la protección por Transacciones No
            Autorizadas, el COMERCIO lo debe notificar a ZIGU en el plazo de 60
            días naturales luego de que aparezca la primera Transacción No
            Autorizada en el estado de cuenta del Historial de su Cuenta.
          </li>
          <br />

          <li>
            COMERCIO Debe iniciar sesión regularmente en su Cuenta y ver el
            estado de cuenta del historial de ella para asegurarse de que no
            haya Transacciones No Autorizadas o Errores. Además, ZIGU le enviará
            un correo electrónico a la dirección principal que proporcionó para
            que se le notifique de cada transacción de su Cuenta. También debe
            el COMERCIO, comprobar las confirmaciones de estas transacciones
            para asegurarse de que cada transacción haya sido autorizada y sea
            correcta.
          </li>
        </ol>
        <p className={cls.text}>
          En caso de Transacciones No Autorizadas o Errores en su Cuenta, el
          COMERCIO deberá de notificarlo al Correo Electrónico o al Servicio de
          Atención al Cliente de ZIGU al 81-1356-1257.
        </p>
        <p className={cls.text}>
          La notificación que el COMERCIO haga a ZIGU deberá de contener: nombre
          y el correo electrónico registrado en su Cuenta; una descripción de la
          Transacción no Autorizada o Error, así como la descripción de por qué
          considera que es incorrecto o de por qué necesita más Información para
          identificar la transacción; y el importe de la Transacción no
          Autorizada o Error.
        </p>
        <p className={cls.text}>
          Durante el curso de la investigación, ZIGU podrá solicitar al COMERCIO
          Información adicional.
        </p>
        <p className={cls.text}>
          2. Medidas de ZIGU una vez recibida su Notificación.
        </p>
        <p className={cls.text}>
          Una vez que el COMERCIO notifique acerca de presuntas Transacciones no
          Autorizadas o Errores, ZIGU seguirá el procedimiento de investigación
          para determinar si ha habido un Transacción no Autorizada o Error. La
          investigación se realizará en el plazo de 10 Días Hábiles a partir de
          la fecha en que se reciba la notificación de Transacción no Autorizada
          o Error sospechoso. Si la Cuenta del COMERCIO es nueva (la primera
          transacción de su Cuenta se hizo hace menos de 30 Días Hábiles a
          partir de la fecha en que se notificó), entonces ZIGU tardará hasta 45
          días naturales en concluir la investigación. ZIGU informará sobre la
          conclusión de la investigación en un lapso de 3 días hábiles a su
          terminación.
        </p>
        <p className={cls.text}>
          Si como resultado de la investigación se concluye que se trata de una
          Transacción no Autorizada o Error, se realizará a la brevedad el
          importe total en su Cuenta. Si ya recibió un abono provisional, podrá
          conservarlo.
        </p>
        <p className={cls.text}>
          Si determinamos que no hubo una Transacción no Autorizada o Error, se
          incluirá una explicación acerca de la decisión de ZIGU en el correo
          electrónico que le enviemos. Si recibió un abono provisional, lo
          quitaremos de su Cuenta y le notificaremos de la fecha e importe del
          cobro. El COMERCIO podrá solicitar copias de los documentos que
          utilizamos en la investigación.
        </p>
        <h3 className={cls.heading}>
          <b>9. CONTROVERSIAS CON ZIGU</b>
        </h3>
        <p className={cls.text}>
          ZIGU tiene como objetivo conocer y abordar el problema. Si no logramos
          hacerlo, buscamos proporcionar un medio neutral y eficiente para
          resolver la controversia eficazmente.
        </p>
        <p className={cls.text}>
          En caso de que hubiere alguna controversia, primeramente el COMERCIO
          intentará resolverlo de manera interna y directa con ZIGU. Esta etapa
          de conciliación tendrá una duración de hasta 180 días, de conformidad
          con lo establecido en el apartado 12 de este Contrato.
        </p>
        <p className={cls.text}>
          Las controversias entre el COMERCIO y ZIGU en relación con los
          Servicios ZIGU pueden ser reportadas en línea al Servicio de Atención
          al Cliente (a través del Centro de Ayuda de ZIGU) o llamando al número
          81-1356-1257 en un horario de lunes a viernes de 9:00 a las 19:00
          horas, (hora de la Ciudad de México), en días hábiles.
        </p>
        <p className={cls.text}>
          En el supuesto de que las partes no llegaren a un acuerdo en la
          solución de alguna controversia según el inciso a) anterior, se
          sujetarán al Arbitraje, cuya resolución tendrá carácter de definitiva,
          sin que pudiera ser recurrida por ninguna de las Partes.
        </p>
        <p className={cls.text}>El arbitraje, tendrá las siguientes reglas:</p>
        <ol className={cls.ordered}>
          <li>
            Cualquier disputa que las Partes no puedan resolver dentro de los
            diez (10) días posteriores a la recepción por parte de una Parte de
            una notificación por escrito de dicha disputa de la otra Parte será
            referida a un panel integrado por un alto ejecutivo (presidente,
            vicepresidente o una persona con título similar) de cada Parte
            aplicable o cualquiera de sus Afiliadas, si corresponde, con
            autoridad para decidir o resolver la Disputa, para su revisión y
            resolución. Dichos ejecutivos senior deberán reunirse e intentar de
            buena fe resolver la disputa dentro de los veinticinco (25) días
            posteriores a la recepción de dicha notificación por escrito.
          </li>
          <li>
            Las Partes acuerdan que cualquier disputa que surja en relación con
            este Acuerdo o cualquier documento de los aquí señalados que formen
            parte integral del presente, debe ser presentada exclusivamente y
            finalmente resuelta mediante arbitraje bajo las reglas de arbitraje
            de la Cámara de Comercio Internacional (el " ICC ") por un árbitro
            designado de acuerdo con las reglas de la Corte Internacional De
            Arbitraje de la ICC.
          </li>
          <li>
            La Parte que sea condenada en la resolución arbitral deberá de pagar
            los costos de la misma, salvo los honorarios legales erogados por la
            contraparte, la cual estará a cargo de la parte contratante de los
            propios servicios legales.
          </li>
        </ol>
        <p className={cls.text}>Las Partes acuerdan además que:</p>
        <ol className={cls.ordered}>
          <li>
            Será nombrado un árbitro con conocimiento especializado en el objeto
            de este Contrato base de la relación comercial entre las partes;
          </li>
          <li>el arbitraje será desahogado en Monterrey, Nuevo León;</li>
          <li>
            el idioma que se utilizará el procedimiento arbitral será español;
          </li>
          <li>
            cualquier adjudicación monetaria se realizará en pesos de los
            Estados Unidos Mexicanos;
          </li>
          <li>
            el árbitro tomará en consideración la naturaleza de la transacción
            al interpretar este Acuerdo de conformidad con la legislación
            mexicana;
          </li>
          <li>
            Si hubiere más de una disputa entre las partes relativa a este
            acuerdo, podrá combinarse los procedimientos arbitrales para que sea
            en uno solo la resolución de los conflictos existentes.;
          </li>
          <li>
            en la medida permitida por la ley aplicable, las Partes renuncian
            irrevocablemente a todo y cualquier derecho a un juicio y a
            cualquier procedimiento legal que surja de o esté relacionado con
            este Acuerdo, salvo lo relativo a la falta de pago;
          </li>
          <li>
            la decisión del árbitro se emitirá dentro de los treinta (30) días
            calendario del cierre de las audiencias con respecto al arbitraje (o
            el tiempo más largo que se acordó, si es necesario, que acuerdo no
            se retendrá irrazonablemente) y la decisión del árbitro una vez
            emitida por escrito y firmada por el árbitro será definitiva,
            concluyente y vinculante para las Partes del presente, y podrá ser
            ejecutada en cualquier tribunal que tenga jurisdicción; y el árbitro
            determinará la proporción de los gastos de dicho arbitraje de los
            cuales cada Parte será responsable; siempre que, sin embargo, la
            Parte ganadora tenga derecho a recuperar sus honorarios y gastos
            legales.
          </li>
        </ol>
        <p className={cls.text}>
          c. Durante la solución de controversia ZIGU retendrá, en todo o en
          parte los fondos, según lo dispuesto en estos Términos.
        </p>
        <h3 className={cls.heading}>
          <b>10. DATOS DE CARÁCTER PERSONAL</b>
        </h3>
        <p className={cls.text}>
          Usted reconoce y acepta que, para el cumplimiento del objetivo de la
          Plataforma, será necesario que Zigu recabe datos personales y datos
          personales sensibles para llevar a cabo el alta de la Cuenta y ofrecer
          los Servicios.
        </p>
        <p className={cls.text}>
          En cumplimiento a los términos previstos en la Ley Federal de
          Protección de Datos Personales en Posesión de Particulares (“LFPDPPP”)
          Zigu, como responsable del tratamiento de sus datos personales, hace
          del conocimiento del Cliente que la información que provea en la
          Plataforma será tratada de conformidad con lo indicado en el Aviso de
          Privacidad contenido en el Sitio. Para utilizar los Servicios, es
          necesario que el Cliente proporcione previamente al Zigu ciertos datos
          de carácter personal (“Datos Personales”).
        </p>
        <h3 className={cls.heading}>
          <b>11. RESPONSABILIDAD Y CALIDAD EN LA PRESTACIÓN DE LOS SERVICIOS</b>
        </h3>
        <p className={cls.text}>
          Usted reconoce que la Plataforma es una herramienta tecnológica que es
          un medio para que el Cliente pueda desarrollar una actividad
          específico, por lo cual acepta que el Zigu no garantiza la calidad,
          idoneidad y/o disponibilidad de los servicios brindados o solicitados
          a través del uso de la Plataforma y/o mediante su uso. Usted
          expresamente reconoce y acepta todos y cada uno de los riesgos
          derivados del uso de la Plataforma, liberando al Zigu de cualquier
          responsabilidad presente o futura que se pudiera presentar. En este
          sentido, el Zigu no será responsable frente al Cliente, o cualquier
          persona relacionada a este, por cualquier tipo de daño o reclamo
          derivado de deficiencias en los Servicios, o por cualquier error,
          omisión y/o falsedad en la información proporcionada por el Cliente,
          ya sea a través de la Plataforma o cualquier otro medio.
        </p>
        <h3 className={cls.heading}>
          <b>12. EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD</b>
        </h3>
        <p className={cls.text}>
          El Cliente es el único responsable del uso que haga a la Plataforma.
          El Cliente reconoce que la información de la Plataforma y de los
          Servicios se proporcionan “como están”, sin ninguna garantía expresa o
          implícita de comerciabilidad o de aptitud para un fin determinado.
          Zigu no garantiza la precisión ni la integridad de la información,
          textos, gráficos, enlaces u otros elementos contenidos en la
          Plataforma o Contenido. Zigu no garantiza la operación ininterrumpida
          o libre de todo error de la Plataforma.
        </p>
        <p className={cls.text}>
          Todo material descargado u obtenido de un modo distinto al previsto en
          la Plataforma, será bajo responsabilidad y riesgo único del Usuario,
          respecto de los daños que pudiera causar en el sistema informático del
          dispositivo a través del cual realice su conexión y/o la pérdida de
          datos que derive de la descarga de ese material. En ningún caso, ni el
          Zigu ni sus proveedores serán responsables de daño alguno derivado del
          uso de la Plataforma o de no poder usarlos (EN PARTICULAR, SIN
          LIMITACIÓN ALGUNA, DE LOS DAÑOS DIRECTOS O INDIRECTOS, MORALES,
          INCIDENTALES, EXCESIVOS, REMOTOS Y/O EVENTUALES, PERJUICIOS, LUCRO
          CESANTE, INTERRUPCIÓN DE LA ACTIVIDAD COMERCIAL O PÉRDIDA DE
          INFORMACIÓN O DATOS Y/O INFRACCIONES DE SEGURIDAD), aún cuando se
          hubiera advertido al Zigu de dicha posibilidad.
        </p>
        <h3 className={cls.heading}>
          <b>13. USO DE COOKIES</b>
        </h3>
        <p className={cls.text}>
          Zigu informa al Cliente que, mediante el uso de cookies y tecnologías
          similares, busca: i) garantizar la mejor experiencia posible en la
          Plataforma; y ii) proporcionar al Usuario información sobre sus
          preferencias de servicios y de mercadeo, ayudándolo así a obtener la
          información adecuada. En caso de que el Usuario requiera de mayor
          información respecto al uso de cookies y tecnologías similares, Zigu
          pone a su disposición la Política de Uso de Cookies.
        </p>
        <h3 className={cls.heading}>
          <b>14. COMPATIBILIDAD DE LOS DISPOSITIVOS ELECTRÓNICOS</b>
        </h3>
        <p className={cls.text}>
          El Usuario será responsable de obtener los dispositivos o hardware que
          sean compatibles con la Plataforma y los Servicios, toda vez que Zigu
          no garantiza que estos funcionen correctamente en cualquier
          dispositivo. De igual manera, el Cliente acepta no utilizar
          dispositivos, software o cualquier otro medio tendiente a interferir
          tanto en las actividades y/u operaciones de los Servicios o en la
          Plataforma o en las bases de datos y/o información que se contenga en
          el mismo.
        </p>
        <h3 className={cls.heading}>
          <b>15. MANTENIMIENTO DE LA PLATAFORMA</b>
        </h3>
        <p className={cls.text}>
          Para llevar a cabo trabajos de mantenimiento, Zigu se reserva el
          derecho de suspender el acceso y/o modificar el Sitio, así como a
          eliminar o deshabilitar el acceso a la Plataforma o a los Servicios,
          sin previo aviso. El acceso a la Plataforma y los Servicios depende de
          la disponibilidad de la red que tenga el Cliente, por lo que Zigu no
          será responsable por cualquier imposibilidad de acceder a la misma,
          derivada de circunstancias que se encuentren fuera de control de Zigu,
          así como por caso fortuito o de fuerza mayor. Zigu, cuando lo
          considere necesario para el correcto funcionamiento de la Plataforma,
          podrá realizará los parches, actualizaciones, correcciones de “bugs” y
          mejoras menores a la Plataforma.
        </p>
        <h3 className={cls.heading}>
          <b>16. SOPORTE</b>
        </h3>
        <p className={cls.text}>
          Zigu ofrece al Cliente el servicio de soporte técnico y orientación
          básica para la utilización de las herramientas y las funcionalidades
          de la Plataforma, pudiendo ser por vía Correo Electrónico, o cualquier
          otro medio que el Zigu considere conveniente y factible, en los
          horarios indefinidos que de igual forma designe para tal efecto,
          mediante previo aviso. Este servicio no tendrá ningún costo adicional.
          Asimismo, el Usuario que hubiere solicitado el Soporte, acepta y
          autoriza al Zigu para tener acceso pleno a toda la información
          proporcionada en la Plataforma, sin ninguna limitación. En este
          sentido y en beneficio del Usuario, el Zigu se obliga a guardar plena
          secrecía y confidencialidad, respecto a la información a la que tenga
          acceso.
        </p>
        <h3 className={cls.heading}>
          <b>17. PROPIEDAD INDUSTRIAL Y DERECHO DE AUTOR</b>
        </h3>
        <p className={cls.text}>
          Zigu autoriza al Cliente a utilizar la Plataforma, exclusivamente bajo
          los términos aquí expresados, sin que ello implique que concede al
          Cliente licencia o autorización alguna, o algún tipo de derecho
          distinto al antes mencionado, respecto de la Propiedad Industrial y
          Derecho de Autor del Zigu, entendiéndose como ello: todas las marcas
          registradas y/o usadas en México o en el extranjero por el Zigu; todo
          derecho sobre invenciones (patentadas o no), diseños industriales,
          modelos de utilidad, información confidencial, nombres comerciales,
          secretos industriales, avisos comerciales, reservas de derechos,
          nombres de dominio; así como todo tipo de derechos patrimoniales sobre
          obras y creaciones protegidas por derechos de autor y demás formas de
          propiedad industrial o intelectual reconocida o que lleguen a
          reconocer las leyes correspondientes.
        </p>
        <p className={cls.text}>
          Usted reconoce y acepta que el Zigu es legítimo propietario, o tiene
          los derechos necesarios, sobre la Plataforma, incluidos los nombres
          comerciales del Zigu, marcas comerciales, marcas de servicio,
          logotipos, nombres de dominio y otras características distintivas de
          la marca contenidas en ellos (las “Marcas Registradas del Zigu”),
          independientemente de que esos derechos estén registrados o no, y de
          cualquier lugar del mundo en el que puedan existir esos derechos, y
          que están protegidos por las leyes y tratados internacionales sobre
          propiedad industrial y derecho de autor. Por lo anterior, Usted acepta
          que las Marcas Registradas del Zigu no podrán ser objeto de copia,
          reproducción, modificación, publicación, carga, envío, transmisión o
          distribución en modo alguno. Salvo indicación expresa en contrario en
          este documento, el Zigu no concede al Cliente ningún derecho expreso
          ni implícito en virtud de patentes, derecho de autor, marcas
          comerciales o información de secretos industriales. Usted reconoce y
          conviene que la Plataforma, así como todos los diseños del mismo, son
          y, serán en todo momento, propiedad del Zigu.
        </p>
        <p className={cls.text}>
          <b>Retroalimentación.</b> En caso de que el Cliente proporcione algún
          comentario al Zigu respecto de la funcionalidad y el rendimiento de la
          Plataforma (incluida la identificación de posibles errores y mejoras),
          en este acto, el Cliente autoriza al Zigu para que haga uso, sin
          restricción, de todos los derechos, títulos e intereses sobre los
          comentarios expresados. Lo anterior, sin que ello se considere como un
          derecho moral del Cliente para requerir participación o retribución
          monetaria alguna, o restricción en el uso de dichos comentarios para
          su explotación por parte del Zigu.
        </p>
        <h3 className={cls.heading}>
          <b>18. OTRAS DISPOSICIONES</b>
        </h3>
        <p className={cls.text}>
          Usted acepta que una versión impresa de los presentes Términos, y de
          cualquier comunicación enviada y/o recibida en forma electrónica, será
          admisible como medio probatorio en cualquier procedimiento judicial
          y/o administrativo.
        </p>
        <h3 className={cls.heading}>
          <b>19. DIVISIBILIDAD</b>
        </h3>
        <p className={cls.text}>
          En caso de que cualquier término, condición o estipulación contenida
          en estos Términos se determine ineficaz, ilegal o sin efecto, el mismo
          podrá ser excluido del cuerpo del presente y el restante continuará en
          vigor y efecto en forma tan amplia como en derecho proceda.
        </p>
        <h3 className={cls.heading}>
          <b>20. DERECHOS.</b>
        </h3>
        <p className={cls.text}>
          Cualquier derecho que no se haya conferido expresamente en este
          documento, se entiende reservado al Zigu.
        </p>
        <h3 className={cls.heading}>
          <b>21. LEY Y JURISDICCIÓN APLICABLE</b>
        </h3>
        <p className={cls.text}>
          En todo lo relacionado con la interpretación y cumplimiento de lo aquí
          dispuesto, las Partes aceptan someterse a las legislación federal de
          México y a la jurisdicción de los tribunales competentes en la Ciudad
          de Monterrey, Nuevo León,, México; renunciando a cualquier otra
          jurisdicción que, por razón de sus domicilios presentes o futuros,
          pudiese corresponderles.
        </p>
        <h3 className={cls.heading}>
          <b>22. FORMA DIGITAL, ELECTRÓNICA O EN LÍNEA</b>
        </h3>
        <p className={cls.text}>
          La Partes acuerdan que la forma para perfeccionar el acuerdo de
          voluntades entre ellas es el de formato Digital, Electrónico o en
          Línea, en donde bastará manifestar su voluntad por medio de la
          aceptación de los presentes Términos, así como proporcionar los datos
          personales e información bancaria en la Plataforma o en las distintas
          aplicaciones de los licenciantes, sin requerir estampar la firma en
          documento alguno.
        </p>
        <h3 className={cls.heading}>
          <b>23. ACEPTACIÓN DE LOS TÉRMINOS</b>
        </h3>
        <p className={cls.text}>
          Usted reconoce que, mediante el acceso, suscripción y uso de la
          Plataforma, los Servicios y/o Contenidos o derivados, manifiesta su
          aceptación plena y sin reservas y, por tanto, su adhesión a la versión
          de los Términos publicada en el momento en que acceda a la Plataforma,
          en términos de lo establecido por los artículos 1803 y 1834 Bis del
          Código Civil Federal, 80, 81, 89 y demás relativos y aplicables del
          Código de Comercio y la legislación aplicable para México. Es
          responsabilidad única y exclusiva del Cliente, leer previamente estos
          Términos y sus modificaciones correspondientes, cada vez que accede a
          la Plataforma. Si en cualquier momento, el Cliente no estuviera de
          acuerdo, total o parcialmente con los presentes Términos, deberá
          abstenerse inmediatamente de acceder y usar la Plataforma y los
          Servicios provistos. Por lo anterior, con la aceptación de los
          presentes Términos, el Cliente consiente expresamente sujetarse a los
          mismos, celebrando así un acuerdo de uso de Plataforma con el Zigu,
          por lo que manifiesta haber leído el contenido de todas y cada una de
          las disposiciones y ratifica su contenido.
        </p>
        <h3 className={cls.heading}>
          <b>24. ACUERDO TOTAL</b>
        </h3>
        <p className={cls.text}>
          El Cliente reconoce y acepta que Zigu puso a su disposición toda la
          información necesaria para entender el alcance y características de la
          Plataforma y los Servicios. De igual forma, manifiesta que, previo al
          acceso a la Plataforma, analizó las características de esta y, por
          consiguiente, está de acuerdo con ella.
        </p>
        <br />
        <br />
        <p className={cls.text}>Fecha de primera emisión: [3 de Mayo, 2022]</p>
        <p className={cls.text}>
          Fecha de última modificación: [3 de Mayo, 2022]
        </p>
      </div>
    </div>
  );
};

export default AvisoDePrivacidad;
