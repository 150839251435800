import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tarifas: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  body: {
    maxWidth: "1600px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    marginTop: "-650px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-750px",
    },
  },
  title: {
    fontFamily: "Accura SemiBold",
    fontWeight: "bold",
    fontSize: "70px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "40px",
    },
  },
  font: {
    color: "#7174D7",
  },
  card: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      borderRadius: "0px",
      paddingBottom: "15vh",
    },
    background: '#7174D7 center 0px no-repeat url("./imgs/tarifas/clip.png")',
    width: "90%",
    borderRadius: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  imgsTop: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    borderRadius: "30px",
    width: "100%",
    overflow: "hidden",
    "& > div": {
      width: "100%",
      height: "500px",
      overflow: "hidden",
    },
  },
  imgImage: {
    marginTop: theme.spacing(8),
    width: "60%",
    zIndex: "2",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      width: "80%",
    },
  },
  precio: {
    maxWidth: "700px",
    backgroundColor: "#FEC011",
    borderRadius: "30px",
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "80px",
    marginBottom: "40px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
    },
    "& > h1": {
      color: "#7174D7",
      fontFamily: "Accura SemiBold",
      fontWeight: "bold",
      fontSize: "100px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "60px",
      },
      // [theme.breakpoints.down('xs')]: {
      //     fontSize: '100px',
      // },
    },
    "& > div": {
      marginTop: "-25px",
      marginBottom: "20px",
      width: "455px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        width: "100%",
      },
    },
    "& > div > span": {
      [theme.breakpoints.up("xs")]: {
        width: "150px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "250px",
        textAlign: "center",
      },
    },
    "& > div > label": {
      color: "#7174D7",
      fontFamily: "Open Sans",
      fontSize: "45px",
      marginRight: "15px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        textAlign: "center",
        marginTop: "10px",
        fontSize: "32px",
      },
    },
  },
  txtTarifas: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      marginBottom: "90px",
    },
    "& > h1:first-child": {
      color: "#FEC011",
    },
    "& > h1:nth-child(2)": {
      color: "#fff",
    },
  },
  imagenes: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "center",
      flexWrap: " wrap-reverse",
      width: "90%",
      "& > div": {
        width: "500px",
        marginTop: "60px",
      },
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: " wrap-reverse",
    width: "120%",
    marginTop: "-100px",
    marginBottom: "-62px",
    "& > div": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        textAlign: "center",
      },
      width: "50%",
      "& > img": {
        width: "90%",
        [theme.breakpoints.down("sm")]: {
          width: "80%",
        },
      },
    },
    "& > div:nth-child(1)": {
      backgroundPosition: "0px 0px",
      backgroundSize: "100% ",
      background: 'no-repeat url("./imgs/tarifas/estrellas.png")',
      height: "820px",
      [theme.breakpoints.down("sm")]: {
        // height: "650px",
        display: "none",
      },
      "& > img": {
        width: "320px",
        [theme.breakpoints.down("sm")]: {
          width: "280px",
        },
      },
    },
  },
  txtNave: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "50px",
    },
    marginLeft: "10%",
    width: "100%",
    textAlign: "center",
    fontSize: "35px",
    "& > label:nth-child(1)": {
      color: "#FEC011",
    },
    "& > label:nth-child(n + 2)": {
      color: "#fff",
    },
  },
  servicios: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "100px",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
      marginBottom: "0px",
    },
    "& > label": {
      fontFamily: "Accura SemiBold",
      fontSize: "70px",
      marginBottom: "60px",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        fontSize: "40px",
        marginBottom: "20px",
      },
    },
    "& > div": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      flexWrap: "wrap",
    },
    "& > div > div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      textAlign: "center",
      alignItems: "center",
      height: "400px",
      width: "320px",
      minWidth: "250px",
      [theme.breakpoints.down("sm")]: {
        margin: "20px 0",
      },
    },
    "& > div > div > *": {
      margin: "3px 0px",
    },
    "& > div > div img": {
      width: "250",
      minWidth: "100px",
      [theme.breakpoints.down("sm")]: {
        width: "40%",
      },
    },
    "& > div label": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: "500",
      fontSize: "35px",
      fontFamily: "Accura SemiBold",
      [theme.breakpoints.down("sm")]: {
        fontSize: "30px",
      },
    },
    "& > div p": {
      width: "80%",
      color: "#58585A",
    },
    "& > div a": {
      fontWeight: "900",
      color: "#7174D7",
      fontSize: "20px",
      fontFamily: "Accura SemiBold",
      marginTop: "5px",
    },
  },
  space: {
    height: "120px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  links: {
    height: "120px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      height: "50px",
    },
  },
  texts: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "20px",
    },
  },
}));

const servicios = [
  {
    img: "./imgs/tarifas/boton.png",
    alt: "boton",
    title: "Botón de pago",
    txt: "Estaremos en contacto contigo para asistirte en cuestiones técnicas y para ayudarte a completar el proceso de alta.",
    to: "/servicios",
  },
  {
    img: "./imgs/tarifas/api.png",
    alt: "api",
    title: "API",
    txt: "Integra nuestra solución al nivel de tus necesidades de cobro con nuestra API.",
    to: "/servicios",
  },
  {
    img: "./imgs/tarifas/link.png",
    alt: "link",
    title: "Link Personalizado",
    txt: "Aún sin tienda en línea puedes vender por internet fácilmente.",
    to: "/servicios",
  },
];

const Tarifas = () => {
  // const { history } = props;
  const cls = useStyles();
  const [size, setSize] = useState();
  const imgUp = (
    <img
      className={cls.imgImage}
      src="./imgs/tarifas/ilustracion.png"
      alt="imgImage"
    />
  );

  // const handleMenuClick = (pageURL) => {
  //   history.push(pageURL);
  //   setOpen(false);
  // };

  useEffect(() => {
    setSize(window.innerWidth);
    window.addEventListener("resize", () => {
      setSize(window.innerWidth);
    });
  }, []);

  return (
    <div className={cls.tarifas}>
      <div className={cls.root}>
        <div className="cont">
          <div className={cls.texts}>
            <h1 className={cls.title}>
              <span className={cls.font}>¡</span>Manejamos
            </h1>
            <h1 className={cls.title}>
              las tarifas
              <span className={cls.font}> + </span>
              bajas<span className={cls.font}>!</span>
            </h1>
          </div>
        </div>
      </div>
      <div className={cls.body}>
        {size < 960 ? imgUp : null}
        <div className={cls.card}>
          {size > 960 ? imgUp : null}
          <div className={cls.precio}>
            <h1>2.90%</h1>
            <div>
              <label>+$3 pesos</label>
              <span>Por transacción exitosa</span>
            </div>
          </div>
          <div className={cls.txtTarifas}>
            <h1>Las mismas tarifas</h1>
            <h1>para todos nuestros servicios y métodos de pago.</h1>
          </div>
          <div className={cls.imagenes}>
            <div>
              <img src="./imgs/tarifas/nave.png" alt="nave" />
              <div className={cls.txtNave}>
                <label>Acepta Pagos</label>
                <br></br>
                <label>de contado o</label>
                <br></br>
                <label>a meses sin intereses.</label>
                <br></br>
              </div>
            </div>
            <div>
              <img src="./imgs/tarifas/pago.png" alt="pago" />
            </div>
          </div>
        </div>
        <div className={cls.servicios}>
          <label>Nuestros Servicios</label>
          <div>
            {servicios.map((s, i) => (
              <div key={i}>
                <img src={s.img} alt={s.alt} />
                <label>{s.title}</label>
                <div className={cls.space}>
                  <p>{s.txt}</p>
                  <div className={cls.links}>
                    <Link to={{ pathname: s.to, btnType: s.alt }}>
                      ¡Descubre más!
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tarifas;
