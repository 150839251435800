import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Boton } from "./Items";
import { Divider } from "@material-ui/core";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

const LOGIN = gql`
  mutation LOGIN($correo: String!, $contrasena: String!) {
    login(correo: $correo, contrasena: $contrasena) {
      payload {
        token
        user {
          id
        }
      }
      error {
        message
        path
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  cont: {
    marginTop: "-500px",
    maxWidth: "1280px",
    border: "solid 1px #fff",
    width: "440px",
    height: "390px",
    borderRadius: "45px",
    [theme.breakpoints.down("sm")]: {
      border: "none",
      width: "100%",
    },
  },
  legend: {
    fontSize: "40px",
    color: "#fff",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    "& > div:nth-child(1)": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      padding: "15px 0px",
    },
    "& input": {
      width: "300px",
      height: "42px",
      fontSize: "15px",
      margin: "10px",
      paddingLeft: "15px",
      border: "none",
      [theme.breakpoints.down("sm")]: {
        minWidth: "300px",
        width: "80%",
        textAlign: "center",
      },
    },
  },
  divider: {
    height: "1px",
    background: "#fff",
    width: "100%",
    color: "#fff",
    margin: "10px 0px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  linkSesion: {
    color: "#000",
    textDecoration: "none",
    marginBottom: "10px",
  },
  sesion: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "& > button": {
      color: "#7174d7",
      marginTop: "5px",
    },
  },
  olvidaste: {
    fontSize: "16px",
    color: "#7174d7",
    margin: "10px 0",
  },
  cuenta: {
    margin: "10px 0",
    fontSize: "16px",
  },
}));

const InicioSesion = () => {
  const cls = useStyles();
  const [login] = useMutation(LOGIN);
  const [values, setValues] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = useCallback(
    (name) => (event) => {
      const newValues = {
        ...values,
      };
      newValues[name] = event.target.value;
      setValues(newValues);
    },
    [values, setValues]
  );

  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        console.log(values);
        let valid = true;
        if (!values.user) {
          // throw new Error('Favor de ingresar usuario')
          valid = false;
          enqueueSnackbar("Favor de ingresar usuario", {
            variant: "error",
          });
        }
        if (!values.contrasena) {
          // throw new Error('Favor de ingresar contraseña')
          valid = false;
          enqueueSnackbar("Favor de ingresar contraseña", {
            variant: "error",
          });
        }
        if (valid) {
          const result = await login({
            variables: {
              correo: values.user,
              contrasena: values.contrasena,
            },
          });
          const token = result?.data?.login?.payload?.token;
          if (token) {
            console.log(token);
            const token64 = btoa(token);
            window.open(`https://admin.zigu.mx/token/${token64}`, "_self");
            // window.open(`http://localhost:3000/token/${token64}`, '_self')
          } else {
            throw new Error("Usuario y/o contraseña invalido");
          }
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbar("Usuario y/o contraseña invalido", {
          variant: "error",
        });
      }
    },
    [values, login, enqueueSnackbar]
  );

  return (
    <fieldset className={cls.cont}>
      {/* <div className="iframe"></div> */}
      <legend className={cls.legend} align="center">
        Inicia sesión
      </legend>
      <form className={cls.form}>
        <div>
          <input
            type="text"
            placeholder="Nombre de usuario"
            onChange={handleChange("user")}
          />
          <input
            type="password"
            placeholder="Contraseña"
            onChange={handleChange("contrasena")}
          />
        </div>
        <div className={cls.sesion}>
          {/* <label className={cls.olvidaste}>¿Olvidaste tu Contraseña?</label> */}
          <div onClick={handleLogin}>
            <Boton
              val="Enviar"
              txt="Inicia Sesión"
              location="#"
              type="btnMorado"
            />
          </div>
          <Divider className={cls.divider} />
          <label className={cls.cuenta}>¿No tienes una cuenta?</label>
          <Boton
            val="Regístrate"
            txt="Inicia Sesión"
            location="registro"
            type="btnMorado"
          />
        </div>
      </form>
    </fieldset>
  );
};

export default InicioSesion;
