import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Label, Ptxt } from "../Items";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "50px 0px",
    flexDirection: "column",
  },
  slider: {
    width: "100%",
    height: "500px",
    display: "flex",
    justifyContent: "flex-start",
    flexDirtection: "row",
    alignItems: "center",
  },
  button: {
    width: "20%",
    height: "100%",
    border: "none",
    color: "#7174D7",
    fontSize: "50px",
    backgroundColor: "transparent",
  },
  contenido: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60%",
  },
  item: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "500px",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
    marginTop: "10px",
    textAlign: "center",
    opacity: "0",
    transition: "opacity ease-in-out 0.4s",
  },
  img: {
    maxWidth: "250px",
    width: "90%",
    marginBottom: "15px",
  },
  dotContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    width: "100%",
  },
  dot: {
    width: "25px",
    height: "25px",
    margin: "0px 40px",
    borderRadius: "50%",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 20px",
    },
  },
  dotActive: {
    backgroundColor: "#7174D7",
    border: "none",
  },
  dotInactive: {
    backgroundColor: "#fff",
    border: "1px solid #7174D7",
  },
  animationIn: {
    opacity: "1",
  },
}));

const ceos = [
  {
    img: "./imgs/home/img1Sec4.png",
    aos: "zoom-out",
    alt: "ceos",
    ptxt: "“Desde que empezamos a utilizar Zigu, se acabaron nuestros dolores de cabeza a la hora de cobrar y recibir el dinero. En verdad han aumentado la seguridad para nosotros y nuestros clientes.”",
    name: "GABRIEL",
    ceo: "CEO MERCADO CPAP",
  },
  {
    img: "./imgs/home/img2Sec4.png",
    aos: "zoom-out-up",
    alt: "ceos",
    ptxt: '"Lo que más valoramos en VMX es el servicio al cliente, y haber encontrado una plataforma de pagos con un equipo siempre listo y dispuesto a ayudarnos e innovar con nosotros ha sido fantástico, los considero parte de nosotros."',
    name: "Miguel ",
    ceo: "CEO Vapormex",
  },
  {
    img: "./imgs/home/img3Sec4.png",
    aos: "zoom-out-down",
    alt: "ceos",
    ptxt: "“Me preocupaba que la integración con mi sistema de eCommerce fuera a complicarse y luego iba a andar batallando. Pero no fue así. Su API es súper simple de instalar y en menos de lo esperado ya estaba cobrando.”",
    name: "Rafael",
    ceo: "CEO Master Holder",
  },
  {
    img: "./imgs/home/img2Sec4.png",
    aos: "zoom-out-up",
    alt: "ceos",
    ptxt: '"Lo que más valoramos en VMX es el servicio al cliente, y haber encontrado una plataforma de pagos con un equipo siempre listo y dispuesto a ayudarnos e innovar con nosotros ha sido fantástico, los considero parte de nosotros."',
    name: "Miguel ",
    ceo: "CEO Vapormex",
  },
];

const Ceos = () => {
  const cls = useStyles();
  const [indexCeos, setIndexCeos] = useState(0);

  const nextSlide = () => {
    indexCeos < ceos.length - 1 ? setIndexCeos(indexCeos + 1) : setIndexCeos(0);
    clearTimeout(handleTimeOut);
  };

  const prevSlide = () => {
    indexCeos > 0 ? setIndexCeos(indexCeos - 1) : setIndexCeos(ceos.length - 1);
    clearTimeout(handleTimeOut);
  };

  const dotSlide = (index) => {
    setIndexCeos(index);
    clearTimeout(handleTimeOut);
  };

  const handleTimeOut = setTimeout(() => nextSlide(), 4000);

  return (
    <div className={cls.root}>
      <div className={cls.slider}>
        <Button onClick={prevSlide} className={cls.button}>
          {"<"}
        </Button>
        <div className={cls.contenido}>
          {ceos.map((obj, index) => (
            <div
              key={index}
              className={`${index === indexCeos ? cls.animationIn : null} ${
                cls.item
              }`}
            >
              <img className={cls.img} src={obj.img} alt={obj.alt} />
              <br></br>
              <Ptxt txt={obj.ptxt} />
              <br></br>
              <Label txt={obj.name} color="#9e9e9e" />
              <br></br>
              <Label txt={obj.ceo} color="#FEC011" />
            </div>
          ))}
        </div>
        <Button onClick={nextSlide} className={cls.button}>
          {">"}
        </Button>
      </div>
      <div className={cls.dotContainer}>
        {ceos.map((obj, index) => (
          <button
            key={index}
            onClick={() => dotSlide(index)}
            className={`${
              index === indexCeos ? cls.dotActive : cls.dotInactive
            } ${cls.dot}`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Ceos;
// var touchstartX = 0;
// var touchstartY = 0;
// var touchendX = 0;
// var touchendY = 0;

// const slider = useRef(null);

// const handleSlide = () => {
//     currentSlider.addEventListener('touchstart', (event) => {
//         touchstartX = event.screenX;
//         touchstartY = event.screenY;
//     }, false);

//     currentSlider.addEventListener('touchend', (event) => {
//         touchendX = event.screenX;
//         touchendY = event.screenY;
//         handleGesure();
//     }, false);

//     const handleGesure = () => {
//         var swiped = 'swiped: ';
//         if (touchendX < touchstartX) {
//             alert(swiped + 'left!');
//         }
//         if (touchendX > touchstartX) {
//             alert(swiped + 'right!');
//         }
//         if (touchendY < touchstartY) {
//             alert(swiped + 'down!');
//         }
//         if (touchendY > touchstartY) {
//             alert(swiped + 'left!');
//         }
//         if (touchendY == touchstartY) {
//             alert('tap!');
//         }
//     }
// }
